import { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Testing from 'app/utils/Testing';
import * as Interaction from 'app/analytics/constants/Interaction';

import BackSvg from 'app/assets/icons/back.svg';
import CloseSvg from 'app/assets/icons/close.svg';

import styles from './Header.module.scss';
import useAnalytics from 'app/analytics/useAnalytics';

export function Header ({
    dark,
    title,
    children,
    close,
    goBack,
}) {
    const { trackInteraction } = useAnalytics();
    const handleClose = useCallback(() => {
        trackInteraction({
            type: Interaction.Type.Button,
            action: Interaction.Action.Click,
            element: 'Close',
        });
        close();
    }, [trackInteraction, close]);

    const handleBack = useCallback(() => {
        trackInteraction({
            type: Interaction.Type.Button,
            action: Interaction.Action.Click,
            element: 'Back',
        });
        goBack();
    }, [trackInteraction, goBack]);

    return <>
        <div className={cx(styles.header, { [styles.dark]: dark })}>
            <TransitionGroup className={styles.transitionGroup}>
                {close && !goBack && (<CSSTransition
                    appear
                    in
                    timeout={300}
                    classNames={{
                        appear: styles.appear,
                        appearActive: styles.appearActive,
                        appearDone: styles.appearDone,
                        enter: styles.enter,
                        enterActive: styles.enterActive,
                        enterDone: styles.enterDone,
                        exit: styles.exit,
                        exitActive: styles.exitActive,
                        exitDone: styles.exitDone,
                    }}
                >

                    <div
                        tabIndex='0'
                        role='button'
                        className={styles.close}
                        onClick={handleClose}
                        {...Testing.testAttribute('Close')}
                    >
                        <CloseSvg width="24" height="24" />
                    </div>
                </CSSTransition>)}
                {goBack && <CSSTransition
                    appear
                    in
                    timeout={300}
                    classNames={{
                        appear: styles.appear,
                        appearActive: styles.appearActive,
                        appearDone: styles.appearDone,
                        enter: styles.enter,
                        enterActive: styles.enterActive,
                        enterDone: styles.enterDone,
                        exit: styles.exit,
                        exitActive: styles.exitActive,
                        exitDone: styles.exitDone,
                    }}
                >
                    <div
                        tabIndex='0'
                        role='button'
                        className={styles.close}
                        onClick={handleBack}
                        {...Testing.testAttribute('Close')}
                    >
                        <BackSvg width="24" height="24" />
                    </div>
                </CSSTransition>}
                {title && <CSSTransition
                    appear
                    in
                    timeout={300}
                    classNames={{
                        appear: styles.appear,
                        appearActive: styles.appearActive,
                        appearDone: styles.appearDone,
                        enter: styles.enter,
                        enterActive: styles.enterActive,
                        enterDone: styles.enterDone,
                        exit: styles.exit,
                        exitActive: styles.exitActive,
                        exitDone: styles.exitDone,
                    }}
                >
                    <h3 className={styles.title}>{title}</h3>
                </CSSTransition>}
            </TransitionGroup>
        </div>
        {children}
    </>;

}

Header.propTypes = {
    children: PropTypes.node,
    title: PropTypes.node,
    dark: PropTypes.bool,
    close: PropTypes.func,
    goBack: PropTypes.func,
};

export default Header;
