import { useCallback, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Testing from 'app/utils/Testing';
import * as Interaction from 'app/analytics/constants/Interaction';
import Router from 'app/platform/Router';
import useNavigation from 'app/hooks/useNavigation';
import useActiveCompany from 'app/hooks/useActiveCompany';
import useAnalytics from 'app/analytics/useAnalytics';
import useTrackRender from 'app/analytics/useTrackRender';

import CustomerFormSvg from 'app/assets/icons/customer-form.svg';
import PaymentsSvg from 'app/assets/icons/payments.svg';
import RightArrowLeftArrowSvg from 'app/assets/icons/right-arrow-left-arrow.svg';
import SettingsSvg from 'app/assets/icons/settings.svg';
import MailSvg from 'app/assets/icons/mail.svg';
import PayablesSvg from 'app/assets/icons/payables.svg';
import CustomersSvg from 'app/assets/icons/customers.svg';
import NounSaveMoneySvg from 'app/assets/icons/noun-save-money.svg';
import DashSvg from 'app/assets/icons/dash.svg';
import LinkOutSvg from 'app/assets/icons/open-in-new.svg';
import ChatSvg from 'app/assets/icons/chat.svg';
import DiscountSvg from 'app/assets/icons/discount.svg';
import GlobeSvg from 'app/assets/icons/globe.svg';
import InfoSvg from 'app/assets/icons/info.svg';
import MagnifySvg from 'app/assets/icons/magnify.svg';
import MidlineHorizontalEllipsisSvg from 'app/assets/icons/midline-horizontal-ellipsis.svg';
import OrderSvg from 'app/assets/icons/order.svg';
import PeopleSvg from 'app/assets/icons/people.svg';
import CartSvg from 'app/assets/icons/shopping-cart.svg';
import TagPersonSvg from 'app/assets/icons/tag-person.svg';
import WholesailSvg from 'app/assets/icons/wholesail.svg';

import styles from './Tab.module.scss';
import Badge from 'app/components/common/Badge/Badge';

function Tab ({ tab, activeNavTab, onClick }) {
    const { navigate } = useNavigation();
    const { activeCompanyId, activeCompany } = useActiveCompany();
    const { trackInteraction } = useAnalytics();

    useTrackRender({
        element: tab.tabName,
        type: Interaction.Type.NavItem,
    }, {
        // used to turn on and off tracking
        isVisible: tab.shouldTrackTabRender || false,
    });

    const active = activeNavTab === tab.tabName;

    const navParams = useMemo(() => {
        if (tab.href) {
            return;
        }
        const result = {
            ...tab.screen.params,
            companyId: activeCompanyId,
        };

        if (tab.screen.includeSellerIdAsTraderId && activeCompany?.seller?.id) {
            result.traderId = activeCompany.seller.id;
        }

        return result;
    }, [tab.screen, activeCompanyId, activeCompany, tab.href]);

    const handleClick = useCallback((e) => {
        e?.preventDefault();

        trackInteraction({
            element: tab.tabName,
            type: Interaction.Type.NavItem,
            action: Interaction.Action.Click,
        });

        if (tab.href) {
            window.open(tab.href, '_blank');
        } else {
            navigate(tab.screen.route, navParams);
        }

        // Primarily used to close the menu on the mobile more menu option
        onClick && onClick();
    }, [navParams, tab, trackInteraction, navigate, onClick]);

    return <a
        {...Testing.testAttribute(`${tab.tabName}Tab`)}
        onClick={handleClick}
        className={cx(styles.tab, { [styles.active]: active })}
        href={tab.href || Router.generatePath(tab.screen.route, navParams)}
    >
        <div className={cx(styles.icon, { [styles.indicator]: tab.indicator })}>
            <IconTransition name={tab.icon} />
        </div>
        <div className={styles.label}>{tab.label}</div>
        {!!tab.appendBadge && <Badge className={styles.badge} level='promotion'>
            {tab.appendBadge}
        </Badge>}
    </a>;

}

Tab.propTypes = {
    tab: PropTypes.object,
    activeNavTab: PropTypes.string,
    onClick: PropTypes.func,
};

/**
 * This is a wrapper to help transition from using `icons.js` and the `Icon`
 * component to using the .svg files wrapped by SVGR.
 */
export function IconTransition(props) {
    const { name, ...passthroughProps } = props;

    switch (name) {
    case 'payments':
        return <PaymentsSvg {...passthroughProps} />;
    case 'settings':
        return <SettingsSvg {...passthroughProps} />;
    case 'right-arrow-left-arrow':
        return <RightArrowLeftArrowSvg {...passthroughProps} />;
    case 'mail':
        return <MailSvg {...passthroughProps} />;
    case 'payables':
        return <PayablesSvg {...passthroughProps} />;
    case 'customers':
        return <CustomersSvg {...passthroughProps} />;
    case 'noun-save-money':
        return <NounSaveMoneySvg {...passthroughProps} />;
    case 'customer-form':
        return <CustomerFormSvg {...passthroughProps} />;
    case 'open-in-new':
    case 'link-out':
        return <LinkOutSvg {...passthroughProps} />;
    case 'chat':
        return <ChatSvg {...passthroughProps} />;
    case 'discount':
        return <DiscountSvg {...passthroughProps} />;
    case 'globe':
        return <GlobeSvg {...passthroughProps} />;
    case 'info':
        return <InfoSvg {...passthroughProps} />;
    case 'magnify':
        return <MagnifySvg {...passthroughProps} />;
    case 'midline-horizontal-ellipsis':
        return <MidlineHorizontalEllipsisSvg {...passthroughProps} />;
    case 'order':
        return <OrderSvg {...passthroughProps} />;
    case 'people':
        return <PeopleSvg {...passthroughProps} />;
    case 'shopping-cart':
        return <CartSvg {...passthroughProps} />;
    case 'tag-person':
        return <TagPersonSvg {...passthroughProps} />;
    case 'wholesail':
        return <WholesailSvg {...passthroughProps} />;
    default:
        return <DashSvg {...passthroughProps} />;
    }
}

IconTransition.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Tab;
