import User from 'app/utils/User';
import CompaniesResponse from 'app/models/CompaniesResponse';

const fetchAvailableCompanies = async ({ queryKey }) => {

    const params = new URLSearchParams();

    const [ , name, tradesWith ] = queryKey;
    if (name) {
        params.append('name', name);
    }
    if (tradesWith) {
        params.append('tradesWith', tradesWith);
    }
    const queryString = params.toString();
    const { body } = await User.authenticatedFetch(
        null,
        `/company/available${queryString ? `?${queryString}` : ''}`,
        'POST',
    );

    const { availableCompanies } = CompaniesResponse({ body });

    // TODO: remove string check once NET-4570 breaking api change is fully rolled out
    return body.result.map(cid => availableCompanies[typeof cid === 'string' ? cid : cid?.companyId]);
};

export default fetchAvailableCompanies;
