import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';

import TopLabel from 'app/components/common/TopLabel';
import TextInput from 'app/components/common/TextInput';
import Button from 'app/components/common/Button';
import FormLayout from 'app/components/common/FormLayout';
import { StepPropTypes } from 'app/components/common/Wizard/Step';
import withOnRef from 'app/components/utils/withOnRef';
import User from 'app/utils/User';

import styles from './SignInFlow.module.scss';
import ActionLink from 'app/components/common/ActionLink';

const UsernameSchema = Yup.object().shape({
    username: Yup.string().required('Email address or phone number required'),
});

export class UsernameStep extends React.PureComponent {

    _input = React.createRef();

    wizardDidFocus () {
        if (this._input.current) {
            this._input.current.focus();
        }
    }

    render () {
        const {
            buttonText,
            touched,
            errors,
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            showUsernameHelperText,
            showLoggedInUser,
            footerText,
            wizardState,
        } = this.props;

        const { hasCurrentUser } = wizardState;

        const handleInlineSignOut = () => {
            this.props.inlineSignOut();
        };

        return (
            <Form name='signIn'>
                <TopLabel
                    label={<FormattedMessage
                        defaultMessage='Work email or phone number'
                        description='Label for email or phone number input on sign in flow'
                    />}
                    helperText={showUsernameHelperText && <FormattedMessage
                        defaultMessage='Enter the email address or phone number that receives statements'
                        description='Helper text for email or phone number input on sign in flow'
                    />}
                    warningText={touched.username && errors.username}
                >
                    <TextInput
                        ref={this._input}
                        type='text'
                        inputMode='text'
                        value={values.username}
                        onChangeText={handleChange('username')}
                        onBlur={handleBlur('username')}
                        analyticsElement="email-address"
                        testAttribute='emailAddressInput'
                        name='username'
                        autoComplete='username'
                        disabled={showLoggedInUser && hasCurrentUser}
                    />
                </TopLabel>
                <input
                    type='password'
                    name='password'
                    autoComplete='current-password'
                    className={styles.hide}
                />
                {(showLoggedInUser && hasCurrentUser) && <div className={styles.alreadySignedIn}>
                    <div>
                        <FormattedMessage
                            defaultMessage='You’re signed in as <b>{username}</b>. Not you?'
                            description='Sign in form message for already logged in user'
                            values={{
                                username: values.username,
                            }}
                        />
                    </div>
                    <ActionLink
                        title={<FormattedMessage
                            defaultMessage='Sign out'
                            description='Sign in form inline sign out action link'
                        />}
                        analyticsElement='Sign out'
                        onClick={handleInlineSignOut}
                        className={styles.alreadySignedInAction}
                    />
                </div>}
                <TopLabel warningText={errors.general} testingName='username-step' />
                <FormLayout>
                    <Button
                        onClick={handleSubmit}
                        title={buttonText || <FormattedMessage
                            defaultMessage='Next →'
                            description='Submit email address on login screen'
                        />}
                        isLoading={isSubmitting}
                        analyticsElement='email next'
                        testAttribute='nextButton'
                        nativeType='submit'
                    />
                </FormLayout>
                {footerText && <div className={styles.footerText}>
                    {footerText}
                </div>}
            </Form>
        );
    }
}

UsernameStep.defaultProps = {
    showUsernameHelperText: true,
    showLoggedInUser: false,
};

UsernameStep.propTypes = {
    ...StepPropTypes,
    showUsernameHelperText: PropTypes.bool,
    showLoggedInUser: PropTypes.bool,
};

const FormikUsernameStep = withFormik({
    mapPropsToValues: (props) => ({
        username: props.wizardState.username || '',
    }),
    validationSchema: () => {
        return UsernameSchema;
    },
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, { props, setFieldError }) => {
        // Save values to the wizard
        props.setWizardState(values);
        if (props.onSubmit) {
            // Wizard parent has elected to do a custom submit
            return props.onSubmit(values, { props, setFieldError});
        }

        let body;
        if (User.isEmail(values.username)) {
            body = { emailAddress: values.username };
        } else {
            body = { phoneNumber: values.username };
        }

        if (props.wizard.props.brandedMode) {
            body.brandedCompanyId = props.wizard.props.brandInfo.companyId;
        }
        const res = await User.authRequest(
            body,
            '/user/login/start',
            'Unable to verify email address — please try again'
        );

        if (res.passwordSet !== undefined) {
            props.wizard.goToStep(res.passwordSet ? 'password' : 'passcode');
        } else if (res.message) {
            setFieldError('general', res.message);
        } else {
            setFieldError('general', 'Oops, something went wrong');
        }
    },
})(withOnRef(UsernameStep));

export default FormikUsernameStep;
