import { useEffect } from 'react';
import useActiveUser from 'app/hooks/useActiveUser';

function LogoutScreen () {
    const { signOut } = useActiveUser();

    useEffect(() => {
        signOut();
    }, []);

    return null;
}

export default LogoutScreen;
