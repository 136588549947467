import { serverEnv } from 'app/utils/BuildInfo';

export const envAppend = (key) => {
    const env = serverEnv();
    const envName = env.fullName();

    // keep the production cookies the same
    if (envName === 'prod') {
        return key;
    }
    return `${key}-${envName}`;
};

// This function is from https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
function storageAvailable(type) {
    let storage;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
}

const STORE = {};
const getStorage = () => {
    if (storageAvailable('localStorage')) {
        return window.localStorage;
    }

    return {
        getItem: (key) => STORE[key],
        setItem: (key, value) => STORE[key] = value.toString(),
        removeItem: (key) => delete STORE[key],
    };
};

const localStorage = getStorage();

const storage = {
    setItem: (key, value) => {
        return localStorage.setItem(envAppend(key), value);
    },
    removeItem: (key) => {
        return localStorage.removeItem(envAppend(key));
    },
    getItem: (key) => {
        return localStorage.getItem(envAppend(key));
    },
};

export default {
    storage,
};

