import React from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import TopLabel from 'app/components/common/TopLabel';
import TextInput from 'app/components/common/TextInput';
import Button from 'app/components/common/Button';
import FormLayout from 'app/components/common/FormLayout';
import { StepPropTypes } from 'app/components/common/Wizard/Step';
import withOnRef from 'app/components/utils/withOnRef';
import User from 'app/utils/User';
import * as SignInUtils from './SignInUtils';
import styles from './SignInFlow.module.scss';

const PasscodeSchema = Yup.object().shape({
    passcode: Yup.string().required('Passcode required'),
});

export class PasscodeStep extends React.PureComponent {

    state = {
        loading: true,
    };

    _input = React.createRef();

    wizardDidFocus () {
        this._sendCode();

        if (this._input.current) {
            this._input.current.focus();
        }
    }

    wizardDidUnfocus () {
        this.props.setWizardState({ forgotPassword: false });
    }

    _sendCode = async () => {
        const { brandedMode, brandInfo } = this.props.wizard.props;
        this.setState({ loading: true });
        const path = this.props.wizardState.forgotPassword ?
            '/user/password/forgot' : '/user/passcode/start';

        let body;
        if (User.isEmail(this.props.wizardState.username)) {
            body = {emailAddress: this.props.wizardState.username};
        } else {
            body = {phoneNumber: this.props.wizardState.username};
        }
        if (brandedMode) {
            body.brandedCompanyId = brandInfo.companyId;
        }

        const res = await User.authRequest(
            body,
            path,
            'Unable to send passcode'
        );

        this.setState({ loading: false });

        if (res.message) {
            this.props.setFieldError('general', res.message);
        }
    };

    render () {
        const { loading } = this.state;
        const { username, hasCurrentUser } = this.props.wizardState;
        const {
            touched,
            errors,
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
        } = this.props;

        const isEmailLogin = User.isEmail(username);

        return (
            <Form>
                <h2 className={styles.text}>
                    {isEmailLogin
                        ? <FormattedMessage
                            defaultMessage='Check your email'
                            description='Title for passcode step of login when logging in with an email'
                        />
                        : <FormattedMessage
                            defaultMessage='Check your phone'
                            description='Title for passcode step of login when logging in with a phone number'
                        />
                    }
                </h2>
                {!hasCurrentUser && <p className={styles.text}>
                    <FormattedMessage
                        defaultMessage="You'll receive a temporary passcode to sign in if we have <b>{username}</b> on file. Otherwise, you'll receive instructions to request access."
                        description='Description for passcode step of login'
                        values={{
                            b: chunk => (<b>{chunk}</b>),
                            username,
                        }}
                    />
                </p>}
                <TopLabel
                    label={<FormattedMessage
                        defaultMessage='Passcode'
                        description='Passcode input label on passcode step of login'
                    />}
                    actionLabel={<FormattedMessage
                        defaultMessage='Resend code'
                        description='Resend code action on passcode step of login'
                    />}
                    testingName='passcode-flow'
                    onAction={this._sendCode}
                    helperText={<FormattedMessage
                        defaultMessage='You’ll only need to sign in once per device.'
                        description='Passcode input helper text on passcode step of login'
                    />}
                    warningText={touched.passcode && errors.passcode}
                >
                    <TextInput
                        ref={this._input}
                        value={values.passcode}
                        onChangeText={handleChange('passcode')}
                        onBlur={handleBlur('passcode')}
                        analyticsElement="passcode"
                        testAttribute='passcodeInput'
                        autoComplete='one-time-code'
                    />
                </TopLabel>
                <TopLabel warningText={errors.general} testingName='passcode-general' />
                <FormLayout>
                    <Button
                        onClick={handleSubmit}
                        title={<FormattedMessage
                            defaultMessage='Sign in'
                            description='Submit button on passcode step of login'
                        />}
                        isLoading={isSubmitting || loading}
                        testAttribute='signInButtonPasscode'
                        nativeType='submit'
                        analyticsElement='Sign in'
                    />
                </FormLayout>
            </Form>
        );
    }
}

PasscodeStep.propTypes = {
    ...StepPropTypes,
};

const FormikPasscodeStep = withFormik({
    mapPropsToValues: () => ({ passcode: '' }),
    validationSchema: PasscodeSchema,
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, FormikBag) => {
        // clear password from wizard state incase we move forward
        FormikBag.props.setWizardState({ password: '' });

        const res = await User.signinUsername(
            FormikBag.props.wizardState.username,
            values.passcode,
            'Incorrect passcode'
        );

        SignInUtils.handleAuthFlow(res, values, FormikBag);
        FormikBag.setValues({ passcode: '' }, false);
    },
})(withOnRef(PasscodeStep));

export default FormikPasscodeStep;
