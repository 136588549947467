import React from 'react';
import PropTypes from 'prop-types';
import CommonPropTypes from 'app/utils/CommonPropTypes';
import RestrictedView from './RestrictedView';
import withNavigation from 'app/components/utils/withNavigation';

class RestrictedBoundary extends React.PureComponent {

    static getDerivedStateFromError(error) {
        return { error };
    }

    state = {
        error: null,
    };

    componentDidCatch(error) {
        if (error.name !== 'RestrictedSessionException') {
            throw error;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.error &&
            (prevProps.navigation.state.routeName !== this.props.navigation.state.routeName ||
                prevProps.isRestricted && !this.props.isRestricted)) {
            this.clearError();
        }
    }

    clearError = () => {
        this.setState({error: null});
    };

    render() {
        const { error } = this.state;
        if (error) {
            if (this.props.suppress) {
                return null;
            }
            return (
                <RestrictedView
                    clearError={this.clearError}
                />
            );
        }
        return this.props.children;
    }
}

RestrictedBoundary.propTypes = {
    isRestricted: PropTypes.bool,
    children: CommonPropTypes.requirePositiveElements,
    suppress: PropTypes.bool,
    company: PropTypes.object,
    navigation: PropTypes.object.isRequired,
};

export const ConnectedRestrictedBoundary = withNavigation(RestrictedBoundary);

export default ConnectedRestrictedBoundary;
