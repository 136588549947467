import useActiveCompany from 'app/hooks/useActiveCompany';
import useNavigation from 'app/hooks/useNavigation';
import { useEffect } from 'react';

export default function RedirectToSellerView({ applicationId }) {
    const { activeCompany } = useActiveCompany();
    const { navigate } = useNavigation();

    useEffect(() => {
        if (activeCompany) {
            navigate('Application', {
                companyId: activeCompany.id,
                applicationId,
            });
        }
    }, [applicationId, activeCompany, navigate]);

    return null;
}
