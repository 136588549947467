import { lazy } from 'react';

import DefaultScreen from 'app/components/DefaultScreen';
import LogoutScreen from 'app/components/LogoutScreen';
import NotFoundScreen from 'app/components/NotFoundScreen';

/**
 * Map of route names to React components
 */
export const screenClasses = {
    Default: DefaultScreen,
    BaseCompany: DefaultScreen,
    BrandedSignIn: lazy(() => import(/* webpackChunkName:"auth" */'app/components/auth/BrandedSignInScreen')),
    SignIn: lazy(() => import(/* webpackChunkName:"auth" */'app/components/auth/SignInScreen')),
    Logout: LogoutScreen,
    Settings: lazy(() => import(/* webpackChunkName: "shared" */'app/components/settings/SettingsScreen')),
    TeamMemberSingle: lazy(() => import(/* webpackChunkName: "shared" */'app/components/user/SingleScreen')),
    NewCustomerFormConfigSingle: lazy(() => import(/* webpackChunkName: "shared" */'app/components/onboarding/config/SingleConfigScreen')),
    Applications: lazy(() => import(/* webpackChunkName:"seller" */'app/components/traders/ApplicationListScreen')),
    Application: lazy(() => import(/* webpackChunkName:"seller" */'app/components/traders/ApplicationSingleScreen')),
    Customers: lazy(() => import(/* webpackChunkName:"internal" */'app/components/traders/ListScreen/CustomersLandingPage')),
    Report: lazy(() => import(/* webpackChunkName:"internal" */'app/components/traders/ListScreen/Report')),
    ReportSettings: lazy(() => import(/* webpackChunkName:"internal" */'app/components/traders/ListScreen/ReportSettings')),
    CustomersTable: lazy(() => import(/* webpackChunkName:"internal" */'app/components/traders/ListScreen/PaginatedTableScreen')),
    CustomerSingle: lazy(() => import(/* webpackChunkName: "shared" */'app/components/invoices/ListScreen')),
    CustomerSettings: lazy(() => import(/* webpackChunkName:"seller" */'app/components/traders/SettingsScreen')),
    ContactSingle: lazy(() => import(/* webpackChunkName: "shared" */'app/components/user/SingleScreen')),
    Vendors: lazy(() => import(/* webpackChunkName: "shared" */'app/components/traders/ListScreen/ListScreen')),
    VendorSingle: lazy(() => import(/* webpackChunkName: "shared" */'app/components/invoices/ListScreen')),
    Cart: lazy(() => import(/* webpackChunkName:"buyer" */'app/components/traders/CartScreen')),
    SellerCart: lazy(() => import(/* webpackChunkName:"seller" */'app/components/traders/CartScreen')),
    CartPayment: lazy(() => import(/* webpackChunkName:"buyer" */'app/components/traders/CartScreen/PaidView')),
    SellerCartPayment: lazy(() => import(/* webpackChunkName:"buyer" */'app/components/traders/CartScreen/PaidView')),
    PaymentsIn: lazy(() => import(/* webpackChunkName: "shared" */'app/components/payments/ListScreen')),
    CustomerAddPayment: lazy(() => import(/* webpackChunkName:"seller" */'app/components/payments/AddScreen')),
    PaymentsOut: lazy(() => import(/* webpackChunkName: "shared" */'app/components/payments/ListScreen')),
    PaymentInSingle: lazy(() => import(/* webpackChunkName: "shared" */'app/components/payments/SingleScreen')),
    PaymentOutSingle: lazy(() => import(/* webpackChunkName: "shared" */'app/components/payments/SingleScreen')),
    Payouts: lazy(() => import(/* webpackChunkName:"seller" */'app/components/payouts/ListScreen')),
    PayoutSingle: lazy(() => import(/* webpackChunkName:"seller" */'app/components/payouts/SingleScreen')),
    Preferences: lazy(() => import(/* webpackChunkName: "shared" */'app/components/settings/PreferencesScreen')),
    Invoices: lazy(() => import(/* webpackChunkName: "shared" */'app/components/invoices/ListScreen')),
    CustomerInvoiceSingle: lazy(() => import(/* webpackChunkName: "shared" */'app/components/invoices/SingleScreen')),
    VendorInvoiceSingle: lazy(() => import(/* webpackChunkName: "shared" */'app/components/invoices/SingleScreen')),
    InvoiceListInvoiceSingle: lazy(() => import(/* webpackChunkName: "shared" */'app/components/invoices/SingleScreen')),
    CustomerSingleCustomerPaymentPlan: lazy(() => import(/* webpackChunkName: "shared" */'app/components/traders/PaymentPlanScreen')),
    CustomerSingleCustomerPaymentPlanSetup: lazy(() => import(/* webpackChunkName: "shared" */'app/components/traders/PaymentPlanSetupScreen')),
    CustomerSingleCustomerPaymentPlanSetupWithExisting: lazy(() => import(/* webpackChunkName: "shared" */'app/components/traders/PaymentPlanSetupScreen')),
    CustomerSingleCustomerPaymentPlanPropose: lazy(() => import(/* webpackChunkName: "shared" */'app/components/traders/PaymentPlanSetupScreen')),
    CustomerSingleCustomerPaymentPlanTerms: lazy(() => import(/* webpackChunkName: "shared" */'app/components/traders/PaymentPlanTermsScreen')),
    VendorSingleVendorPaymentPlan: lazy(() => import(/* webpackChunkName: "shared" */'app/components/traders/PaymentPlanVendorScreen')),
    VendorSingleVendorPaymentPlanSetup: lazy(() => import(/* webpackChunkName: "shared" */'app/components/traders/PaymentPlanSetupScreen')),
    VendorSingleVendorPaymentAcceptSetup: lazy(() => import(/* webpackChunkName: "shared" */'app/components/traders/PaymentPlanSetupScreen')),
    VendorSingleVendorPaymentPlanTerms: lazy(() => import(/* webpackChunkName: "shared" */'app/components/traders/PaymentPlanTermsScreen')),
    PaymentPlanListCustomerPaymentPlan: lazy(() => import(/* webpackChunkName: "shared" */'app/components/traders/PaymentPlanScreen')),
    Autopay: lazy(() => import(/* webpackChunkName:"seller" */'app/components/traders/PaymentPlanListScreen')),
    PowerUser: lazy(() => import(/* webpackChunkName:"internal" */'app/components/internal/PowerUserScreen')),
    StripeLink: lazy(() => import(/* webpackChunkName:"seller" */'app/components/settings/StripeLinkScreen')),
    StripeLinkV2: lazy(() => import(/* webpackChunkName:"seller" */'app/components/settings/StripeLinkScreenV2')),
    StripeLinkRefreshV2: lazy(() => import(/* webpackChunkName:"seller" */'app/components/settings/StripeLinkRefreshScreenV2')),
    AccountChangeContact: lazy(() => import(/* webpackChunkName: "shared" */'app/components/settings/AccountChangeContactScreen')),
    Invite: lazy(() => import(/* webpackChunkName:"buyer" */'app/components/invite/InviteScreen')),
    PlaidLink: lazy(() => import(/* webpackChunkName: "shared" */'app/components/settings/PlaidLinkScreen')),
    PlaidLinkBouncer: lazy(() => import(/* webpackChunkName: "shared" */'app/components/settings/PlaidLinkScreen/PlaidLinkBouncer')),
    Messages: lazy(() => import(/* webpackChunkName:"seller" */'app/components/messages/ListScreen')),
    MessageSingle: lazy(() => import(/* webpackChunkName:"seller" */'app/components/messages/SingleScreen')),
    Welcome: lazy(() => import(/* webpackChunkName:"auth" */'app/components/messages/WelcomeScreen')), // auth since more of a first time flow
    BuyerUpsell: lazy(() => import(/* webpackChunkName:"marketing" */'app/components/marketing/BuyerUpsellScreen')),
    OnboardingLanding: lazy(() => import(/* webpackChunkName:"onboarding" */'app/components/onboarding/new-customer-form/OnboardingLandingScreen')),
    OnboardingApplication: lazy(() => import(/* webpackChunkName:"onboarding" */'app/components/onboarding/new-customer-form/OnboardingApplicationScreen')),
    OnboardingApplicationPreview: lazy(() => import(/* webpackChunkName:"onboarding" */'app/components/onboarding/new-customer-form/OnboardingApplicationScreen')),
    OnboardingPlaidLink: lazy(() => import(/* webpackChunkName:"onboarding" */'app/components/onboarding/new-customer-form/PlaidLink')),
    OnboardingApplicationSingle: lazy(() => import(/* webpackChunkName:"onboarding" */'app/components/onboarding/new-customer-form/OnboardingApplicationSingle')),
    SuperAdminNotFound: NotFoundScreen,
};
