import { useContext } from 'react';
import { NavigationContext } from 'app/components/utils/NavigationProvider';

export default function useNavigation () {
    const { navigation, router } = useContext(NavigationContext);

    return {
        ...navigation,
        hasHistory: router?.hasHistory,
    };
}
