import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Popover from 'app/components/common/Popover';

import ActionButton from './ActionButton';
import useAnalytics from 'app/analytics/useAnalytics';
import * as Interaction from 'app/analytics/constants/Interaction';

import EllipsisSvg from 'app/assets/icons/midline-horizontal-ellipsis.svg';

import styles from './MoreDropdown.module.scss';

/**
 * The MoreDropdown component renders an expandable menu containing
 * ActionButton or ActionButton-like components.
 */
export default function MoreDropdown({ children }) {
    const [ isOpen, setIsOpen ] = useState(false);
    const { trackInteraction } = useAnalytics();

    const items = React.Children.map(children, actionButton => {
        return <div className={styles.menuItem} key={actionButton.props.id}>
            {React.cloneElement(actionButton, { isInDropdown: true, onClick: () => {
                setIsOpen(false);
                actionButton.props.onClick && actionButton.props.onClick(arguments);
            }})}
        </div>;
    });

    const toggleOpen = () => {
        trackInteraction({
            element: 'More Menu',
            type: Interaction.Type.Dropdown,
            action: isOpen ? Interaction.Action.Collapse : Interaction.Action.Expand,
        });
        setIsOpen(!isOpen);
    };

    return (
        <Popover
            containerClassName={styles.popoverContainer}
            isOpen={isOpen}
            padding={2}
            reposition={false}
            onClickOutside={toggleOpen}
            onEscDown={toggleOpen}
            positions={[ 'bottom' ]}
            align='end'

            content={<div className={styles.popover} onClick={() => {
                setIsOpen(false);
            }}>
                {items}
            </div>}
        >
            <ActionButton
                id='more'
                icon={<EllipsisSvg />}
                title={<FormattedMessage
                    defaultMessage='More'
                    description='Action button title to open the more dropdown'
                />}
                testAttr='More'
                aria-haspopup='true'
                aria-expanded={isOpen}
                onClick={toggleOpen}
                analyticsElement={Interaction.SuppressElement}
            />
        </Popover>
    );
}

MoreDropdown.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
};
