import React from 'react';
import { NOOP } from 'app/utils/Utils';
import Step from './Step';
import styles from './Wizard.module.scss';

function withWizardState (WrappedComponent) {
    class WizardState extends React.PureComponent {
        constructor (props, ...args) {
            super(props, ...args);

            this.state = props.initialState;
        }

        _setState = (...args) => this.setState(...args);

        render () {
            const { forwardedRef, ...props } = this.props;
            return (
                <WrappedComponent
                    ref={forwardedRef}
                    {...props}
                    wizardState={this.state}
                    setWizardState={this._setState}
                />
            );
        }
    }

    WizardState.defaultProps = {
        initialState: {},
    };

    return React.forwardRef((props, ref) => (
        <WizardState {...props} forwardedRef={ref} />
    ));
}

export class Wizard extends React.PureComponent {

    constructor (props, ...args) {
        super(props, ...args);

        this.state = {
            activeStep: props.initialStep,
            height: -1,
        };
    }

    _history = [];

    back = () => {
        if (this._history.length) {
            const step = this._history.pop();
            this._goToStep(step, { reverse: true });
        }
    };

    goToStep = (stepId) => {
        if (stepId !== this.state.activeStep) {
            this._history.push(this.state.activeStep);
            this._goToStep(stepId);
        }
    };

    finish = (...args) => {
        this.props.onFinish && this.props.onFinish(...args);
    };

    _goToStep = (stepId, opts = { reverse: false }) => {
        this.props.onNavigation({
            toStep: stepId,
            fromStep: this.state.activeStep,
            history: this._history,
        });

        this.setState({ activeStep: stepId, ...opts });
    };

    _onStepLayout = (stepId, { height }) => {
        this.setState({ [`_stepHeight_${stepId}`]: height });
    };

    _renderSteps = () => {
        const { wizardState, setWizardState, steps, contentClassName, brandedMode, brandInfo } = this.props;
        const { activeStep, reverse } = this.state;
        const passProps = {
            activeStep,
            reverse,
            onLayout: this._onStepLayout,
            wizard: this,
            wizardState,
            setWizardState,
            contentClassName,
            brandedMode,
            brandInfo,
        };

        return Object.keys(steps).map(stepId => (
            <Step key={stepId} stepId={stepId} wizardStepId={stepId} {...passProps} {...steps[stepId]} />
        ));
    };

    render() {
        const height = this.state[`_stepHeight_${this.state.activeStep}`];

        return (
            <div className={styles.wizard} style={{ height }}>
                {this._renderSteps()}
            </div>
        );
    }

}

Wizard.defaultProps = {
    onNavigation: NOOP,
};

export default withWizardState(Wizard);
