import React, { useCallback, useMemo } from 'react';
import useActiveCompany from 'app/hooks/useActiveCompany';
import useNavigation from 'app/hooks/useNavigation';
import Router from 'app/platform/Router';
import useAnalytics from 'app/analytics/useAnalytics';
import * as Interaction from 'app/analytics/constants/Interaction';

export default function Link ({
    children,
    routeName,
    params,
    onClick,
    analyticsElement,
    analyticsType = Interaction.Type.Link,
    analyticsAction = Interaction.Action.Click,
    forwardHref,
}) {
    const { activeCompanyId } = useActiveCompany();
    const { navigate, state } = useNavigation();
    const { trackInteraction } = useAnalytics();

    // Automatically add a companyId of the activeCompanyId if one is not in the params
    const finalParams = useMemo(() => {
        const result = { ...params };

        if (result.companyId === undefined) {
            result.companyId = activeCompanyId || state?.params?.companyId;
        }

        return result;
    }, [params, activeCompanyId, state?.params?.companyId]);

    const handleClick = useCallback((e) => {
        e?.preventDefault();
        navigate(routeName, finalParams);
        onClick && onClick(e);

        if (analyticsElement) {
            trackInteraction({
                element: analyticsElement,
                type: analyticsType,
                action: analyticsAction,
            });
        }
    }, [navigate, routeName, finalParams, onClick, trackInteraction, analyticsElement, analyticsType, analyticsAction]);

    // verify there is only one child
    React.Children.only(children);

    const _forwardHref = children.props.href !== undefined || children.type === 'a' || forwardHref;

    return React.cloneElement(children, {
        onClick: handleClick,
        href: _forwardHref ? Router.generatePath(routeName, finalParams) : undefined,
    });
}
