import { useId, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

import Testing from 'app/utils/Testing';
import ActionLink from 'app/components/common/ActionLink';
import ActivityIndicator from 'app/components/common/ActivityIndicator';
import ValidationBadge from 'app/components/common/ValidationBadge';

import styles from './TopLabel.module.scss';

export default function TopLabel ({
    warningText,
    children,
    label,
    testingName,
    isLoading,
    helperText,
    onAction,
    actionLabel,
    required = false,
    optional = false,
    isValid = false,
}) {
    const id = useId();

    const warningLabelNode = warningText && (
        <div
            className={styles.warningText}
            {...Testing.testAttribute(`${testingName}-warning`)}
        >
            { warningText }
        </div>
    );
    const actionNode = actionLabel && onAction && (
        <ActionLink
            title={actionLabel}
            onClick={onAction}
            {...Testing.testAttribute(`${testingName}-action`)}
        />
    );

    const helperNode = helperText && (
        <div className={styles.helperText}>{helperText}</div>
    );

    const labelNode = label && (
        <div className={styles.label}>
            <div className={styles.labelContent}>
                <label className={styles.labelText} htmlFor={id}>
                    {label}
                    {(required || optional || isValid) && <ValidationBadge
                        type={required ? ValidationBadge.types.REQUIRED : ValidationBadge.types.OPTIONAL}
                        isValid={isValid}
                        className={styles.badge}
                    />}
                </label>
                {isLoading && <ActivityIndicator wrapped={false} />}
            </div>
            {actionNode}
        </div>
    );

    // Don't render any space if there is no content
    const hasContent = labelNode || children || helperNode || warningLabelNode;
    if (!hasContent) {
        return null;
    }

    const child = Children.count(children) === 1 ? cloneElement(children, { id }) : children;

    return <div className={styles.wrapper}>
        <div className={styles.labelComponent}>
            { labelNode }
            { child }
        </div>
        { helperNode }
        { warningLabelNode }
    </div>;

}

TopLabel.propTypes = {
    required: PropTypes.bool,
    optional: PropTypes.bool,
    isValid: PropTypes.bool,
    label: PropTypes.node,
    actionLabel: PropTypes.node,
    onAction: PropTypes.func,
    helperText: PropTypes.node,
    warningText: PropTypes.node,
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    testingName: PropTypes.string,
};
