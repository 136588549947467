import { DISPLAY_TO_API_STATUS_MAP } from 'app/constants/Onboarding';
import User from 'app/utils/User';

const fetchCustomerForms = async ({ queryKey }) => {
    const [ , companyId, states, limit, offset, archived, query, sortField, sortDirection ] = queryKey;
    const mappedStates = (states || []).map(state => DISPLAY_TO_API_STATUS_MAP[state]).flat();

    const requestBody = {
        sellerId: companyId,
        limit,
        offset,
        archived,
    };

    if (query) {
        requestBody.search = query;
    }

    if (sortField) {
        requestBody.sortBy = {
            field: sortField,
            direction: sortDirection,
        };
    }

    if (mappedStates && mappedStates.length) {
        requestBody.states = mappedStates;
    }

    const { body } = await User.authenticatedFetch(
        null,
        '/company/onboarding/queryV2',
        'POST',
        requestBody,
    );

    return body;
};

export default fetchCustomerForms;
