import React from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import User from 'app/utils/User';
import TopLabel from 'app/components/common/TopLabel';
import TextInput from 'app/components/common/TextInput';
import Button from 'app/components/common/Button';
import FormLayout from 'app/components/common/FormLayout';
import { StepPropTypes } from 'app/components/common/Wizard/Step';
import withOnRef from 'app/components/utils/withOnRef';
import * as SignInUtils from './SignInUtils';
import styles from './SignInFlow.module.scss';

const SetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Required'),
});

export class SetPasswordStep extends React.PureComponent {

    state = {
        showPassword: false,
    };

    _input = React.createRef();

    wizardDidFocus () {
        if (this._input.current) {
            this._input.current.focus();
        }
    }

    _toggleShowPassword = () => this.setState(s => ({ showPassword: !s.showPassword }));

    render () {
        const {
            touched,
            errors,
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            wizardState,
        } = this.props;
        const {
            showPassword,
        } = this.state;

        const inputType = showPassword ? 'text' : 'password';

        return (
            <Form>
                <h2 className={styles.text}>
                    <FormattedMessage
                        defaultMessage='Set password'
                        description='Title for set password step of login'
                    />
                </h2>
                <p className={styles.text}>
                    <FormattedMessage
                        defaultMessage="Enter and confirm a password below. The next time you login, use <b>{username}</b> as your username."
                        description='Description for set password step of login'
                        values={{
                            b: chunk => (<b>{chunk}</b>),
                            username: wizardState.username,
                        }}
                    />
                </p>
                <input
                    name='username'
                    autoComplete='username'
                    readOnly
                    value={wizardState.username}
                    className={styles.hide}
                />
                <TopLabel
                    label={<FormattedMessage
                        defaultMessage='Password'
                        description='Password input label on set password step of login'
                    />}
                    warningText={touched.password && errors.password}
                    actionLabel={showPassword ? <FormattedMessage
                        defaultMessage='Hide'
                        description='Toggle to hide password during login'
                    /> : <FormattedMessage
                        defaultMessage='Show'
                        description='Toggle to show password during login'
                    />}
                    onAction={this._toggleShowPassword}
                >
                    <TextInput
                        ref={this._input}
                        type={inputType}
                        value={values.password}
                        onChangeText={handleChange('password')}
                        onBlur={handleBlur('password')}
                        analyticsElement="password"
                        testAttribute='newPasswordInput'
                        autoComplete='new-password'
                    />
                </TopLabel>
                <TopLabel
                    label={<FormattedMessage
                        defaultMessage='Confirm Password'
                        description='Confirm password input label on set password step of login'
                    />}
                    helperText={<FormattedMessage
                        defaultMessage='Passwords must be at least 8 characters long'
                        description='Confirm password input helper text on set password step of login'
                    />}
                    warningText={touched.confirmPassword && errors.confirmPassword}
                >
                    <TextInput
                        type={inputType}
                        value={values.confirmPassword}
                        onChangeText={handleChange('confirmPassword')}
                        onBlur={handleBlur('confirmPassword')}
                        analyticsElement="confirmPassword"
                        testAttribute='confirmPasswordInput'
                        autoComplete='new-password'
                    />
                </TopLabel>
                <TopLabel warningText={errors.general} />
                <FormLayout>
                    <Button
                        onClick={handleSubmit}
                        title={<FormattedMessage
                            defaultMessage='Finish'
                            description='Submit button on set password step of login'
                        />}
                        isLoading={isSubmitting}
                        testAttribute='finishButton'
                        nativeType='submit'
                        analyticsElement='Finish'
                    />
                </FormLayout>
            </Form>
        );
    }
}

SetPasswordStep.propTypes = {
    ...StepPropTypes,
};

const FormikSetPasswordStep = withFormik({
    mapPropsToValues: () => ({ password: '', confirmPassword: '' }),
    validationSchema: SetPasswordSchema,
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, FormikBag) => {
        const { props } = FormikBag;
        const body = {
            password: props.wizardState.password || props.wizardState.passcode,
            newPassword: values.password,
        };
        if (User.isEmail(props.wizardState.username)) {
            body.emailAddress = props.wizardState.username;
        } else {
            body.phoneNumber = props.wizardState.username;
        }
        const res = await User.authRequest(
            body,
            '/user/password/set',
            'Unable to set new password'
        );

        SignInUtils.handleAuthFlow(res, values, FormikBag);
        FormikBag.setValues({ password: '', confirmPassword: '' }, false);
    },
})(withOnRef(SetPasswordStep));

export default FormikSetPasswordStep;
