import React from 'react';
import { hocDisplayName } from 'app/utils/Utils';
import useNavigation from 'app/hooks/useNavigation';

const withNavigation = (WrappedComponent) => {
    const navForwareded = React.forwardRef((props, ref) => {
        const navigation = useNavigation();
        return <WrappedComponent ref={ref} {...props} navigation={navigation} />;
    });
    navForwareded.displayName = hocDisplayName('withNavigation', WrappedComponent);

    return navForwareded;
};

export default withNavigation;
