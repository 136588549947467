import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Toast from 'app/components/common/Toast';

export const ToastContext = React.createContext({});
const ToastRendererContext = React.createContext({});

const TOAST_DELAY = 5000;

export function Toaster ({ className }) {
    const { toast, isLeaving, clearToast } = useContext(ToastRendererContext);
    return toast && <div className={className}>
        <Toast
            visible={!isLeaving}
            onExited={clearToast}
            level={toast.level}
        >
            {toast.message}
        </Toast>
    </div>;
}

function ToastProvider ({ children }) {
    const [ toast, setToast ] = useState();
    const [ isLeaving, setIsLeaving ] = useState(false);

    const addToast = useCallback(({message, level}) => {
        setToast({
            message, level,
        });
    }, [setToast]);

    const clearToast = useCallback(() => {
        setToast();
        setIsLeaving(false);
    }, [setToast]);

    useEffect(() => {
        let timer;
        if (toast) {
            timer = setTimeout(() => {
                setIsLeaving(true);
            }, TOAST_DELAY);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [toast, setToast, setIsLeaving]);

    return (
        <ToastContext.Provider value={{ addToast }}>
            <ToastRendererContext.Provider value={{ toast, isLeaving, clearToast }}>
                {children}
            </ToastRendererContext.Provider>
        </ToastContext.Provider>
    );
}

ToastProvider.propTypes = {
    children: PropTypes.node,
};

export default ToastProvider;
