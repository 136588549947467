import { useContext, useEffect, createContext } from 'react';
import { createPortal } from 'react-dom';

export const FixedFooterContext = createContext();

export default function ModalFixedFooter ({ children, Component = 'div', ...rest }) {
    const { ref: footerContextRef, hasContent } = useContext(FixedFooterContext);

    useEffect(() => {
        hasContent(!!footerContextRef);
    }, [ footerContextRef, hasContent ]);

    if (!footerContextRef) {
        return null;
    }

    const content = Component ? <Component {...rest}>{children}</Component> : children;

    return createPortal(
        content,
        footerContextRef
    );
}
