import { _ } from 'mixpanel-browser/src/utils';
import { replaceUUID } from 'app/utils/Utils';

const wsrtRe = /_wsrt=[^&]*/i;

export const deviceProperties = async () => {
    const properties = _.info.properties();
    const $current_url = properties['$current_url'].replace(wsrtRe, '');
    const $referrer = properties['$referrer'] &&
        properties['$referrer'].replace(wsrtRe, '');

    return {
        ...properties,
        $current_url,
        $referrer,
        url_pattern: replaceUUID($current_url),
        user_agent: window.navigator.userAgent,
    };
};
