import PropTypes from 'prop-types';

import LockSvg from 'app/assets/icons/lock-filled.svg';
import TagPersonSvg from 'app/assets/icons/tag-person.svg';

import styles from './Avatar.module.scss';

const Avatar = ({ icon, showTitle, title, useDisplayNameIcon }) => {
    const displayName = useDisplayNameIcon ? <TagPersonSvg /> : title?.charAt(0);

    const getIcon = () => {
        let Svg;
        switch (icon) {
        case 'lock':
            Svg = LockSvg;
            break;
        default:
            throw new Error(`Requested icon [${icon}] not supported`);
        }

        return (<div className={styles.icon}><Svg width={12} height={12} /></div>);
    };

    return (<span className={styles.avatarHolder}>
        <span className={styles.avatar} role='button'>
            {displayName}
            {!!icon && getIcon(icon)}
        </span>
        {showTitle && <span className={styles.title}>{title}</span>}
    </span>);
};

Avatar.propTypes = {
    icon: PropTypes.oneOf(['lock']),
    showTitle: PropTypes.bool,
    title: PropTypes.string.isRequired,
    useDisplayNameIcon: PropTypes.bool,
};

Avatar.defaultProps = {
    showTitle: false,
    useDisplayNameIcon: false,
};

export default Avatar;
