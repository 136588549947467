import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import CommonColors from '@wsui/core/Colors.js';

import ActionLink from 'app/components/common/ActionLink';
import Button from 'app/components/common/Button';
import CompanyLogo from 'app/components/common/CompanyLogo';
import UserMenu from 'app/components/common/Screen/Nav/UserMenu';
import ContactUsModal from 'app/components/modals/ContactUs/ContactUs';
import GenericModal from 'app/components/modals/common/GenericModal';
import ModalFixedFooter from 'app/components/common/Modal/FixedFooter';

import useActiveUser from 'app/hooks/useActiveUser';
import useNavigation from 'app/hooks/useNavigation';
import * as PageView from 'app/analytics/constants/PageView';
import { APPLICATION_STATUSES } from 'app/constants/Onboarding';

import CheckSvg from 'app/assets/icons/check.svg';

import { CustomerFormContext } from '../util/CustomerFormProvider';
import RedirectToSellerView from '../util/RedirectToSellerView';

import styles from './OnboardingHeader.module.scss';

const UNEDITABLE_STATUSES = [
    APPLICATION_STATUSES.LINKED,
    APPLICATION_STATUSES.PENDING_LINK,
    APPLICATION_STATUSES.EXPORT_ENQUEUED,
    APPLICATION_STATUSES.STANDALONE_ACCEPTED,
];

export default function OnboardingHeader() {
    const {
        applicationId,
        companySlug,
        applicationSlug,
        goToSection,
        formArchived,
        formState,
        sellerData,
        isLoading,
        availableSections,
        formPrimaryUserId,
    } = useContext(CustomerFormContext);

    const { navigate, state } = useNavigation();
    const { signOut, activeUser } = useActiveUser();
    const isViewOnly = state.routeParams.isViewOnly;

    const [ isScrolled, setIsScrolled ] = useState(false);
    const [ showFormLockedModal, setShowFormLockedModal ] = useState(false);

    const [ref, setRef] = useState();
    const [isContactUsModalOpen, setIsContactusModalOpen] = useState(false);

    const activeTabRefCallback = useCallback((node) => {
        setRef(node);
    }, [setRef]);

    useEffect(() => {
        if (isViewOnly) {
            return;
        }
        if (formArchived || UNEDITABLE_STATUSES.includes(formState)) {
            setShowFormLockedModal(true);
        }
    }, [isViewOnly, formArchived, formState]);

    useEffect(() => {
        if (ref) {
            ref.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, [ref]);

    useEffect(() => {
        const onScroll = () => {
            if (window.document.documentElement.scrollTop > 5) {
                setIsScrolled(true);
            }
        };
        window.document.addEventListener('scroll', onScroll);

        return () => {
            window.document.removeEventListener('scroll', onScroll);
        };
    }, [setIsScrolled]);

    const userMenuOptions = useMemo(() => ([
        <ActionLink
            key='contactUs'
            title={<FormattedMessage
                defaultMessage='Contact Wholesail'
                description='New buyer form - contact Wholesail menu option'
            />}
            onClick={() => {
                setIsContactusModalOpen(true);
            }}
            analyticsElement='Contact us'
            inline={false}
        />,
        <ActionLink
            key='signOut'
            title={<FormattedMessage
                defaultMessage='Sign out'
                description='New buyer form - sign out user menu option'
            />}
            onClick={() => signOut({
                navigateToSignin: false,
                next: {
                    routeName: 'OnboardingLanding',
                    params: {
                        companySlug,
                        applicationSlug,
                    },
                },
            })}
            analyticsElement='User menu sign out'
            inline={false}
        />,
    ].filter(Boolean)), [applicationSlug, companySlug, signOut]);

    const handleArchivedModalClose = async () => {
        navigate('OnboardingLanding', {
            companySlug,
            applicationSlug,
        });
    };

    const handleViewApprovedForm = async () => {
        navigate('OnboardingApplicationSingle', {
            applicationId,
            applicationSlug,
            companySlug,
        });
    };

    if (isLoading) {
        return null;
    }

    let renderSellerRedirect = false;
    if (activeUser?.id && formPrimaryUserId && formPrimaryUserId !== activeUser.id) {
        renderSellerRedirect = true;
    }

    const showLinkToApprovedForm = UNEDITABLE_STATUSES.indexOf(formState) >= 0;

    return <div className={cx(styles.header, {
        [styles.scrolled]: isScrolled,
        [styles.noTabs]: isViewOnly,
    })}>
        <div className={styles.userMenu}>
            <UserMenu
                positions={['bottom']}
                variant='onboarding'
                menuOptions={userMenuOptions}
            />
        </div>
        <div className={styles.animationHolder}>
            <CompanyLogo
                uri={sellerData.logoUri}
                diameter={96}
                borderWidth={4}
                borderColor={CommonColors.surfieLight}
                className={styles.companyLogo}
            />
            <h1 className={styles.headline}>
                <FormattedMessage
                    defaultMessage='{name} new customer form'
                    description='New customer form - header'
                    values={{
                        name: sellerData.displayName,
                    }}
                />
            </h1>
        </div>
        {!isViewOnly && <>
            <div className={styles.sections}>
                {availableSections.map((section, index) => (section.header && <div
                    ref={section.current ? activeTabRefCallback : undefined}
                    key={section.name}
                    role={section.complete && 'button'}
                    className={cx(styles.section, {
                        [styles.complete]: section.complete,
                        [styles.current]: section.current,
                    })}
                    onClick={() => {
                        section.complete && goToSection(section);
                    }}
                >
                    {section.complete
                        ? <CheckSvg width={20} height={20} />
                        : <div className={styles.count}><small>{index + 1}</small></div>}
                    <b>{section.label}</b>
                </div>))}
            </div>
            <GenericModal
                visible={showFormLockedModal}
                showClose={false}
                analyticsEntity={PageView.Entity.Confirmation}
                analyticsModalType='Confirmation'
                title={(formArchived && !showLinkToApprovedForm)
                    ? <FormattedMessage
                        defaultMessage='New customer form has been archived by the vendor'
                        description='Locked form modal - archived title text'
                    />
                    : <FormattedMessage
                        defaultMessage='New customer form has been processed by the vendor'
                        description='Locked form modal - processed title text'
                    />}
                analyticsName='Locked form modal'
            >
                {(formArchived && !showLinkToApprovedForm)
                    ? <FormattedMessage
                        defaultMessage='This new customer form has been archived by the vendor and can no longer be edited or submitted.'
                        description='Locked form modal - description text informing customer form has been archived and cannot be modified'
                    />
                    : <FormattedMessage
                        defaultMessage='This new customer form has been processed by the vendor and can no longer be edited or submitted.'
                        description='Locked form modal - description text informing customer form has been processed and cannot be modified'
                    />}
                <ModalFixedFooter>
                    <div className={styles.buttonBar}>
                        {showLinkToApprovedForm && <Button
                            title={<FormattedMessage
                                defaultMessage='View approved form'
                                description='Locked form modal - view approved form'
                            />}
                            analyticsElement='view approved form'
                            onClick={handleViewApprovedForm}
                        />}
                        {(formState === APPLICATION_STATUSES.LINKED
                            || formState === APPLICATION_STATUSES.STANDALONE_ACCEPTED
                            || formArchived) && <Button
                            title={<FormattedMessage
                                defaultMessage='Start another new customer form'
                                description='Locked form modal - create new form button'
                            />}
                            analyticsElement='Confirm'
                            onClick={handleArchivedModalClose}
                            type={UNEDITABLE_STATUSES.indexOf(formState) >= 0 ? 'secondary' : 'primary'}
                        />}
                    </div>
                </ModalFixedFooter>
            </GenericModal>
        </>}
        <ContactUsModal visible={isContactUsModalOpen} onClose={() => setIsContactusModalOpen(false)} />
        {renderSellerRedirect && <RedirectToSellerView applicationId={applicationId} />}
    </div>;
}
