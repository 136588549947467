import ArrowSvg from 'app/assets/icons/open-in-new.svg';

/**
 * This component renders a symbol to indicate a link will open in a new tab to
 * users in advance.  It is designed to be be placed inline with the contents
 * of an anchor if that anchor will open in a new tab.
 *
 * See Also:
 *
 *   - https://adrianroselli.com/2020/02/link-targets-and-3-2-5.html
 *
 *   - https://knowbility.org/blog/2019/links-opening-new-windows-no-warning/
 */
export default function OpenNewTabIcon({...rest}) {
    return <ArrowSvg alt="Opens in new tab" {...rest} />;
}
