import * as Auth from 'app/utils/Auth';
import { navigateToSignIn } from 'app/platform/Router';
import * as Fetch from 'app/utils/Fetch';

const KNOWN_SERVER_ERRORS = [
    'generic_user',
    'json_mapping',
    'invalid_definition',
    'session_rejected',
    'invalid_email',
];

const ERROR_INVALID_EMAIL = 'invalid_email';
const ERROR_USER_NOT_FOUND = 'user_not_found';

const isEmail = (value) => {
    return (value || '').indexOf('@') >= 0;
};

export const authRequest = async (
    bodyBase,
    path,
    failureMessage
) => {

    const platform = 'WEB';
    let body = {
        ...bodyBase,
        platform,
    };

    let response;
    try {
        response = await Fetch.makefetch({
            method: 'POST',
            path,
            body,
        });

        if (response.status >= 200 && response.status < 300) {
            return {
                ...response.body,
                token: response.body.sessionToken,
            };
        }
    } catch (error) {
        if (error.isErrorName('NetworkException')) {
            throw error;
        }
        if (error.isError('BadRequestException', KNOWN_SERVER_ERRORS)) {
            return {
                message: failureMessage,
            };
        }
        if (error.additionalData &&
            error.additionalData.status === 403 &&
            error.additionalData.json.error === 'set_password'
        ) {
            return {
                forceSetPassword: true,
                message: 'Please set a new password',
            };
        }
        if (error.additionalData &&
            error.additionalData.status === 403 &&
            error.additionalData.json.error === 'user_login_attempts'
        ) {
            return {
                message: 'Too many unsuccessful sign in attempts. Please try again ' +
                    'later, or email support@paywholesail.com',
            };
        }
    }

    return {
        message: 'Unknown error occurred — please try again',
    };
};

const generateUsernameRequestBody = username => {
    return isEmail(username)
        ? {
            emailAddress: username,
        }
        : {
            phoneNumber: username,
        };
};

const signinUsername = (
    username,
    password,
    failureMessage = 'This email and password is not in our records'
) => {

    return authRequest(
        {
            ...generateUsernameRequestBody(username),
            password,
        },
        '/user/login/request',
        failureMessage);

};

const sendTempPasscode = async (username, type = 'SET_PASSWORD') => {
    const body = {
        ...generateUsernameRequestBody(username),
        type,
    };

    try {
        const res = await Fetch.makefetch({
            method: 'POST',
            path: '/user/mfa',
            body,
        });
        return res;
    } catch (error) {

        if (error.additionalData) {
            const { status, json } = error.additionalData;
            const response = { status, body: json };

            const _isInvalidUsername = result => {
                return result.status === 400 &&
                    result.body &&
                    result.body.error &&
                    result.body.error === ERROR_INVALID_EMAIL;
            };

            const _isUnknownUsername = result => {
                return result.status === 404 &&
                    result.body &&
                    result.body.error &&
                    result.body.error === ERROR_USER_NOT_FOUND;
            };

            if (_isInvalidUsername(response)) {
                return { message: 'This email is invalid' };
            }

            if (_isInvalidUsername(response) ||
                _isUnknownUsername(response)) {
                return response;
            }
        }

        throw error;
    }
};

const updateSession = async (fetch) => {
    const response = await fetch;
    if (response && response.body) {
        await Auth.updateRestrictedSession(response.body);
    }
    return response;
};

const authenticatedFetch = (
    navigate,
    path,
    method,
    body,
    opts = {},
) => {
    return updateSession(Fetch.authenticatedFetch({
        getTokenPromise: Auth.isSignedIn,
        clearTokenPromise: Auth.onSignOut,
        requireSignIn: navigateToSignIn,
        method,
        path,
        body,
        ...opts,
    }));
};

export default {
    unauthenticatedFetch: Fetch.unauthenticatedFetch,
    authenticatedFetch,
    generateUsernameRequestBody,
    signinUsername,
    sendTempPasscode,
    authRequest,
    isEmail,
};

