import PropTypes from 'prop-types';
import cx from 'classnames';
import TextInput from 'app/components/common/TextInput';
import MagnifySvg from 'app/assets/icons/magnify.svg';
import styles from './SearchBar.module.scss';

export default function SearchBar({ inputClassName, ...rest }) {
    return (
        <TextInput
            type='search'
            {...rest}
            IconSvg={MagnifySvg}
            inputClassName={cx(styles.search, inputClassName)}
        />
    );
}

SearchBar.propTypes = {
    ...TextInput.propTypes,

    onChangeText: PropTypes.func,
    onChange: PropTypes.func,
};

SearchBar.defaultProps = {
    enableValueTracking: true,
};
