import { useRef } from 'react';
import useResizeObserver from 'use-resize-observer';
import PropTypes from 'prop-types';
import UserMenu from 'app/components/common/Screen/Nav/UserMenu';
import SwitchCompany from 'app/components/nav/SwitchCompany';
import SideNav from './Nav/SideNav';
import BottomNav from './Nav/BottomNav';
import Conditional from 'app/components/utils/Conditional';
import SyncStatus from 'app/components/nav/SyncStatus/SyncStatus';
import useActiveCompany from 'app/hooks/useActiveCompany';
import useActiveUser from 'app/hooks/useActiveUser';
import { Toaster } from 'app/components/utils/ToastProvider';
import { FixedFooterContext } from './FixedFooter';
import styles from './ScreenFrame.module.scss';

function ScreenFrame ({ children, activeNavTab, unauthenticated }) {
    const { ref, height: fixedFooterHeight } = useResizeObserver();
    const { activeCompany: company } = useActiveCompany();
    const { activeUser } = useActiveUser();
    const fixedFooterRef = useRef();

    if (unauthenticated) {
        return children;
    }

    return (
        <FixedFooterContext.Provider value={fixedFooterRef}>
            <div className={styles.pageContent}>
                <div className={styles.companySwitcher}>
                    <SwitchCompany company={company} />
                    {!!activeUser && (<UserMenu />)}
                </div>
                <div className={styles.content}>
                    {children}
                    <div style={{ height: fixedFooterHeight }} />
                </div>
            </div>
            <SideNav
                company={company}
                activeNavTab={activeNavTab}
            />
            <div
                ref={ref}
                className={styles.fixedFooter}
            >
                <div style={{height: 0}}>
                    <Toaster className={styles.toast} />
                </div>
                <div className={styles.fixedFooterSpacer} ref={fixedFooterRef} />
                <Conditional isMobile>
                    <SyncStatus company={company} />
                </Conditional>
                <BottomNav
                    company={company}
                    activeNavTab={activeNavTab}
                />
            </div>
        </FixedFooterContext.Provider>
    );
}

ScreenFrame.propTypes = {
    children: PropTypes.node,
    unauthenticated: PropTypes.bool,
    activeNavTab: PropTypes.string,
};

export default ScreenFrame;
