import { useContext } from 'react';
import { useQuery } from 'react-query';
import { ActiveUserIdContext } from 'app/components/utils/ActiveUserProvider';
import fetchActiveUser from 'app/fetch/fetchActiveUser';
import ActiveUser, { USER_FLAGS } from 'app/models/ActiveUser';

export default function useActiveUser () {
    const context = useContext(ActiveUserIdContext);
    const { data, refetch, isLoading } = useQuery([ 'activeUser', context.activeUserId ], fetchActiveUser, {
        enabled: context.isSignedIn,
        select: (data) => data && new ActiveUser(data),
    });

    return {
        ...context,
        refetch,
        activeUser: data,
        USER_FLAGS,
        isLoading,
    };
}
