import PropTypes from 'prop-types';
import CommonPropTypes from 'app/utils/CommonPropTypes';
import * as FeatureFlag from 'app/utils/FeatureFlag';
import useBreakpoints from 'app/hooks/useBreakpoints';

function Conditional ({
    children,
    condition,
    isDesktopWeb,
    isMobile,
    isFeatureOn,
    isFeatureOff,
    mode,
}) {
    const breakpoints = useBreakpoints();

    const conditions = [];
    const pushIfDefinedAndEqualTo = (test, toEqual) => {
        if (test !== undefined) {
            conditions.push(test === toEqual);
        }
    };

    pushIfDefinedAndEqualTo(condition, true);
    pushIfDefinedAndEqualTo(isDesktopWeb, breakpoints.isDesktopWeb);
    pushIfDefinedAndEqualTo(isMobile, !breakpoints.isDesktopWeb);

    if (isFeatureOn) {
        conditions.push(FeatureFlag.get(isFeatureOn));
    }

    if (isFeatureOff) {
        conditions.push(!FeatureFlag.get(isFeatureOff));
    }

    if (conditions[mode](i => i)) {
        return children;
    }
    return null;
}

Conditional.defaultProps = {
    mode: 'every',
};

Conditional.propTypes = {
    children: CommonPropTypes.optionalPositiveNodes,
    condition: PropTypes.bool,
    isDesktopWeb: PropTypes.bool,
    isMobile: PropTypes.bool,
    isFeatureOn: PropTypes.string,
    isFeatureOff: PropTypes.string,
    mode: PropTypes.oneOf([ 'every', 'some' ]),
};

export default Conditional;

