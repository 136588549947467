import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NOOP } from 'app/utils/Utils';
import useActiveUser from 'app/hooks/useActiveUser';
import Wizard from 'app/components/common/Wizard';
import UsernameStep from 'app/components/auth/SignInScreen/SignInFlow/UsernameStep';
import PasswordStep from 'app/components/auth/SignInScreen/SignInFlow/PasswordStep';
import PasscodeStep from 'app/components/auth/SignInScreen/SignInFlow/PasscodeStep';
import SetPasswordStep from 'app/components/auth/SignInScreen/SignInFlow/SetPasswordStep';
import styles from './SignInFlow.module.scss';

const STEPS = {
    username: { Component: UsernameStep },
    password: { Component: PasswordStep },
    setPassword: { Component: SetPasswordStep },
    passcode: { Component: PasscodeStep },
};

const INITIAL_STATE = {
    username: '',
    password: '',
    passcode: '',
};

const SignInFlow = React.forwardRef(({
    showBack,
    brandedMode,
    brandInfo,
    currentUser,
    onFinish,
    steps,
}, ref) => {

    const { setUserToken, isSignedIn } = useActiveUser();

    const handleFinish = useCallback(({ sessionToken }) => {
        let success = isSignedIn;

        if (sessionToken) {
            // `setUserToken` will tell us if we successfully update the token
            success = setUserToken(sessionToken);
        }
        onFinish({ success });
    }, [isSignedIn, setUserToken, onFinish]);

    const handleNavigation = useCallback(({ history }) => {
        showBack(!!history.length);
    }, [showBack]);

    const initialState = currentUser ?
        {
            ...INITIAL_STATE,
            username: currentUser.username,
            hasCurrentUser: true,
        } : INITIAL_STATE;
    const initialStep = currentUser ?
        (currentUser.passwordSet ? 'password' : 'passcode' ) : 'username';

    return (
        <Wizard
            ref={ref}
            initialState={initialState}
            initialStep={initialStep}
            steps={steps}
            contentClassName={styles.stepContent}
            onFinish={handleFinish}
            onNavigation={handleNavigation}
            brandedMode={brandedMode}
            brandInfo={brandInfo}
        />
    );
});

SignInFlow.defaultProps = {
    showBack: NOOP,
    brandedMode: false,
    steps: STEPS,
};

SignInFlow.propTypes = {
    brandedMode: PropTypes.bool,
    brandInfo: PropTypes.shape({
        companyId: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        logoUri: PropTypes.string.isRequired,
        hasSellers: PropTypes.bool.isRequired,
        hasBuyers: PropTypes.bool.isRequired,
    }),
    onFinish: PropTypes.func.isRequired,
    showBack: PropTypes.func,
    currentUser: PropTypes.shape({
        username: PropTypes.string.isRequired,
        passwordSet: PropTypes.bool.isRequired,
    }),

    // Allows overriding the steps
    steps: PropTypes.object,
};

export default SignInFlow;
