import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './FormLayout.module.scss';

export default function FormLayout ({ children, className }) {
    return (
        <div className={cx(styles.wrapper, className)}>
            {children}
        </div>
    );
}

FormLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
