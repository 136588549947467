import { useEffect } from 'react';
import * as Analytics from 'app/analytics/Analytics';
import useActiveUser from 'app/hooks/useActiveUser';

export default function SSLCheck () {
    const { activeUserId } = useActiveUser();

    useEffect(() => {
        (async () => {
            let success;
            try {
                await fetch('https://ssl-test-api.paywholesail.com/v1/debug');
                success = true;
            } catch (error) {
                success = false;
            }

            Analytics.trackEventWithScreen('SSLCheck', { success });
        })();
    }, [ activeUserId ]);

    return null;
}
