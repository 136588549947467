import querystring from 'querystring';

/**
 * Produce a URL query string from a given object.  When the query string is
 * not empty, it is prefixed with a "?".
 *
 * @param {Object} obj
 * @returns {string}
 */
export function generateQueryString(obj) {
    const tmp = querystring.stringify(obj);
    return tmp ? '?' + tmp : '';
}
