import PropTypes from 'prop-types';
import styles from './ServerIndicator.module.scss';

export default function ServerIndicator ({ hostColor }) {
    return <div style={{ borderTopColor: hostColor }} className={styles.indicator} />;
}

ServerIndicator.propTypes = {
    hostColor: PropTypes.string,
};
