import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BackButton from 'app/components/common/BackButton';
import MoreDropdown from './MoreDropdown';
import styles from './TopNavBar.module.scss';

/**
 * The maximum number of action items to display before introducing the "More
 * Dropdown".
 */
const MORE_THRESHOLD = 4;

/**
 * The number of action items to display when the "More Dropdown" also appears.
 * It is one less than the threshold to ensure the dropdown contains at least
 * two items.
 */
const MORE_SPLIT = MORE_THRESHOLD - 1;

export default function TopNavBar({ backButtonFallback, children, className }) {
    const allItems = React.Children.toArray(children);

    if (!backButtonFallback && allItems.length === 0) {
        return null;
    }

    let leadingItems = allItems;
    let trailingItems = [];
    if (allItems.length > MORE_THRESHOLD) {
        leadingItems = allItems.slice(0, MORE_SPLIT);
        trailingItems = allItems.slice(MORE_SPLIT);
    }

    return (
        <div className={cx(styles.container, className)}>
            {backButtonFallback && <div className={styles.backButton}>
                <BackButton fallbackRoute={backButtonFallback} />
            </div>}
            <div className={styles.actionButtons}>
                {leadingItems}
                {trailingItems.length > 0 && (
                    <MoreDropdown>{trailingItems}</MoreDropdown>
                )}
            </div>
        </div>
    );
}

TopNavBar.propTypes = {
    backButtonFallback: PropTypes.object,
    children: PropTypes.arrayOf(PropTypes.element),
    className: PropTypes.string,
};
