import React from 'react';
import Cookies from 'js-cookie';
import { envAppend } from 'app/platform/Storage';
import Sentry, { hasSentry } from 'app/utils/Sentry';
import CommonPropTypes from 'app/utils/CommonPropTypes';
import PropTypes from 'prop-types';
import ErrorView from 'app/components/modals/ErrorView';
import { COMPANY_ID_COOKIE } from 'app/components/utils/ActiveCompanyProvider';
import MaintenanceView from 'app/components/utils/ErrorBoundary/MaintenanceView';

class ErrorBoundary extends React.PureComponent {

    state = {
        error: null,
    };

    componentDidCatch(error, errorInfo) {
        const { ignoreRestrictedException } = this.props;

        // Ignore RestrictedSessionException so the RestrictedBoundary can catch it.
        // This should only be enabled if the RestrictedBoundary is higher up.
        if (ignoreRestrictedException && error.name === 'RestrictedSessionException') {
            throw error;
        }

        if (window.unloading && error.name === 'FailedToFetchException') {
            // Canceled network requests on navigation/refresh spawn exceptions that can be ignored
            return;
        }

        // Set the error to show
        this.setState({ error });

        // Do nothing if Sentry isn't setup
        if (!hasSentry()) {
            return;
        }

        Sentry.withScope(scope => {
            // Tag the error with `hitBoundry` so Sentry knows it has been shown
            scope.setExtra('hitBoundary', true);

            // Add the componentStack for debugging purposes
            scope.setExtra('componentStack', errorInfo.componentStack);

            // Send the error to Sentry
            Sentry.captureException(error);
        });
    }

    dismiss = () => {
        const { error } = this.state;
        if (error?.name === 'PermissionDeniedException') {
            if (error.additionalData?.json?.detail?.denied?.targetType === 'COMPANY') {
                Cookies.remove(envAppend(COMPANY_ID_COOKIE));
            }
        }
        this.setState({ error: undefined });
    };

    render() {
        const { error } = this.state;

        if (error) {
            if (error.name === 'MaintenanceException') {
                return <MaintenanceView error={error} dismiss={this.dismiss} />;
            }

            return (
                <ErrorView
                    showBackButton={this.props.showBackButton}
                    error={error}
                    visible={!!error}
                    dismiss={this.dismiss}
                    withoutNavigation={this.props.withoutNavigation}
                />
            );

        }

        return this.props.children;
    }
}

ErrorBoundary.defaultProps = {
    ignoreRestrictedException: false,
};

ErrorBoundary.propTypes = {
    showBackButton: PropTypes.bool,
    withoutNavigation: PropTypes.bool,
    children: CommonPropTypes.requirePositiveElements,
    ignoreRestrictedException: PropTypes.bool,
};

export default ErrorBoundary;
