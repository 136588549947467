import { CAPABILITIES } from '@wsui/core/models/ActiveCompany';
import useActiveCompany from './useActiveCompany';

export default function useCapabilities() {
    const { activeCompany } = useActiveCompany();
    const capabilities = {};
    Object.values(CAPABILITIES).forEach(c => {
        capabilities[c] = {
            canView: activeCompany?.canView(c) || false,
            canUpdate: activeCompany?.canUpdate(c) || false,
            canDelete: activeCompany?.canDelete(c) || false,
            canCreate: activeCompany?.canCreate(c) || false,
        };
    });

    return capabilities;
}
