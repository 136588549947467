import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import useAnalytics from 'app/analytics/useAnalytics';
import Testing from 'app/utils/Testing';
import * as Interaction from 'app/analytics/constants/Interaction';
import styles from './ActionButton.module.scss';

const ActionButton = React.forwardRef((props, ref) => {
    const {
        icon,
        isInDropdown,
        onClick,
        title,
        testAttr,
        disabled,
        analyticsElement,
        ...passthroughProps
    } = props;

    const { trackInteraction } = useAnalytics();

    const onAction = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        trackInteraction({
            element: analyticsElement,
            type: Interaction.Type.Button,
            action: Interaction.Action.Click,
            disabled,
        });

        if (disabled) {
            return;
        }

        onClick && onClick(e);
    }, [ trackInteraction, analyticsElement, disabled, onClick ]);

    return (
        <button
            className={cx(styles.btn, { [styles.inDropdown]: isInDropdown })}
            onClick={onAction}
            ref={ref}
            disabled={disabled}
            {...passthroughProps}
            {...Testing.testAttribute(`Action-${testAttr}`)}
            tabIndex="0"
        >
            <div className={styles.icon}>{icon}</div>
            <span className={styles.title}>{title}</span>
        </button>
    );
});

ActionButton.defaultProps = {
    isInDropdown: false,
    onClick: undefined,
};

ActionButton.propTypes = {
    icon: PropTypes.element,
    /**
     * The id is used as the key when an ActionButton is cloned for use as a
     * menu item.  Typically, its value will be the same as the key's when both
     * are set.
     */
    id: PropTypes.string.isRequired,
    /**
     * True iff this should be styled as an item in a dropdown menu.  By
     * default, it is styled to be a "floating" item in the top nav.
     */
    isInDropdown: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    title: PropTypes.node.isRequired,
    testAttr: PropTypes.string,
    disabled: PropTypes.bool,
    analyticsElement: PropTypes.string.isRequired,
};

export default ActionButton;
