import querystring from 'querystring';

// These special-case query params should never have multiple values when
// defined.  The last value will override all others.
const SINGULAR_PARAMS = [ 'branch', 'env' ];

const getParameterByName = (name) => {
    return parseQueryString()[name];
};

const env = () => {
    const envByHostLookup = {
        'localhost': 'qa',
        'app-qa.paywholesail.com': 'qa',
        'app.paywholesail.com': 'prod',
        'static-dev.paywholesail.com': 'test',
    };

    const envByHost = envByHostLookup[window.location.host.split(':')[0]];
    const envByQueryParam = getParameterByName('env');
    const cleanEnv = envByQueryParam || envByHost;
    if (!cleanEnv) {
        const errorMessage = 'Unable to find server environment ' +
            `for host: ${window.location.host}`;
        throw new Error(errorMessage);
    }
    return cleanEnv;
};

const nodeEnv = () => {
    const envByHostLookup = {
        'localhost': 'local',
        'app-qa.paywholesail.com': 'master',
        'app.paywholesail.com': 'prod',
        'static-dev.paywholesail.com': 'test',
    };

    const envByHost = envByHostLookup[window.location.host.split(':')[0]];
    const envByQueryParam = getParameterByName('nodeenv');
    const cleanEnv = envByQueryParam || envByHost;
    if (!cleanEnv) {
        const errorMessage = 'Unable to find server environment ' +
            `for host: ${window.location.host}`;
        throw new Error(errorMessage);
    }
    return cleanEnv;
};

/**
 * Convert a query string to an Object where each key-value pair becomes a
 * property and its value.
 *
 *   - If a key appears multiple times, the value of the key will be an array
 *     containing all of the values.
 *
 *   - The string literals "true" and "false" will be converted to the booleans
 *     `true` and `false`, respectively.
 *
 *   - The input query string may optionally start with a "?".
 *
 * @param {string} qs
 * @returns {Object}
 */
export function parseQueryString(qs = window.location.search) {
    const trimmed = (qs && qs[0] === '?')
        ? qs.substr(1)
        : qs;

    const obj = querystring.parse(trimmed);

    const obj2 = {};
    for (const prop in obj) {
        let val = obj[prop];
        if (Array.isArray(val) && SINGULAR_PARAMS.includes(prop)) {
            val = val[val.length - 1];
        }

        obj2[prop] = convertType(val);
    }
    return obj2;
}

/**
 * Appends the branch query string parameter to the URL if it is present
 * @param {string} url should not have a query string already
 * @returns {string}
 */
export function appendBranchParameterToUrl(url) {
    if (parseQueryString()['branch']) {
        return `${url}?branch=${parseQueryString()['branch']}`;
    } else {
        return url;
    }
}

/**
 * Convert special-case values to non-string types.
 *
 * @param {string} val
 * @returns {*}
 */
function convertType(val) {
    switch (val) {
    case 'true':
        return true;
    case 'false':
        return false;
    default:
        return val;
    }
}

export default {
    parseQueryString,
    appendBranchParameterToUrl,
    env,
    nodeEnv,
};
