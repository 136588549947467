import { useRef } from 'react';
import useResizeObserver from 'use-resize-observer';
import { Toaster } from 'app/components/utils/ToastProvider';
import { FixedFooterContext } from 'app/components/common/Screen/FixedFooter';

import CustomerFormProvider from './util/CustomerFormProvider';
import OnboardingHeader from './OnboardingHeader';

import styles from './OnboardingScreenFrame.module.scss';

export default function OnboardingScreenFrame({ children }) {
    const { ref, height: fixedFooterHeight } = useResizeObserver();
    const fixedFooterRef = useRef();

    return <FixedFooterContext.Provider value={fixedFooterRef}>
        <CustomerFormProvider>
            <OnboardingHeader />
            <div>
                <div className={styles.content}>
                    {children}
                    <div style={{ height: fixedFooterHeight }} />
                </div>
            </div>
            <div
                ref={ref}
                className={styles.fixedFooter}
            >
                <div style={{height: 0}}>
                    <Toaster className={styles.toast} />
                </div>
                <div ref={fixedFooterRef} />
            </div>
        </CustomerFormProvider>
    </FixedFooterContext.Provider>;
}
