// To be used with GET /userManagement/loginConfig endpoint
import { ROLES } from 'app/constants/UserManagement';

export const USER_FLAGS = {
    SHOWN_PROMOTE_SMS_POPUP: 'SHOWN_PROMOTE_SMS_POPUP',
};

export default class ActiveUser {
    constructor(data) {
        this.data = data;
    }

    get id() {
        return this.data.result;
    }

    get email() {
        return this._user?.user?.email;
    }

    get phoneNumber() {
        return this._user?.user?.phone;
    }

    get accountId () {
        return this.email || this.phoneNumber;
    }

    get _user() {
        return this.data.users[this.id];
    }

    get canSwitchCompanies() {
        return this.data.capabilities[this.id].switchCompanies;
    }

    get switchCompanyCount() {
        return this.data.capabilities[this.id].switchCompanyCount;
    }

    get passwordSet() {
        return this._user.user.passwordSet;
    }

    get isSuperUser() {
        return this.data.capabilities?.[this.id]?.superuser;
    }

    _hasRoleForCompany = (companyId, role) => {
        return this.data.userRoles[this.id].some(r => r.companyId === companyId && r.roleId === role);
    };

    isAdminForCompany = (companyId) => {
        if (!this.data.userRoles[this.id]) {
            return this.isSuperUser;
        }
        return this._hasRoleForCompany(companyId, ROLES.admin) || this.isSuperUser;
    };

    isSalesRepForCompany = (companyId) => {
        if (!this.data.userRoles[this.id]) {
            return false;
        }
        return this._hasRoleForCompany(companyId, ROLES.salesRep);
    };

    hasUserFlag = (flag) => {
        return this.data.users[this.id].flags?.indexOf(flag) >= 0;
    };
}
