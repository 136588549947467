import { defineMessages, FormattedMessage } from 'react-intl';
import { NotFoundException } from '../Exceptions';
import { formatBasisPoints } from '../utils/Utils';

const BUYER = 'BUYER';
const SELLER = 'SELLER';

const types = defineMessages({
    'Wholesail ACH': {
        defaultMessage: 'checking account',
        description: 'Payment account type for fee of type Wholesail ACH',
    },
    'Wholesail CC': {
        defaultMessage: 'card',
        description: 'Payment account type for fee of type Wholesail CC',
    },
});

export function sellerFeeConfigFromTraderRelationship (responseBody, sellerId, buyerId) {
    if (!responseBody || !sellerId || !buyerId) {
        return new FeeConfig({ empty: true });
    }

    const traderRelationships = responseBody.traderRelationships[sellerId] ||
        responseBody.traderRelationships[buyerId];
    const relationships = [ ...traderRelationships.buyers, ...traderRelationships.sellers ];
    const traderRelationship = relationships.filter((i) =>
        i.sellerId === sellerId && i.buyerId === buyerId
    )[0];
    const sellerCCTotalFee = responseBody.companies[sellerId]?.policy?.ccTotalFee;
    const resolvedFees = responseBody.resolvedFeeConfigs[sellerId]?.[buyerId];

    if (!resolvedFees) {
        throw new NotFoundException(
            `trader relationship not found:: buyer=${buyerId} seller=${sellerId}`
        );
    }

    return new FeeConfig({
        ccTotalFee: sellerCCTotalFee,
        feeConfig: resolvedFees,
        traderFees: traderRelationship?.feeConfig,
    });
}

export default class FeeConfig {

    constructor(data) {
        this.data = data;
    }

    isEmpty () {
        // Will be empty if not valid (aka maybe still fetching data)
        return this.data.empty || !this.data || this.data.feeConfig?.fees === undefined;
    }

    getFee (feeType, paymentType) {
        if (this.isEmpty()) {
            return;
        }

        const fees = this.data.feeConfig.fees.filter(
            i => i.feeType === feeType && (!paymentType || i.paymentType === paymentType) && !i.isWholesailServiceFee
        );

        if (fees.length > 1) {
            throw new Error('Unable to determine fee - too many fees');
        }

        return fees[0];
    }

    getFeeBasisPoints (feeType, paymentType) {
        if (this.isEmpty()) {
            return;
        }

        const fee = this.getFee(feeType, paymentType);
        return fee?.term?.basisPoints || 0;
    }

    getBuyerFee (type) {
        return this.getFee(BUYER, type);
    }

    getBuyerFeeBasisPoints (type) {
        return this.getFeeBasisPoints(BUYER, type);
    }

    getCcMaxFeeBasisPoints () {
        return this.data.ccTotalFee?.basisPoints;
    }

    isCustomized () {
        return !!this.data.traderFees?.fees?.length;
    }

    buyerFee = (type, { long = true, omitIfNone = false } = {}) => {
        const basisPoints = this.getBuyerFeeBasisPoints(type);
        const percent = basisPoints && formatBasisPoints(basisPoints);

        if (basisPoints === 0) {
            return omitIfNone ? undefined : 'No fee';
        }

        const label = long ?
            'convenience fee' :
            'fee';
        return percent && `${percent} ${label}`;
    };

    typeName = (paymentType) => {
        return types[paymentType] && <FormattedMessage {...types[paymentType]} />;
    };

    buyerFeeIntl = (type, { long = true, omitIfNone = false, intl}) => {
        const basisPoints = this.getBuyerFeeBasisPoints(type);
        const percent = basisPoints && formatBasisPoints(basisPoints);

        if (basisPoints === 0) {
            return omitIfNone ? undefined : intl.formatMessage({defaultMessage: 'No fee', description: 'No buyer fee'});
        }

        const label = long ?
            intl.formatMessage({
                defaultMessage: 'convenience fee',
                description: 'Long form of the fee description',
            }) :
            intl.formatMessage({
                defaultMessage: 'fee',
                description: 'Short form of the fee description',
            });
        return percent && `${percent} ${label}`;
    };

    sellerFeeIntl = (type, { omitIfNone = false, intl}) => {
        const buyerBasisPoints = this.getFeeBasisPoints(BUYER, type);
        const sellerBasisPoints = this.getFeeBasisPoints(SELLER, type);
        const buyerPercent = buyerBasisPoints && formatBasisPoints(buyerBasisPoints);
        const sellerPercent = sellerBasisPoints && formatBasisPoints(sellerBasisPoints);
        const typeName = this.typeName(type);

        if (buyerBasisPoints === 0 && sellerBasisPoints === 0) {
            return omitIfNone ? undefined : intl.formatMessage({defaultMessage: 'No fee', description: 'No buyer/seller fee'});
        }

        if (buyerBasisPoints && sellerBasisPoints) {
            return intl.formatMessage({
                defaultMessage: 'Customer pays {buyerPercent} and vendor pays {sellerPercent} of the {typeName} convenience fee',
                description: 'Seller fee description where both the buyer and seller contribute',
            }, { buyerPercent, sellerPercent, typeName });
        } else if (buyerBasisPoints) {
            return intl.formatMessage({
                defaultMessage: 'Customer pays a {buyerPercent} {typeName} convenience fee',
                description: 'Seller fee description where just the buyer pays',
            }, { buyerPercent, typeName });
        } else if (sellerBasisPoints) {
            return intl.formatMessage({
                defaultMessage: 'Vendor pays a {sellerPercent} {typeName} convenience fee',
                description: 'Seller fee description where just the seller pays',
            }, { sellerPercent, typeName });
        }
    };
}
