import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { connectModal } from 'app/components/utils/ModalProvider';
import Modal from 'app/components/common/Modal';
import useTrackPageView from 'app/analytics/useTrackPageView';
import * as PageView from 'app/analytics/constants/PageView';

import styles from './GenericModal.module.scss';

export function StickyHeader ({ children, className }) {
    const ref = useRef();
    const [ stuck, setStuck ] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver( 
            ([ e ]) => setStuck(e.intersectionRatio < 1),
            { threshold: [ 1 ] }
        );
        
        observer.observe(ref.current);
    }, []);

    return (
        <div ref={ref} className={cx(styles.stickyHeader, { [styles.stuck]: stuck }, className)}>
            <div className={styles.stickyMask} />
            <div className={styles.stickyContent}>
                {children}
            </div>
        </div>
    );
}

function GenericModal({
    close,
    title: textTitle,
    TitleComponent,
    IconComponent,
    children,
    analyticsEntity,
    analyticsName,
    isLoading,
}) {

    const headerRef = useRef();
    const fixedFooterRef = useRef();
    const [ footerHasContent, setFooterHasContent ] = useState(false);
    const [ scrolledPastTitle, setScrolledPastTitle ] = useState(false);
    const title = textTitle || (TitleComponent && <TitleComponent />);

    const onBodyScroll = useCallback((target) => {
        if (headerRef.current){
            if (target.scrollTop > headerRef.current.clientHeight) {
                if (!scrolledPastTitle) {
                    setScrolledPastTitle(true);
                }
            } else if (scrolledPastTitle) {
                setScrolledPastTitle(false);
            }
        }

    }, [ scrolledPastTitle, setScrolledPastTitle ]);

    useTrackPageView({
        type: PageView.Type.Modal,
        entity: analyticsEntity,
        name: analyticsName,
    }, { isVisible: !isLoading });

    return (
        <Modal.FixedFooterContext.Provider value={{
            ref: fixedFooterRef.current,
            hasContent: setFooterHasContent,
        }}>
            <Modal.Container isLoading={isLoading}>
                <Modal.Header
                    close={close}
                    title={scrolledPastTitle && title}
                />
                <Modal.Body handleScroll={onBodyScroll} footerHasContent={footerHasContent}>
                    <div ref={headerRef}>
                        {IconComponent && <IconComponent width={96} height={96} className={styles.titleIcon} />}
                        <h1 className={styles.title}>
                            {title}
                        </h1>
                    </div>
                    {children}
                </Modal.Body>
                <Modal.Footer ref={fixedFooterRef} />
            </Modal.Container>
        </Modal.FixedFooterContext.Provider>
    );
}

export default connectModal(GenericModal, {
    analyticsModalType: 'Generic Modal',
});

GenericModal.propTypes = {
    close: PropTypes.func,
    title: PropTypes.node,
    TitleComponent: PropTypes.func,
    IconComponent: PropTypes.func,
    children: PropTypes.node,
    analyticsEntity: PropTypes.string.isRequired,
    analyticsName: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
};
