
export default (response) => {
    const { result, companies, traderRelationships } = response.body;
    const availableCompanies = {};

    const traders = (company, type) => {
        const relationshipKey = {
            'buyers': 'buyerId',
            'sellers': 'sellerId',
        }[type];
        const traderRelationship = traderRelationships[company.id];

        if (!traderRelationship) {
            return [];
        }

        return traderRelationship[type]
            .map(relationship => relationship[relationshipKey]);
    };

    result?.forEach(_cid => {
        // Note: due to NET-4570, /available now returns result as an array of objects. Other users of this function
        // still return result as an array of strings. Be careful when changing this
        const cid = typeof _cid === 'string' ? _cid : _cid?.companyId;
        const c = availableCompanies[cid] = companies[cid];
        c.vendors = traders(c, 'sellers');
        c.hasCustomers = traderRelationships[cid].isSeller;
        c.hasVendors = !!c.vendors.length;
        c.vendorNames = c.vendors.map(vid => companies[vid]?.displayName || 'Unknown vendor');
        c.seller = c.vendors[0] && companies[c.vendors[0]];

        // Add extra metadata from the results section if it exists
        if (typeof _cid === 'object') {
            Object.keys(_cid).forEach(i => c[i] = _cid[i]);
        }
    });

    return {
        ...response.body,
        availableCompanies,
    };
};
