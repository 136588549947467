export function handleAuthFlow (res, values, FormikBag) {
    if (res.forceSetPassword) {
        // Save values to the wizard
        FormikBag.props.setWizardState(values);

        // navigate
        FormikBag.props.wizard.goToStep('setPassword');
        return undefined;
    }

    const success = !!res.sessionToken;

    if (success) {
        FormikBag.props.wizard.finish(res);
    } else {
        FormikBag.setFieldError('general', res.message);
    }
}
