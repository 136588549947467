import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { envAppend } from 'app/platform/Storage';
import { setTrackingId } from 'app/analytics/TrackingId';

import { SESSION_LEVEL } from 'app/constants/Auth';

export const USER_SESSION_TOKEN = 'user-session-token';
const EXPIRES_DAYS = 179;

export const decodeAuthToken = (userToken) => {
    return userToken && jwtDecode(userToken);
};

export const getAuthToken = () => {
    return Cookies.get(envAppend(USER_SESSION_TOKEN));
};

export const setAuthToken = (userToken) => {
    try {
        // first decode to make sure it is a decodable token (may be truncated or alterd)
        const decoded = decodeAuthToken(userToken);
        Cookies.set(envAppend(USER_SESSION_TOKEN), userToken, { expires: EXPIRES_DAYS, sameSite: 'strict' });

        // kick off setting of the tracking id
        setTrackingId(decoded?.u || decoded?.uid);

        return decoded;
    } catch (error) {
        if (error.name !== 'InvalidTokenError') {
            throw error;
        }
    }
};

export const getSessionLevel = (decodedUserToken) => {
    let sessionLevel = SESSION_LEVEL.UNAUTHENTICATED;
    if (decodedUserToken) {
        if (!!decodedUserToken
            && (decodedUserToken.grant || decodedUserToken.g)
            && Object.keys(decodedUserToken.grant || decodedUserToken.g).length > 0) {
            sessionLevel = SESSION_LEVEL.RESTRICTED;
        } else {
            sessionLevel = SESSION_LEVEL.UNRESTRICTED;
        }
    }

    return sessionLevel;
};

// Below here are the legacy functions mostly used for AuthenticatedFetch

export const sessionLevel = async () => {
    return window._wsAuthHack.sessionLevel;
};

export const updateRestrictedSession = async (params, nav) => {
    if (params && params._wsrt) {
        const authLevel = await sessionLevel();
        if (authLevel !== SESSION_LEVEL.UNRESTRICTED) {
            window._wsAuthHack.setUserToken(params._wsrt);
        }
        if (nav) {
            const newParams = {
                ...params,
            };
            delete newParams._wsrt;

            nav.replace(nav.state.routeName, newParams);
        }
    }
};

export const onSignOut = async (opts) => {
    window._wsAuthHack.signOut(opts);
};

export const isSignedIn = () => {
    return window._wsAuthHack.sessionToken;
};

// Above here are the legacy functions mostly used for AuthenticatedFetch
