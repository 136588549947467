import * as BuildInfo from 'app/utils/BuildInfo';
import Exceptions from 'app/utils/Exceptions';
import { trackEventWithScreen } from 'app/analytics/Analytics';
import { replaceUUID, isUUID, randomIdOfLength } from 'app/utils/Utils';
import Sentry from 'app/utils/Sentry';

const ERROR_SESSION_REJECTED = 'session_rejected';
const ERROR_INTERNAL_SESSION = 'internal_session'; // another type of rejected
const ERROR_PERMISSION_DENIED = 'permission_denied';
const ERROR_STRIPE_CARD_ERROR = 'card_declined';
export const ERROR_MAINTENANCE_MODE = 'maintenance';

const DEFAULT_HEADERS = {
    'Accept': 'application/json',
};

let seqRequestNum = 0;
function getTraceId() {
    let requestId = (seqRequestNum++).toString(16).substr(0, 6).padStart(6, '0');

    return randomIdOfLength(26) + requestId;
}

function getTraceparent(traceId) {
    return `00-${traceId}-${randomIdOfLength(16)}-00`;
}

function headersWithoutAuth(headers) {
    const {
        Authorization, // eslint-disable-line no-unused-vars
        ...requestHeaders
    } = headers;

    return requestHeaders;
}

function cleanBodyForExternalUse(body) {
    if (typeof body === 'string') {
        return isUUID(body) ? body : 'String';
    } else if (typeof body === 'number') {
        return 0;
    } else if (Array.isArray(body)) {
        // still allow array of ids
        if (body.every(i => typeof i === 'string')) {
            return body.map(i => cleanBodyForExternalUse(i));
        }

        const first = body.length > 0 ? `First: ${cleanBodyForExternalUse(body[0])}` : '';
        return `Array(${body.length}) ${first}`;
    } else if (body && typeof body === 'object') {
        const result = {};
        Object.keys(body).forEach((key) => {
            result[key] = cleanBodyForExternalUse(body[key]);
        });
        return result;
    }

    return body;
}

export const basicFetch = async ({ method, url, headers = {}, body, keepAlive = true }) => {
    const request = {
        method,
        headers,

        // Added so network requests don't cancel on page unload causing FailedToFetchException errors
        // Needs to be overridden for Safari based uploads
        keepalive: keepAlive,
    };

    if (body) {
        request.body = body;
    }

    let response;
    try {
        response = await fetch(url, request);
    } catch (err) {
        const additionalData = {
            method,
            requestHeaders: headersWithoutAuth(request.headers),
            url,
            requestBody: body,
        };

        // Not connected to internet
        if (err instanceof TypeError && !window.navigator.onLine) {
            throw new Exceptions.NetworkException(
                `Network exception: ${err.message}`,
                additionalData
            );
        }

        Sentry.addBreadcrumb({
            category: 'WS Fetch',
            type: 'http',
            level: 'info',
            data: additionalData,
        });

        throw new Exceptions.FailedToFetchException(
            err.message,
            additionalData
        );
    }

    return response;
};

export const makefetch = async ({
    method,
    path,
    headers = {},
    body,
    retries = 3,
    shouldTrackResponse = true,
    retryable,
    isJson = true,
    keepAlive = true,
}) => {
    const { SERVER_BASE } = BuildInfo.getConfig();
    const traceId = getTraceId();
    const requestHeaders = {
        ...DEFAULT_HEADERS,
        traceparent: getTraceparent(traceId),
        ...headers,
    };

    let reqBody = body;
    if (isJson) {
        requestHeaders['Content-Type'] = 'application/json';
        reqBody = body ? JSON.stringify(body) : undefined;
    }

    const startTime = Date.now();
    const response = await basicFetch({
        method,
        url: SERVER_BASE + path,
        headers: requestHeaders,
        body: reqBody,
        keepAlive,
    });
    const requestDuration = Date.now() - startTime;

    const textResponse = response.clone();

    let json, bodyText;
    try {
        bodyText = await textResponse.text();
    } catch (err) {
        // do nothing
    }

    try {
        json = await response.json();
    } catch (err) {
        if (bodyText) {
            json = { bodyText };
        }
        // Attempting to capture more information about an intermittent error where it appears
        // that the server is returning an invalid JSON object, causing issues further down the stack
        // Once diagnosed, it may be possible to remove this.
        if (path.indexOf('plaid') === -1) {
            Sentry.addBreadcrumb({
                category: 'Non JSON response',
                type: 'http',
                level: 'info',
                data: { bodyText },
            });
        }
    }

    const result = {
        status: response.status,
        body: json || {},
        bodyText: bodyText || '',
        traceId,
    };

    // Retry GET requests if `retryable` not defined
    const canRetry = retryable === undefined ? method === 'GET' : retryable;
    const isMaintenanceMode = result.status === 503 && result.body?.error === ERROR_MAINTENANCE_MODE;
    const shouldRetry = retries > 0 && canRetry &&
        result.status >= 500 && result.status <= 599 && !isMaintenanceMode;

    // Track request before retrying
    if (shouldTrackResponse) {
        const error = result.body && result.body.error;

        // This data is going to third-party services. Don't send PII or sensitive data
        const exportData = {
            method,
            trace_id: traceId,
            duration: requestDuration,
            retrying: shouldRetry,
        };

        if (error) {
            exportData.error = JSON.stringify(error);
        }

        try {
            trackEventWithScreen('NetworkRequest', {
                ...exportData,
                path_pattern: replaceUUID(path),
                status: result.status,
                path,
            });
        } catch (error) {
            // do nothing with this error
        }

        Sentry.addBreadcrumb({
            category: 'WS Fetch',
            type: 'http',
            level: 'info',
            data: {
                ...exportData,
                url: SERVER_BASE + path,
                status_code: result.status,
                duration: (exportData.duration / 1000) + 's',
                requestBody: cleanBodyForExternalUse(body),
                responseBody: cleanBodyForExternalUse(result.body),
            },
        });
    }

    // Make the retry request
    if (shouldRetry) {
        return makefetch({
            method,
            path,
            headers,
            body,
            retries: retries - 1,
            retryable,
        });
    }

    const additionalData = {
        method,
        path,
        body,
        status: result.status,
        bodyText: result.bodyText,
        json,
    };

    if (
        result.status === 403 &&
        result.body &&
        result.body.error
    ) {
        if (result.body.detail && result.body.detail.soft) {
            throw new Exceptions.RestrictedSessionException(
                result.body.message,
                additionalData
            );
        } else if (result.body.error === ERROR_PERMISSION_DENIED) {
            throw new Exceptions.PermissionDeniedException(
                result.body.message,
                additionalData
            );
        }
    }

    if (
        result.status === 400 &&
        result.body &&
        result.body.error
    ) {
        if (
            result.body.error === ERROR_STRIPE_CARD_ERROR &&
            result.body.message
        ) {
            throw new Exceptions.StripeCardException(
                result.body.message,
                additionalData
            );
        }
    }

    if (
        result.status >= 400 &&
        result.status < 500 &&
        [ ERROR_INTERNAL_SESSION, ERROR_SESSION_REJECTED ].includes(result.body?.error)
    ) {
        throw new Exceptions.UnauthenticatedRequestException(
            'Token rejected/missing',
            additionalData
        );
    }

    if (result.status === 404) {
        throw new Exceptions.NotFoundException(
            `${method}: ${path} could not be found`,
            additionalData
        );
    }

    if (result.status === 503 && result?.body?.error === ERROR_MAINTENANCE_MODE) {
        throw new Exceptions.MaintenanceException(
            result?.body?.message,
            additionalData,
        );
    }

    if ([ 400, 409, 422 ].includes(result.status)) {
        const { error = 'UnknownError' } = result.body;
        throw new Exceptions.BadRequestException(
            `BadRequest: ${path} - ${error}`,
            additionalData
        );
    }

    if (result.status >= 400 && result.status < 600) {
        throw new Exceptions.UnexpectedServerException(
            `unexpected server code ${result.status}`,
            additionalData
        );
    }

    return result;
};

export const unauthenticatedFetch = (method, path, body, opts = {}) => {
    return makefetch({
        method,
        path,
        body,
        ...opts,
    });
};

export const authenticatedFetch = async ({
    getTokenPromise,
    clearTokenPromise,
    requireSignIn,
    method,
    path,
    body,
    retryable,
    ...rest
}) => {
    const token = await getTokenPromise();

    if (!token) {
        return requireSignIn();
    }

    try {
        const response = await makefetch({
            method,
            path,
            body,
            headers: {
                Authorization: `ws ${token}`,
            },
            retryable,
            ...rest,
        });
        return response;
    } catch (err) {
        if (err.name === 'UnauthenticatedRequestException') {
            await clearTokenPromise({ navigateToSignin: false });
            return requireSignIn();
        }

        throw err;
    }
};
