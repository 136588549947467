import React from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import User from 'app/utils/User';
import TopLabel from 'app/components/common/TopLabel';
import TextInput from 'app/components/common/TextInput';
import Button from 'app/components/common/Button';
import FormLayout from 'app/components/common/FormLayout';
import { StepPropTypes } from 'app/components/common/Wizard/Step';
import withOnRef from 'app/components/utils/withOnRef';
import * as SignInUtils from './SignInUtils';
import styles from './SignInFlow.module.scss';

const PasswordSchema = Yup.object().shape({
    password: Yup.string().required('Password required'),
});

export class PasswordStep extends React.PureComponent {

    _input = React.createRef();

    wizardDidFocus () {
        if (this._input.current) {
            this._input.current.focus();
        }
    }

    _onForgotPassword = () => {
        this.props.setWizardState({ forgotPassword: true });
        this.props.wizard.goToStep('passcode');
    };

    render () {
        const {
            touched,
            errors,
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            wizardState,
        } = this.props;

        return (
            <Form name='signIn'>
                <h2 className={styles.text}>
                    <FormattedMessage
                        defaultMessage='Sign in with your password to continue'
                        description='Title for password step of login'
                    />
                </h2>
                <input
                    name='username'
                    autoComplete='username'
                    readOnly
                    value={wizardState.username}
                    className={styles.hide}
                />
                <TopLabel
                    label={<FormattedMessage
                        defaultMessage='Password'
                        description='Password input label on password step of login'
                    />}
                    actionLabel={<FormattedMessage
                        defaultMessage='Forgot password'
                        description='forgot password action link on password step of login'
                    />}
                    onAction={this._onForgotPassword}
                    helperText={<FormattedMessage
                        defaultMessage='You’ll only need to sign in once per device.'
                        description='Password input helper text on password step of login'
                    />}
                    warningText={touched.password && errors.password}
                    testingName='password'
                >
                    <TextInput
                        ref={this._input}
                        type='password'
                        value={values.password}
                        onChangeText={handleChange('password')}
                        onBlur={handleBlur('password')}
                        analyticsElement='password'
                        testAttribute='passwordInput'
                        name='password'
                        autoComplete='current-password'
                    />
                </TopLabel>
                <TopLabel testingName='error' warningText={errors.general} />
                <FormLayout>
                    <Button
                        onClick={handleSubmit}
                        title={<FormattedMessage
                            defaultMessage='Sign in'
                            description='Submit button on password step of login'
                        />}
                        isLoading={isSubmitting}
                        analyticsElement='Password Sign In'
                        testAttribute='signInButton'
                        nativeType='submit'
                    />
                </FormLayout>
            </Form>
        );
    }
}

PasswordStep.propTypes = {
    ...StepPropTypes,
};

const FormikPasswordStep = withFormik({
    mapPropsToValues: () => ({ password: '' }),
    validationSchema: PasswordSchema,
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, FormikBag) => {
        // clear passcode from wizard state incase we move forward
        FormikBag.props.setWizardState({ passcode: '' });

        const res = await User.signinUsername(
            FormikBag.props.wizardState.username,
            values.password,
            'Incorrect password'
        );

        SignInUtils.handleAuthFlow(res, values, FormikBag);

        FormikBag.setValues({ password: '' }, false);
    },
})(withOnRef(PasswordStep));

export default FormikPasswordStep;
