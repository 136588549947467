import Cookies from 'js-cookie';
import { envAppend } from 'app/platform/Storage';

// using `tid` so people don't see a cookie with the word "Tracking" in it
const TRACKING_ID = 'tid';

export const setTrackingId = async (trackingId) => {
    if (trackingId) {
        Cookies.set(envAppend(TRACKING_ID), trackingId, { expires: 3650, sameSite: 'strict' });
        return trackingId;
    }
};

export const getTrackingId = async () => {
    // Get the saved cookie trackingId
    return Cookies.get(envAppend(TRACKING_ID));
};
