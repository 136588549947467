import { lazy } from 'react';

const AdminScreenFrame = lazy(() => import(/* webpackChunkName:"internal" */'app/admin/AdminScreenFrame/AdminScreenFrame'));

const routes = {
    SuperadminHome: {
        pattern: '/superadmin',
    },
    SANotificationPreview: {
        pattern: '/superadmin/notification/preview',
    },
};

// Update global settings for Superadmin screens :)
Object.keys(routes).forEach((routeName) => {
    const route = routes[routeName];

    route.ScreenFrame = route.ScreenFrame || AdminScreenFrame;
    route.props = {
        isSuperadmin: true,
        ...(route.props || {}),
    };
});

export default routes;
