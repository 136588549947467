import { useMemo } from 'react';
import PropTypes from 'prop-types';

import useTabs from '../useTabs';
import Tab from '../Tab';
import MoreTab from '../Tab/MoreTab';
import styles from './BottomNav.module.scss';

function BottomNav ({ activeNavTab }) {
    const tabs = useTabs({ isDesktop: false });

    const { extraTabs, standardTabs } = useMemo(() => {
        let extraTabs;
        let standardTabs = tabs;
        if (tabs.length > 5) {
            standardTabs = tabs.slice(0, 4);
            extraTabs = tabs.slice(4);
        }
        return {
            standardTabs,
            extraTabs,
        };
    }, [ tabs ]);

    return (
        <div className={styles.tabs} id='bottom-nav'>
            {standardTabs.map(tab => (<Tab key={tab.tabName} activeNavTab={activeNavTab} tab={tab} />))}
            <MoreTab tabs={extraTabs} activeNavTab={activeNavTab} />
        </div>
    );

}

BottomNav.propTypes = {
    activeNavTab: PropTypes.string,
};

export default BottomNav;

