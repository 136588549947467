import Navigation from './Navigation';

let router;

const SIGN_IN = 'SignIn';

export const setInstance = (r) => router = r;

// Added to support mobile
export const navigate = async (path) => {
    const { navigate } = Navigation.navigator();
    navigate(path);
};
export const instance = () => router;
export const generatePath = (...args) => instance().generatePath(...args);

export const navigateToSignIn = () => {
    const { routeName, params } = Navigation.getScreenState();
    const nextPath = routeName !== SIGN_IN && generatePath(routeName, params);
    const next = params.next || nextPath || null;

    Navigation.navigate(SIGN_IN, next && { next });
};

export default {
    navigate,
    instance,
    generatePath,
};
