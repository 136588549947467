import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import useActiveUser from 'app/hooks/useActiveUser';
import Button from 'app/components/common/Button';
import Screen from 'app/components/common/Screen';
import ActivityIndicator from 'app/components/common/ActivityIndicator';
import RestrictedSessionModal from 'app/components/modals/RestrictedSession';

export default function RestrictedView({ clearError }) {

    const [ visible, setVisible ] = useState(true);
    const { activeUser } = useActiveUser();

    const openModal = () => setVisible( true );

    const handleFinish = ({ success } = {}) => {
        setVisible(false);

        if (success) {
            clearError();
        }
    };

    if (!activeUser) {
        return <Screen>
            <ActivityIndicator />
        </Screen>;
    }

    const { passwordSet, email, phone } = activeUser;
    const username = email || phone;

    return <Screen
        title={!visible && <FormattedMessage
            defaultMessage="You'll need to sign in to view this page"
            description='Restricted view title when user needs to sign in to view a page'
        />}
        description={!visible && <FormattedMessage
            defaultMessage="You'll need to sign in once per device"
            description='Details on restricted view about signing in once per device'
        />}
    >
        {!visible && <Button
            type='primary'
            onClick={openModal}
            title={<FormattedMessage defaultMessage='Sign in' description='Sign in button on restricted view' />}
            analyticsElement={'Open sign in modal'}
        />}
        <RestrictedSessionModal
            visible={visible}
            currentUser={{ username, passwordSet }}
            onClose={handleFinish}
        />
    </Screen>;
}

RestrictedView.propTypes = {
    clearError: PropTypes.func.isRequired,
};
