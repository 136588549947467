import { useContext, createContext } from 'react';
import { createPortal } from 'react-dom';

export const FixedFooterContext = createContext();

export default function FixedFooter ({ children }) {
    const footerContextRef = useContext(FixedFooterContext);

    if (!footerContextRef.current) {
        return null;
    }

    return createPortal(
        children,
        footerContextRef.current
    );
}
