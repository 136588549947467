import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import Payment from '@wsui/core/models/Payment';
import { getPaymentBadges } from '@wsui/core/formatted/Payment';

import GlobeSvg from 'app/assets/icons/globe.svg';
import WrenchSvg from 'app/assets/icons/wrench.svg';

import styles from './Badge.module.scss';

export const PaymentStatusBadge = ({ className, payment }) => {
    const paymentObj = new Payment(payment);
    const badges = getPaymentBadges(paymentObj);

    return <Badges badges={badges} className={className} />;
};

export function GlobalBadge ({ className }) {
    return <Badge level='default' className={cx(styles.iconBadge, className)}>
        <GlobeSvg width={10} height={10} />
        <FormattedMessage
            defaultMessage='Global'
            description='Text for global badge'
        />
    </Badge>;
}

export function CustomBadge ({ className }) {
    return <Badge level='attention' className={cx(styles.iconBadge, className)}>
        <WrenchSvg width={10} height={10} />
        <FormattedMessage
            defaultMessage='Custom'
            description='Text for custom badge'
        />
    </Badge>;
}

export function Badges ({ badges, className }) {
    return badges.map(i => <Badge {...i} key={i.key} className={className} />);
}

export default function Badge ({ className, children, label, level, direction }) {
    return (<span className={styles.badgeHolder}>
        {direction === 'left' && <span className={cx(styles.arrow, styles.left, styles[level])}></span>}
        <small className={cx(styles.badge, styles[level], styles[direction], className)}>
            {children || label}
        </small>
        {direction === 'right' && <span className={cx(styles.arrow, styles.right, styles[level])}></span>}
    </span>);
}

Badge.propTypes = {
    children: PropTypes.any,
    label: PropTypes.any,
    className: PropTypes.string,
    level: PropTypes.oneOf(['attention', 'default', 'warning', 'promotion']),
    direction: PropTypes.oneOf(['left', 'right']),
};

Badge.defaultProps = {
    level: 'default',
};
