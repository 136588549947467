
// Error matching the error's name
(Error as any).prototype.isErrorName = function (name: string | Array<string>) {
    const names = Array.isArray(name) ? name : [name];
    return names.indexOf(this.name) >= 0;
};

// Error matching the `error` key on the json repsonse
(Error as any).prototype.isErrorType = function () {
    return false;
};

// Error of a specific name and type
(Error as any).prototype.isError = function (name: string | Array<string>, type: string | Array<string>) {
    return this.isErrorName(name) && this.isErrorType(type);
};

class CustomException extends Error {
    additionalData?: any;
    suppressSentry: boolean;

    constructor(name: string, message: string, additionalData?: object) {
        super(message);
        this.name = name;
        this.additionalData = additionalData;
        this.suppressSentry = false;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }

    isErrorType = (errorType: string | Array<string>) => {
        const errorTypes = Array.isArray(errorType) ? errorType : [errorType];

        return !!this.json().error && errorTypes.indexOf(this.json().error) >= 0;
    };

    json = () => {
        return this.additionalData && this.additionalData?.json || {};
    };
}

export class RestrictedSessionException extends CustomException {
    constructor(message: string, additionalData?: any) {
        super('RestrictedSessionException', message, additionalData);
        this.suppressSentry = true;
    }
}

export class StripeCardException extends CustomException {
    constructor(message: string, additionalData?: any) {
        super('StripeCardException', message, additionalData);
        this.suppressSentry = true;
    }
}

export class NotFoundException extends CustomException {
    constructor(message: string, additionalData?: any) {
        super('NotFoundException', message, additionalData);
        this.suppressSentry = true;
    }
}

export class EmptyResponseException extends CustomException {
    constructor(message: string, additionalData?: any) {
        super('EmptyResponseException', message, additionalData);
        this.suppressSentry = false;
    }
}

export class PermissionDeniedException extends CustomException {
    constructor(message: string, additionalData?: any) {
        super('PermissionDeniedException', message, additionalData);
        this.suppressSentry = true;
    }
}

export class UnexpectedServerException extends CustomException {
    constructor(message: string, additionalData?: any) {
        super('UnexpectedServerException', message, additionalData);
    }
}

export class NetworkException extends CustomException {
    constructor(message: string, additionalData?: any) {
        super('NetworkException', message, additionalData);
        this.suppressSentry = true;
    }
}

export class FailedToFetchException extends CustomException {
    constructor(message: string, additionalData?: any) {
        super('FailedToFetchException', message, additionalData);
    }
}

export class UnauthenticatedRequestException extends CustomException {
    constructor(message: string, additionalData?: any) {
        super('UnauthenticatedRequestException', message, additionalData);
    }
}

export class BadRequestException extends CustomException {
    constructor(message: string, additionalData?: any) {
        super('BadRequestException', message, additionalData);
    }
}

export class MaintenanceException extends CustomException {
    constructor(message: string, additionalData?: any) {
        super('MaintenanceException', message, additionalData);
        this.suppressSentry = true;
    }
}

