import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CommonPropTypes from 'app/utils/CommonPropTypes';
import Conditional from 'app/components/utils/Conditional';
import ActivityIndicator from 'app/components/common/ActivityIndicator';
import useBreakpoints from 'app/hooks/useBreakpoints';
import CommonColors from '@wsui/core/Colors';
import Testing from 'app/utils/Testing';
import styles from './Container.module.scss';

export default function Container ({ isLoading, dark, size, children }) {
    const contentLoaded = isLoading ? 'LOADING' : 'LOADED';
    const testingAttr = Testing.testAttribute(`MODAL_CONTENT_${contentLoaded}`);
    const { isDesktopWeb } = useBreakpoints();

    return (
        <React.Fragment>
            <Conditional condition={isLoading}>
                <div className={styles.indicator}>
                    <ActivityIndicator
                        {...testingAttr}
                        color={isDesktopWeb ? CommonColors.snow : 'primary'}
                        size='large'
                    />
                </div>
            </Conditional>
            <Conditional condition={!isLoading}>
                <div className={styles.wrapper}>
                    <div
                        {...testingAttr}
                        className={cx(styles.contents, styles[size], { [styles.dark]: dark })}
                    >
                        {children}
                    </div>
                </div>
            </Conditional>
        </React.Fragment>
    );
}

Container.propTypes = {
    isLoading: PropTypes.bool,
    dark: PropTypes.bool,
    children: CommonPropTypes.optionalPositiveElements,
    size: PropTypes.oneOf(['small', 'large']),
};

Container.defaultProps = {
    size: 'small',
    isLoading: false,
};
