import { trackEvent } from 'app/analytics/Analytics';

const reportWebVitals = () => {
    const track = ({name, delta, value, id}) => {
        trackEvent('WebVital', {
            metric_name: name,
            metric_delta: delta,
            metric_value: value,
            metric_id: id,
        });
    };

    import('web-vitals').then(({ onCLS, onFID, onFCP, onLCP, onTTFB, onINP }) => {
        onCLS(track);
        onFID(track);
        onLCP(track);
        onINP(track);
        onFCP(track);
        onTTFB(track);
    });
};

export default reportWebVitals;
