import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/locale-data/en';
import { getFormats } from '@wsui/core/utils/IntlFormats.js';

import { NOOP } from 'app/utils/Utils';
import * as BuildInfo from 'app/utils/BuildInfo';
import Cookies from 'js-cookie';
import { envAppend } from 'app/platform/Storage';
import * as FeatureFlag from 'app/utils/FeatureFlag';
import TrackedExternalLink from 'app/components/common/ExternalLink/TrackedExternalLink';

function loadLocaleData(locale) {
    switch (locale) {
    case 'es-US':
        return import('compiled-lang/app_es-US.json');
    default:
        return FeatureFlag.get(FeatureFlag.FEATURE.FANCY_LANG) ?
            import('compiled-lang/app_en-XA.json') : import('compiled-lang/app_en-US.json');
    }
}

export const IntlContext = React.createContext({});

const LOCALE_COOKIE = 'locale-override';
const SUPPORTED_LOCALES = ['en-US', 'es-US'];

function IntlProvider({ children, overrideMessages }) {
    const cookieLocale = Cookies.get(envAppend(LOCALE_COOKIE));
    const { serverEnv } = BuildInfo.getConfig();

    let browserLocale = cookieLocale || navigator.language;
    if (!browserLocale || browserLocale.indexOf('en') === 0) {
        browserLocale = 'en-US';
    } else if (browserLocale.indexOf('es') === 0) {
        browserLocale = 'es-US';
    }

    if (SUPPORTED_LOCALES.indexOf(browserLocale) < 0) {
        browserLocale = 'en-US';
    }

    const [messages, setMessages] = useState(overrideMessages);
    const [locale, _setLocale] = useState(browserLocale);
    const [sellerTz, setSellerTz] = useState();

    const defaultFormats = useMemo(() => getFormats(sellerTz), [sellerTz]);

    const setLocale = useCallback((newLocale) => {
        Cookies.set(envAppend(LOCALE_COOKIE), newLocale, { sameSite: 'strict' });
        _setLocale(newLocale);
    }, [_setLocale]);

    useEffect(() => {
        (async () => {
            if (locale && !overrideMessages) {
                const loadedMessages = await loadLocaleData(locale);
                setMessages(loadedMessages);
            }
        })();
    }, [setMessages, locale, overrideMessages]);

    return (
        <IntlContext.Provider value={{
            setLocale,
            setSellerTz,
        }}>
            <ReactIntlProvider
                onError={serverEnv.isTest() ? NOOP : undefined}
                locale={locale}
                key={locale}
                messages={messages}
                formats={defaultFormats}
                defaultFormats={defaultFormats}
                defaultRichTextElements={{
                    highLegibility: chunks => <span className='doc-id'>{chunks}</span>,
                    b: chunks => <strong>{chunks}</strong>,
                    h3: chunks => <h3>{chunks}</h3>,
                    li: chunks => <li>{chunks}</li>,
                    p: chunks => <p>{chunks}</p>,
                    ul: chunks => <ul>{chunks}</ul>,
                    docId: chunks => <span className="doc-id">{chunks}</span>,
                    lowerCase: chunks => <span className='case-lower'>{chunks}</span>,
                    br: <br />,
                    supportEmail: <TrackedExternalLink
                        title='support@paywholesail.com'
                        link='mailto:support@paywholesail.com'
                        analyticsElement='support email'
                    />,
                }}
            >
                {children}
            </ReactIntlProvider>
        </IntlContext.Provider>
    );
}

export default IntlProvider;
