import cx from 'classnames';
import PropTypes from 'prop-types';
import Testing from 'app/utils/Testing';
import useNavigation from 'app/hooks/useNavigation';
import * as Interaction from 'app/analytics/constants/Interaction';
import useAnalytics from 'app/analytics/useAnalytics';
import useActiveCompany from 'app/hooks/useActiveCompany';
import BackSvg from 'app/assets/icons/back.svg';
import styles from './BackButton.module.scss';

export function BackButton ({
    className,
    fallbackRoute,
}) {
    const { activeCompanyId } = useActiveCompany();
    const { goBack } = useNavigation();
    const { trackInteraction } = useAnalytics();

    const handleBack = () => {
        trackInteraction({
            element: 'Back Button',
            type: Interaction.Type.Button,
            action: Interaction.Action.Click,
        });

        goBack(fallbackRoute.routeName, {
            companyId: activeCompanyId,
            ...fallbackRoute.params,
        });
    };

    const hasFallback = !!fallbackRoute?.routeName;

    if (!hasFallback) {
        return null;
    }

    return <button
        {...Testing.testAttribute('goBack')}
        onClick={handleBack}
        className={cx(styles.button, className)}
    >
        <BackSvg width={24} height={24} />
    </button>;

}

BackButton.propTypes = {
    className: PropTypes.string,
    fallbackRoute: PropTypes.object,
};

export default BackButton;
