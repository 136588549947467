import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'app/components/common/Button';
import useNavigation from 'app/hooks/useNavigation';
import { NOOP } from 'app/utils/Utils';

function ErrorBackButton ({
    onClick,
    ...passThroughProps
}) {
    const { navigate, state } = useNavigation();
    const handleClick = () => {
        onClick();
        if (state.routeParams.errorBehavior) {
            if (state.routeParams.errorBehavior === 'refresh') {
                window.location.reload();
            }
        } else {
            navigate('Default');
        }
    };

    return (
        <Button
            title={<FormattedMessage
                defaultMessage="Go home"
                description="Button title to go home from error view"
            />}
            onClick={handleClick}
            analyticsElement='Error go back'
            {...passThroughProps}
        />
    );
}

ErrorBackButton.defaultProps = {
    onClick: NOOP,
};

ErrorBackButton.propTypes = {
    onClick: PropTypes.func,
};

export default ErrorBackButton;
