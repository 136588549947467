import Utils from 'app/utils/Utils';
import useActiveCompany from 'app/hooks/useActiveCompany';
import useNavigation from 'app/hooks/useNavigation';
import useCapabilities from 'app/hooks/useCapabilities';

function DefaultScreen () {
    const { activeCompany, activeCompanyId } = useActiveCompany();
    const capabilities = useCapabilities();
    const { replace, navigate } = useNavigation();

    if (activeCompanyId) {
        if (activeCompany) {
            const defaultScreen = Utils.defaultScreen(activeCompany, capabilities);
            replace(
                defaultScreen.route,
                defaultScreen.params
            );
        }
    } else {
        navigate('SignIn', { next: null });
    }

    return null;
}

export default DefaultScreen;
