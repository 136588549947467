import cx from 'classnames';
import styles from './Footer.module.scss';
import { forwardRef } from 'react';

const Footer = forwardRef(({ className, children }, ref) => {
    return (
        <div ref={ref} className={cx(styles.footer, className)}>
            {children}
        </div>
    );
});

export default Footer;
