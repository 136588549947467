import { AppEnv, ServerEnv, NodeEnv } from '@wsui/core/EnvConfig';
import BUILD_INFO from '@wsui/core/appInfo.json';
import Url from 'app/platform/Url';

export * from '@wsui/core/EnvConfig';

/**
 * Note: the "BuildInfo" does not change after inital load. We used to compute on every
 * calling of these functions, but it will now cache the results and the function calls
 * just return the cached result. These are called very heavily so we should try to make
 * sure they do as little work as possible on each invocatiion.
 */

// Managed outside of Turbo
// eslint-disable-next-line turbo/no-undeclared-env-vars
export const release = () => process.env.RELEASE;

// Managed outside of Turbo
// eslint-disable-next-line turbo/no-undeclared-env-vars
const _appEnv = new AppEnv(process.env.WS_APP_ENV);
export const appEnv = () => _appEnv;

const _serverEnv = new ServerEnv(_appEnv.isTest() ? 'test' : Url.env());
export const serverEnv = () => _serverEnv;

const _nodeEnv = new NodeEnv(_appEnv.isTest() ? 'test' : Url.nodeEnv());
export const nodeEnv = () => _nodeEnv;

export const isFullProd = () => {
    return _appEnv.isProd() && _serverEnv.isProd();
};

const isFullQA = () => {
    return _appEnv.isQA() && _serverEnv.isQA();
};

export const isTeststackServer = () => {
    return _serverEnv.isTeststack() || _serverEnv.isLocal();
};

// For sentry/mixpanel event tracking: which env should be used
const _analyticsEnv = (() => {
    if (isFullProd()) {
        return 'prod';
    } else if (isFullQA()) {
        return 'qa';
    } else if (_serverEnv.isTeststack() || _serverEnv.isDemo() || _serverEnv.isDynamic() || _serverEnv.isNamespace()) {
        return _serverEnv.name();
    } else if (_appEnv.isTest()) {
        return 'test';
    } else if (_appEnv.isLocal()) {
        return 'local';
    }
    return 'dev';
})();
export const analyticsEnv = () => _analyticsEnv;

const _config = {
    ..._serverEnv.config,
    // appConfig contains configs that are independent of the app env
    ...BUILD_INFO.appConfig,
    ..._appEnv.config,
    ...(BUILD_INFO.analyticsEnvConfig[_analyticsEnv] || {}),
    ..._serverEnv.serverBase,
    serverEnv: _serverEnv,
    appEnv: _appEnv,
    nodeEnv: _nodeEnv,
    analyticsEnv: _analyticsEnv,
};
export const getConfig = () => _config;
