import PropTypes from 'prop-types';
import Circle from 'app/components/common/Circle';
import CommonColors from '@wsui/core/Colors';
import { connectModal } from 'app/components/utils/ModalProvider';
import Modal from 'app/components/common/Modal';
import * as PageView from 'app/analytics/constants/PageView';
import Conditional from 'app/components/utils/Conditional';
import SignInFlow from 'app/components/auth/SignInScreen/SignInFlow';
import useTrackPageView from 'app/analytics/useTrackPageView';

import LockSvg from 'app/assets/icons/lock.svg';

import styles from './RestrictedSession.module.scss';

function RestrictedSession ({ close, currentUser, canClose = true }) {

    useTrackPageView({
        type: PageView.Type.Modal,
        entity: PageView.Entity.User,
        name: 'Restricted modal',
        passwordSet: currentUser.passwordSet,
    });

    return (
        <Modal.Container>
            <Modal.Header close={canClose && close} />
            <Modal.Body className={styles.body}>
                <Conditional isDesktopWeb={true}>
                    <Circle
                        diameter={72}
                        backgroundColor={CommonColors.surfie}
                        className={styles.circle}
                    >
                        <LockSvg width={48} height={48} />
                    </Circle>
                </Conditional>
                <SignInFlow currentUser={currentUser} onFinish={close} />
            </Modal.Body>
        </Modal.Container>
    );
}

RestrictedSession.propTypes = {
    canClose: PropTypes.bool,
    close: PropTypes.func.isRequired,
    currentUser: SignInFlow.propTypes.currentUser,
};

export default connectModal(RestrictedSession, {
    analyticsModalType: 'Restricted session',
});
