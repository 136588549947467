import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { envAppend } from 'app/platform/Storage';
import useNavigation from 'app/hooks/useNavigation';

export const COMPANY_ID_COOKIE = 'last-used-company';

export const ActiveCompanyIdContext = React.createContext({});

function ActiveCompanyProvider ({ children }) {
    const [ cookieActiveCompany, setCookieActiveCompany ] = useState(Cookies.get(envAppend(COMPANY_ID_COOKIE)));
    const navigation = useNavigation();
    const navActiveCompany = navigation?.state?.params?.companyId;
    const activeCompanyId = navActiveCompany || cookieActiveCompany;

    const setActiveCompanyId = useCallback((companyId) => {
        Cookies.set(envAppend(COMPANY_ID_COOKIE), companyId, { sameSite: 'strict' });
        setCookieActiveCompany(companyId);
    }, [ setCookieActiveCompany ]);

    const clearActiveCompanyId = useCallback(() => {
        Cookies.remove(envAppend(COMPANY_ID_COOKIE));
        setCookieActiveCompany(undefined);
    }, [ setCookieActiveCompany ]);

    if (activeCompanyId && cookieActiveCompany !== activeCompanyId) {
        setActiveCompanyId(activeCompanyId);
    }

    // TODO: get rid of this!! OMG this is so bad
    window._wsCompanyIdHack = activeCompanyId;

    return (
        <ActiveCompanyIdContext.Provider value={{ activeCompanyId, clearActiveCompanyId }}>
            {children}
        </ActiveCompanyIdContext.Provider>
    );
}

ActiveCompanyProvider.propTypes = {
    children: PropTypes.node,
};

export default ActiveCompanyProvider;
