import { useCallback, useEffect, useRef, forwardRef } from 'react';
import { Popover as ReactPopover } from 'react-tiny-popover';
import * as focusManager from 'react-modal/lib/helpers/focusManager';

const Popover = forwardRef(({ isOpen, content, onEscDown, focusElClassName, ...props }, ref) => {
    const node = useRef();

    useEffect(() => {
        if (isOpen && node?.current) {
            focusManager.setupScopedFocus(node?.current);
            focusManager.markForFocusLater();

            node?.current.focus();
        } else if (!isOpen) {
            focusManager.returnFocus();
            focusManager.teardownScopedFocus();
        }
    }, [isOpen]);

    const handleEscDown = useCallback((evt) => {
        if (isOpen && evt.key === 'Escape') {
            onEscDown?.();
        }
    }, [isOpen, onEscDown]);

    useEffect(() => {
        document.addEventListener('keydown', handleEscDown, false);
        return () => document.removeEventListener('keydown', handleEscDown, false);
    });

    return (
        <ReactPopover
            ref={ref}
            {...props}
            isOpen={isOpen}
            content={(popoverState) => (
                <div tabIndex='-1' ref={node} className={focusElClassName}>
                    {typeof content === 'function' ? content(popoverState) : content}
                </div>
            )}
        />
    );
});

export default Popover;
