import PropTypes from 'prop-types';
import cx from 'classnames';
import CompanyLogo from 'app/components/common/CompanyLogo';
import Testing from 'app/utils/Testing';
import styles from './Header.module.scss';

const LOGO_SIZES = {
    small: 48,
    large: 72,
};

function Header ({ logoUri, logoSize, title, description, className, isTopLevel }) {

    if (!logoUri && !title && !description) {
        return null;
    }

    let size = LOGO_SIZES.small;
    if (logoSize && LOGO_SIZES[logoSize]) {
        size = LOGO_SIZES[logoSize];
    }

    return (
        <div className={cx(styles.wrapper, className)}>
            {logoUri && <div className={styles.companyLogoWrapper}>
                <CompanyLogo uri={logoUri } diameter={size} />
            </div>}
            <div className={cx(styles.textWrapper, { [styles.topLevel]: isTopLevel })}>
                <h1 {...Testing.testAttribute('screenTitle')}>
                    {title}
                </h1>
                {description && <div className={styles.description}>{description}</div>}
            </div>
        </div>
    );
}

Header.propTypes = {
    logoUri: PropTypes.string,
    logoSize: PropTypes.oneOf([ 'small', 'large' ]),
    title: PropTypes.node,
    description: PropTypes.node,
    className: PropTypes.string,
    isTopLevel: PropTypes.bool,
};

export default Header;
