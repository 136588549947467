import { useState, createContext } from 'react';
import CommonPropTypes from 'app/utils/CommonPropTypes';

export const CartContext = createContext({});

function CartContextProvider (props) {
    const [cartState, setCartState] = useState({});
    
    return (
        <CartContext.Provider value={{
            cartState,
            setCartState,
        }}>
            { props.children }
        </CartContext.Provider>
    );

}

CartContextProvider.propTypes = {
    children: CommonPropTypes.requirePositiveElements,
};

export default CartContextProvider;

