import { FormattedMessage } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';

import User from 'app/utils/User';
import styles from './MaintenanceView.module.scss';

import ConstructionBadgeSvg from 'app/assets/illustrations/construction-badge.svg';
import { useEffect } from 'react';

const fetchStatus = async () => {
    const res = await User.authenticatedFetch(
        null,
        '/userManagement/loginConfig',
        'GET',
    );

    return res?.body;
};

export default function MaintenanceView ({ error, dismiss }) {
    const queryClient = useQueryClient();
    const { isSuccess } = useQuery(['systemCheck'], fetchStatus, {
        refetchInterval: 15_000,
        retry: false,
        useErrorBoundary: false,
    });

    useEffect(() => {
        if (isSuccess) {
            queryClient.resetQueries();
            dismiss();
        }
    }, [isSuccess, dismiss, queryClient]);

    return (
        <div className={styles.content}>
            <ConstructionBadgeSvg className={styles.icon} />
            <h2>
                <FormattedMessage
                    defaultMessage="We'll be right back"
                    description='Title for maintenance error page'
                />
            </h2>
            <p>
                {error?.message || <FormattedMessage
                    defaultMessage='The Wholesail team is working on some planned maintenance. The site will be back as soon as possible.'
                    description='Default message for maintenance error page'
                />}
            </p>
            <p>
                {<FormattedMessage
                    defaultMessage='Any questions? Contact us at {supportEmail}.'
                    description='Generic contact support message on maintenance error page'
                />}
            </p>
        </div>
    );
}
