import { useEffect } from 'react';
import useAnalytics from 'app/analytics/useAnalytics';
import { log } from 'app/analytics/Analytics';
import * as Rendered from 'app/analytics/constants/Rendered';

export default function useTrackRender (opts = {}, config = {}) {
    const { trackEvent } = useAnalytics();
    const { isVisible = true } = config;
    useEffect(() => {
        if (!opts.element || !opts.type) {
            log('red', 'missing required params: element or type');
        }

        if (isVisible) {
            trackEvent(Rendered.EventName, opts);
        }
    // We only want to update if `isVisible` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);
}
