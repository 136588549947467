import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popover } from 'react-tiny-popover';
import cx from 'classnames';

import useAnalytics from 'app/analytics/useAnalytics';
import * as Interaction from 'app/analytics/constants/Interaction';

import EllipsisSvg from 'app/assets/icons/midline-horizontal-ellipsis.svg';

import Tab from './Tab';

import styles from './Tab.module.scss';
import Testing from 'app/utils/Testing';

export default function MoreTab({ activeNavTab, tabs }) {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ parent, setParent ] = useState();
    const { trackInteraction } = useAnalytics();

    const toggleOpen = useCallback(() => {
        trackInteraction({
            element: 'Mobile more menu',
            type: Interaction.Type.Dropdown,
            action: isOpen ? Interaction.Action.Collapse : Interaction.Action.Expand,
        });
        setIsOpen(!isOpen);
    }, [isOpen, trackInteraction]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!parent) {
            if (document.getElementById('bottom-nav')) {
                setParent(document.getElementById('bottom-nav'));
            }
        }
    });

    if (!tabs || tabs.length === 0) {
        return null;
    }

    const active = tabs.some(tab => activeNavTab === tab.tabName);

    return <Popover
        containerClassName={styles.popoverContainer}
        isOpen={isOpen}
        padding={8}
        reposition={true}
        onClickOutside={toggleOpen}
        align='end'
        parentElement={parent}
        positions={['top']}
        content={<div className={styles.popover}>
            {tabs.map(tab => <Tab
                tab={tab}
                key={tab.tabName}
                onClick={toggleOpen}
            />)}
        </div>}
    >
        <div
            className={cx(styles.tab, { [styles.active]: active })}
            onClick={toggleOpen}
            role='menu'
            {...Testing.testAttribute('MoreTab')}
        >
            <div className={styles.icon}>
                <EllipsisSvg />
            </div>
            <FormattedMessage
                defaultMessage='More'
                description='More menu item - mobile bottom nav'
            />
        </div>
    </Popover>;
}
