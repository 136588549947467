import PropTypes from 'prop-types';

export const optionalPositiveElements = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
]);

export const optionalPositiveNodes = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
]);

export const optionalPositiveObjects = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
]);

export const optionalPositiveCells = PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.bool,
]));

export const requirePositiveElements = optionalPositiveElements.isRequired;
export const requirePositiveNodes = optionalPositiveNodes.isRequired;
export const requirePositiveObjects = optionalPositiveObjects.isRequired;
export const requirePositiveCells = optionalPositiveCells.isRequired;

export default {
    optionalPositiveElements,
    optionalPositiveNodes,
    optionalPositiveObjects,
    optionalPositiveCells,
    requirePositiveElements,
    requirePositiveNodes,
    requirePositiveObjects,
    requirePositiveCells,
};

