import PropTypes from 'prop-types';
import * as Interaction from 'app/analytics/constants/Interaction';
import OpenNewTabIcon from 'app/components/common/OpenNewTabIcon/OpenNewTabIcon';
import styles from './ExternalLink.module.scss';
import useAnalytics from 'app/analytics/useAnalytics';

export function ExternalLink({
    analyticsElement,
    analyticsType,
    analyticsAction,
    title,
    link,
}) {
    const { trackInteraction } = useAnalytics();
    const trackAnalytics = () => (
        trackInteraction({
            element: analyticsElement || (typeof title === 'string' ? title : undefined),
            type: analyticsType,
            action: analyticsAction,
        })
    );

    const hasIcon = !link.startsWith('mailto:');

    return <a
        className={styles.link}
        target='_blank'
        href={link}
        onClick={trackAnalytics}
        rel='external noreferrer'
    >
        {title}{hasIcon && <span className={styles.linkOutIcon}><OpenNewTabIcon /></span>}
    </a>;
}

ExternalLink.propTypes = {
    title: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    analyticsElement: PropTypes.string,
    analyticsType: PropTypes.string,
    analyticsAction: PropTypes.string,
};

ExternalLink.defaultProps = {
    analyticsType: Interaction.Type.Link,
    analyticsAction: Interaction.Action.Click,
};

export default ExternalLink;
