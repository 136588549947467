import PropTypes from 'prop-types';
import useActiveUser from 'app/hooks/useActiveUser';
import useActiveCompany from 'app/hooks/useActiveCompany';
import SwitchCompany from 'app/components/nav/SwitchCompany';
import UserMenu from '../UserMenu';
import Testing from 'app/utils/Testing';
import styles from './SideNav.module.scss';
import SyncStatus from 'app/components/nav/SyncStatus';
import useTabs from '../useTabs';
import Tab from '../Tab';

function SideNav ({ activeNavTab }) {
    const { activeCompany } = useActiveCompany();
    const tabs = useTabs({ isDesktop: true });
    const { activeUser } = useActiveUser();

    return (
        <div className={styles.sidebar}>
            <div className={styles.content} {...Testing.unitAttribute('items')}>
                {activeCompany && <div className={styles.companySwitcher}>
                    <SwitchCompany company={activeCompany} />
                </div>}
                {tabs.map(tab => (<Tab key={tab.tabName} activeNavTab={activeNavTab} tab={tab} />))}
            </div>
            <div>
                <div className={styles.content}>
                    <SyncStatus company={activeCompany} />
                </div>
                <div className={styles.bottomBar}>
                    <div className={styles.content}>
                        {!!activeUser && <UserMenu />}
                    </div>
                </div>
            </div>
        </div>
    );

}

SideNav.propTypes = {
    activeNavTab: PropTypes.string,
};

export default SideNav;

