import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { NOOP } from 'app/utils/Utils';
import useActiveUser from 'app/hooks/useActiveUser';
import useNavigation from 'app/hooks/useNavigation';
import ActionLink from 'app/components/common/ActionLink';
import Link from 'app/components/utils/Link';

export function SignOutButton ({ onClick }) {
    const { activeUser, signOut } = useActiveUser();
    const { state } = useNavigation();

    const emailAddress = activeUser?.email;
    const text = emailAddress ? <FormattedMessage
        defaultMessage='Sign out of {emailAddress}'
        description='Sign out link with email address'
        values={{ emailAddress }}
    /> : <FormattedMessage
        defaultMessage='Sign out'
        description='Sign out link without email address'
    />;

    const customLogoutLocation = !!state?.routeParams?.errorSignOutLocation;

    const handleClick = () => {
        if (customLogoutLocation) {
            const params = {};
            state.routeParams.errorSignOutParams.forEach(param => {
                params[param] = state.params[param];
            });
            onClick();
            signOut({
                navigateToSignin: false,
                next: {
                    routeName: state.routeParams.errorSignOutLocation,
                    params: params,
                },
            });
        }
    };

    const getLinkWrapper = (link) => {
        if (customLogoutLocation) {
            return link;
        } else {
            return <Link routeName='Logout' params={{companyId: null}} onClick={onClick}>
                {link}
            </Link>;
        }
    };

    return <div>
        {getLinkWrapper(
            <ActionLink
                title={text}
                type='destructive'
                analyticsElement='Sign Out'
                testAttribute='signOutButton'
                onClick={handleClick}
            />
        )}
    </div>;
}

SignOutButton.propTypes = {
    onClick: PropTypes.func,
};

SignOutButton.defaultProps = {
    onClick: NOOP,
};

export default SignOutButton;
