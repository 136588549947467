import PropTypes from 'prop-types';
import cx from 'classnames';
import Testing from 'app/utils/Testing';
import { NOOP } from 'app/utils/Utils';
import useAnalytics from 'app/analytics/useAnalytics';
import * as Interaction from 'app/analytics/constants/Interaction';
import styles from './BaseButton.module.scss';

export function BaseButton (props) {
    /* eslint-disable no-unused-vars */
    const {
        type,
        disabled,
        testAttribute,
        onClick,
        analyticsAction,
        analyticsElement,
        analyticsType,
        className,
        children,
        Component,
        analyticsPassthru,
        nativeType,
        variant,
        size,
        externalLink,
        ...rest
    } = props;
    /* eslint-enable no-unused-vars */

    const { trackInteraction } = useAnalytics();

    const _handlePress = (e) => {
        trackInteraction({
            element: analyticsElement,
            action: analyticsAction,
            type: analyticsType,
            button_type: type,
        });

        onClick(e);
    };

    const classes = cx(styles.button, styles[`type-${type}`], styles[`variant-${variant}`], styles[`size-${size}`], {
        [styles.disabled]: disabled,
    }, className);

    const linkProps = Component === 'a' && externalLink ? {
        rel: 'external noreferrer',
        target: '_blank',
    } : {};

    return (
        <Component
            {...Testing.testAttributes(props)}
            disabled={disabled}
            onClick={_handlePress}
            className={classes}
            type={nativeType}
            {...rest}
            {...linkProps}
        >
            {children}
        </Component>
    );
}

BaseButton.defaultProps = {
    Component: 'button',
    type: 'primary',
    size: 'md',
    analyticsType: Interaction.Type.Button,
    analyticsAction: Interaction.Action.Click,
    onClick: NOOP,
    nativeType: 'button',
    variant: 'standard',
    externalLink: false,
};

BaseButton.propTypes = {
    className: PropTypes.string,
    Component: PropTypes.oneOf([ 'a', 'button' ]),
    testAttribute: PropTypes.string,
    disabled: PropTypes.bool,
    externalLink: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.oneOf([ 'primary', 'secondary' ]),
    variant: PropTypes.oneOf([ 'standard', 'destructive' ]),
    nativeType: PropTypes.oneOf([ 'button', 'submit', 'reset' ]),
    size: PropTypes.oneOf([ 'sm', 'md', 'lg' ]),
    analyticsElement: PropTypes.string.isRequired,
    analyticsType: PropTypes.string,
    analyticsAction: PropTypes.string,
    analyticsPassthru: PropTypes.func,
    children: PropTypes.node,
};

export default BaseButton;
