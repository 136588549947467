import User from 'app/utils/User';

const fetchInProgressForm = async ({ queryKey }) => {
    const [, applicationId] = queryKey;
    const { body } = await User.authenticatedFetch(
        null,
        `/company/onboarding/${applicationId}`,
        'GET'
    );

    return body;
};

export default fetchInProgressForm;
