
export const DESKTOP_SCREEN_WIDTH = 768;
export const DESKTOP_CONTENT_MAX_WIDTH = 1680;
export const DESKTOP_BODY_WIDTH_SMALL = 576;
export const DESKTOP_BODY_WIDTH_LARGE = 992;
export const DESKTOP_BODY_WIDTH_EXTRA_LARGE = 1190;
export const DESKTOP_BODY_HEIGHT = 600;
export const SIDE_NAV_WIDTH = 240;
export const HOVER_INSET = 4;

const MARKETING_SITE = 'https://www.paywholesail.com';

export const TOS_BUYER_URL = `${MARKETING_SITE}/buyerterms/`;
export const TOS_SELLER_URL = `${MARKETING_SITE}/sellerterms/`;
export const PRIVACY_POLICY_URL = `${MARKETING_SITE}/privacy/`;

const COMPANY_NAME = 'Wholesail';

const CREDIT_MIN_PAYMENT_CENTS = 50;

export default {
    DESKTOP_SCREEN_WIDTH,
    DESKTOP_CONTENT_MAX_WIDTH,
    DESKTOP_BODY_WIDTH_SMALL,
    DESKTOP_BODY_WIDTH_LARGE,
    DESKTOP_BODY_WIDTH_EXTRA_LARGE,
    DESKTOP_BODY_HEIGHT,
    HOVER_INSET,
    MARKETING_SITE,
    COMPANY_NAME,
    CREDIT_MIN_PAYMENT_CENTS,
    TOS_BUYER_URL,
};

