import User from 'app/utils/User';

const fetchActiveCompany = async ({ queryKey }) => {
    const [, companyId] = queryKey;
    const { body } = await User.authenticatedFetch(
        null,
        `/company/banner/${companyId}`,
        'GET',
    );

    return body;
};

export default fetchActiveCompany;
