import Storage from 'app/platform/Storage';

const FEATUREFLAG_KEY = 'ff_items';

export const FEATURE = [
    'FANCY_LANG',
    'CONTROL_HELP',
    'CUSTOMER_TABLE',
].reduce((result, key) => {
    result[key] = key;
    return result;
}, {});

const DEFAULT_VALUE = {
    [FEATURE.FANCY_LANG]: false,
    [FEATURE.CONTROL_HELP]: false,
    [FEATURE.CUSTOMER_TABLE]: false,
};

let _activeValues = {};

function updateActiveValues (stored) {
    _activeValues = {
        ...DEFAULT_VALUE,
        ...stored,
    };
}

function loadStored () {
    const raw = Storage.storage.getItem(FEATUREFLAG_KEY);
    const stored = raw ? JSON.parse(raw) : {};
    updateActiveValues(stored);
    return stored;
}

export async function store (feature, value) {
    const stored = loadStored();
    stored[feature] = value;
    await Storage.storage.setItem(FEATUREFLAG_KEY, JSON.stringify(stored));
    updateActiveValues(stored);
    return stored;
}

export function get (feature) {
    if (!feature) {
        return false;
    }

    return _activeValues[feature];
}

export function getAll () {
    return _activeValues;
}

// initial load
try {
    loadStored();
} catch (err) {
    console.info('Unable to load feature flags -- using defaults');
}
