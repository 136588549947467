import { useEffect } from 'react';
import { processSelection } from 'app/utils/Selection';
import useAnalytics from 'app/analytics/useAnalytics';

const ClipboardMonitor = () => {
    const { trackInteraction, Interaction } = useAnalytics();
    useEffect(() => {
        const copyListener = () => {
            const { result: selectionString, rowCount } = processSelection(document.getSelection());
            if (selectionString) {
                // Handle the IE 11 case
                if (window.clipboardData && window.clipboardData.setData) {
                    window.clipboardData.setData('text', selectionString);
                    event.preventDefault();
                } else if (event.clipboardData && event.clipboardData.setData) {
                    event.clipboardData.setData('text/plain', selectionString);
                    event.preventDefault();
                }
                if (rowCount) {
                    trackInteraction({
                        element: 'Table',
                        type: Interaction.Type.Text,
                        action: Interaction.Action.Copy,
                        rowsSelected: rowCount,
                    });
                }
            }

        };

        document.addEventListener('copy', copyListener);

        return () => document.removeEventListener('copy', copyListener);
    }, []);

    return null;
};

export default ClipboardMonitor;
