import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import User from 'app/utils/User';
import { ActiveCompanyIdContext } from 'app/components/utils/ActiveCompanyProvider';

const fetchAccountingSyncStatus = async ({ queryKey }) => {
    const [, activeCompanyId] = queryKey;
    const res = await User.authenticatedFetch(
        null,
        `/connect/check/${activeCompanyId}`,
        'GET',
    );

    return res?.body;
};

export default function useAccountingSyncStatus () {
    const { activeCompanyId } = useContext(ActiveCompanyIdContext);
    const [refetchInterval, setRefetchInterval] = useState(30000);
    const [ enabled, setEnabled ] = useState(true);
    const result = useQuery(['accountingSyncStatus', activeCompanyId], fetchAccountingSyncStatus, {
        refetchInterval,
        enabled,
        retry: false,
        useErrorBoundary: false,
    });

    // Fetch more frequently if currently syncing
    useEffect(() => {
        if (result.data) {
            setRefetchInterval(result.data.syncing ? 5000 : 30000);
        }
    }, [result.data?.syncing]);

    // Don't keep fetching if not connected
    useEffect(() => {
        if (result.data?.connected !== undefined) {
            setEnabled(!!result.data?.connected);
        }
    }, [result.data?.connected]);

    // Enable when active companyId changes
    useEffect(() => {
        setEnabled(true);
    }, [activeCompanyId]);

    return result;
}
