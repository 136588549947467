import { lazy, Suspense } from 'react';
import useActiveUser from 'app/hooks/useActiveUser';
import * as FeatureFlag from 'app/utils/FeatureFlag';
import useBreakpoints from 'app/hooks/useBreakpoints';

const AdminContainer = lazy(() => import(/* webpackChunkName:"internal" */'./ControlHelpContainer'));

export default function ControlHelpPortal () {
    const { activeUser } = useActiveUser();
    const { isDesktopWeb } = useBreakpoints();

    if (isDesktopWeb && activeUser?.isSuperUser && FeatureFlag.get(FeatureFlag.FEATURE.CONTROL_HELP)) {
        return (
            <Suspense>
                <AdminContainer />
            </Suspense>
        );
    }
}
