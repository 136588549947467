import { useEffect, useRef, useState, useLayoutEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import useResizeObserver from 'use-resize-observer';
import styles from './Step.module.scss';

export const StepPropTypes = {
    wizard: PropTypes.any,
    wizardState: PropTypes.object.isRequired,
    setWizardState: PropTypes.func.isRequired,
    wizardStepId: PropTypes.string.isRequired,
};

export function Step ({
    Component,
    props,
    wizard,
    wizardState,
    setWizardState,
    contentClassName,
    stepId,
    activeStep,
    reverse,
    onLayout,
}) {
    const { ref, height } = useResizeObserver();
    const [animating, setAnimating] = useState(false);
    const active = stepId === activeStep;
    const direction = reverse ? 100 : -100;
    const [wasActive, setWasActive] = useState(active);
    const [x, setX] = useState(active ? 0 : direction);
    const stepRef = useRef();

    const callOnStep = (eventName) => {
        const { current } = stepRef;
        const func = `wizard${eventName}`;

        if (current && current[func]) {
            current[func]();
        }
    };

    // Call lifecycle event at the beginning only
    useEffect(() => {
        if (active) {
            callOnStep('DidFocus');
        }
    }, []);

    // Run animations
    useEffect(() => {
        if (active !== wasActive) {
            setX(active ? 0 - direction : 0);
            setWasActive(active);

            window.requestAnimationFrame(() => {
                setAnimating(true);
                setX(active ? 0 : direction);

                if (!active) {
                    callOnStep('DidUnfocus');
                }

                setTimeout(() => {
                    setAnimating(false);

                    if (active) {
                        callOnStep('DidFocus');
                    }
                }, 300);
            });
        }
    }, [active, wasActive]);

    // Update wizard of height changes
    useLayoutEffect(() => {
        onLayout(stepId, { height });
    }, [onLayout, stepId, height]);

    const isVisible = active || animating || x === 0;

    return (
        <div
            ref={ref}
            className={cx(styles.step, { [styles.animating]: animating })}
            style={{ transform: `translateX(${x}%)` }}
        >
            <div className={contentClassName}>
                {isVisible && <Component
                    {...props}
                    onRef={stepRef}
                    wizard={wizard}
                    wizardState={wizardState}
                    setWizardState={setWizardState}
                    wizardStepId={stepId}
                />}
            </div>
        </div>
    );
}

Step.defaultProps = {
    props: {},
};

Step.propTypes = {
    activeStep: PropTypes.string,
    stepId: PropTypes.string,
    reverse: PropTypes.bool,
    onLayout: PropTypes.func,
    Component: PropTypes.any,
    props: PropTypes.object,
    ...StepPropTypes,
};

export default Step;
