import PropTypes from 'prop-types';
import cx from 'classnames';
import CommonColors from '@wsui/core/Colors';
import Testing from 'app/utils/Testing';
import styles from './ActivityIndicator.module.scss';

const MAPPING = {
    primary: { spinningColor: CommonColors.surfie, staticColor: CommonColors.slate, staticOpacity: 0.32 },
};

export default function ActivityIndicator ({ className, wrapperClassName, color, size, wrapped }) {

    const { spinningColor = color, staticColor = color, staticOpacity = 0.2 } = MAPPING[color] || {};

    const spinner = <svg
        className={cx(styles.spinner, styles[size], className)}
        {...Testing.testAttribute('activity-indicator')}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
    >
        <path fill={staticColor} opacity={staticOpacity} fillRule="evenodd" clipRule="evenodd" d="M20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36ZM20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"/>
        <path fill={spinningColor} d="M31.5262 31.0972C34.2965 28.2204 36 24.3092 36 20H40C40 21.3509 39.8632 22.6906 39.5959 24C39.0267 26.7886 37.8655 29.4394 36.1729 31.7659C34.4804 34.0924 32.3158 36.0132 29.8378 37.4132C28.6746 38.0703 27.4424 38.6127 26.1576 39.0285L26.1564 39.0289L24.9265 35.2272C27.2711 34.4692 29.3791 33.1847 31.1109 31.513C31.2517 31.3771 31.3902 31.2384 31.5262 31.0972Z" />
    </svg>;

    if (wrapped) {
        return <div className={cx(styles.wrapper, wrapperClassName)}>{spinner}</div>;
    }

    return spinner;
}

ActivityIndicator.defaultProps = {
    color: 'primary',
    size: 'small',
    wrapped: true,
};

ActivityIndicator.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOf([ 'small', 'large', 'extra-large' ]),
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,

    /**
     * Wraps the spinner in an div and aligns it center
     */
    wrapped: PropTypes.bool,
};
