import React, { useState } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import ReactModal from 'react-modal';
import { hocDisplayName } from 'app/utils/Utils';
import Testing from 'app/utils/Testing';
import styles from './ModalProvider.module.scss';
import AnalyticsMetadata from 'app/analytics/AnalyticsMetadata';

const ContentElement = React.forwardRef(({ children, onClick, ...rest }, ref) => {
    const handleClick = (e) => {
        e.stopPropagation();
        return onClick(e);
    };

    return (
        <div ref={ref} onClick={handleClick} {...rest}>
            {children}
        </div>
    );
});

export const connectModal = (ModalComponent, modalProviderParams = {}) => {

    const {
        analyticsModalType,
        ...restModalProviderParams
    } = modalProviderParams;

    function ConnectedModal ({
        visible,
        onClose,
        analyticsModalType: overrideAnalyticsModalType,
        ...passthruProps
    }) {

        // `isOpen` - is either open or in the process of closing
        const [isOpen, setIsOpen] = useState(false);

        const onAfterOpen = () => {
            setIsOpen(true);
        };

        const onAfterClose = () => {
            setIsOpen(false);
            clearAllBodyScrollLocks();
        };

        return (
            <AnalyticsMetadata
                modal_name={passthruProps.analyticsName}
                modal_type={overrideAnalyticsModalType || analyticsModalType}
                modal_base={analyticsModalType}
            >
                <ReactModal
                    isOpen={visible}
                    onRequestClose={onClose}
                    onAfterOpen={onAfterOpen}
                    onAfterClose={onAfterClose}
                    overlayClassName={{
                        base: styles.overlay,
                        afterOpen: styles.afterOpen,
                        beforeClose: styles.beforeClose,
                    }}
                    className={{
                        base: styles.content,
                        afterOpen: styles.afterOpen,
                        beforeClose: styles.beforeClose,
                    }}
                    overlayElement={(props, children) => <ContentElement {...props}>{children}</ContentElement>}
                    contentElement={(props, children) => <ContentElement {...props}>{children}</ContentElement>}
                    {...restModalProviderParams}
                >
                    <ModalComponent close={onClose} {...passthruProps} />

                    {/* Note: div just used to provide modal state to testing infrastructure */}
                    {visible && isOpen && <div {...Testing.testAttribute('MODAL_OPEN')} />}
                    <div {...Testing.testAttribute('MODAL')} />
                </ReactModal>
            </AnalyticsMetadata>
        );
    }

    ConnectedModal.displayName = hocDisplayName('connectModal', ModalComponent);

    return ConnectedModal;
};

// Default export needed for generated index.js file
export default null;
