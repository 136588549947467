import { useState, useRef, useEffect, useCallback, useMemo, forwardRef } from 'react';
import cx from 'classnames';
import { disableBodyScroll } from 'body-scroll-lock';

import styles from './Body.module.scss';

export const Body = forwardRef(({
    className,
    footerHasContent,
    handleScroll,
    ...rest
}, ref) => {
    const [scrolledTop, setScrolledTop] = useState(true);
    const [scrolledBottom, setScrolledBottom] = useState(false);
    const [scrollable, setScrollable] = useState(false);
    const bodyRef = useRef();
    const onScroll = useCallback(e => {
        const target = e?.target;

        if (target) {
            setScrolledTop(target.scrollTop === 0);
            setScrolledBottom(target.scrollHeight - target.scrollTop - target.clientHeight < 1);
            setScrollable(target.scrollHeight > target.clientHeight);
            handleScroll && handleScroll(target);
        }
    }, [setScrolledTop, setScrolledBottom, setScrollable, handleScroll]);
    const resizeObserver = useMemo(() => new ResizeObserver(i => onScroll(i[0])), [onScroll]);

    useEffect(() => {
        const node = bodyRef?.current;
        onScroll({ target: node });
        node?.addEventListener('scroll', onScroll);
        node && resizeObserver.observe(node);
        bodyRef?.current && disableBodyScroll(bodyRef?.current, {
            allowTouchMove: el => {
                while (el && el !== document.body) {
                    if (el.classList.contains('ignore-body-scroll-lock')) {
                        return true;
                    }

                    el = el.parentElement;
                }
            },
        });

        return () => {
            node?.removeEventListener('scroll', onScroll);
            node && resizeObserver.unobserve(node);
        };
    }, [onScroll, resizeObserver]);

    const setRef = useCallback(e => {
        if (ref) {
            ref.current = e;
        }
        if (bodyRef) {
            bodyRef.current = e;
        }
    }, [ref]);

    return <div
        className={cx(styles.wrapper, {
            [styles.scrolledTop]: scrolledTop,
            [styles.scrolledBottom]: scrolledBottom,
            [styles.scrollable]: scrollable,
            [styles.footerHasContent]: footerHasContent,
        })}
    >
        <div ref={setRef} className={cx(styles.body, className)} {...rest} />
    </div>;
});

export default Body;
