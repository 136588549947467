import * as BuildInfo from 'app/utils/BuildInfo';
import { prependKeysWith } from 'app/utils/Utils';

export const testAttribute = (name) => ({ 'data-testid': name });

const forwardTestAttribute = (props) => {
    const result = {};
    Object.keys(props).forEach(key => {
        if (/^data-test/.test(key)) {
            result[key] = props[key];
        }
    });
    return result;
};

const testAttributes = (param) => {
    if (typeof param === 'object') {
        if (param.testAttribute) {
            return testAttribute(param.testAttribute);
        } else {
            return forwardTestAttribute(param);
        }
    } else if (typeof param === 'string') {
        return testAttribute(param);
    } else {
        return {};
    }
};

export const testMeta = (params) => prependKeysWith('data-test-', params);

const unitAttribute = (name) => (
    global.__UNIT_TEST__ ? { 'data-testid': name } : {}
);

export const onlyE2eTest = (cb) => BuildInfo.isTeststackServer() && cb();

export const findById = (wrapper, testID) => {
    return wrapper.findWhere(node => {
        return node.prop('data-testid') === testID;
    });
};

export const flushPromises = () => {
    return new Promise(resolve => setImmediate(resolve));
};

export default {
    testAttribute,
    forwardTestAttribute,
    testAttributes,
    unitAttribute,
    testMeta,
};
