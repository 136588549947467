const DEFAULT_LIST_RENDER_QUANTITY = 100;
const DEFAULT_LIST_WINDOW_SIZE = 9;

export const PLACEHOLDER = '\u2014';/* JS strings do not support entity names */

const withPlaceholder = (field, formatter) => field ?
    formatter(field) :
    PLACEHOLDER;

const defaultScreen = (company, { reviewSubmittedOnboarding }) => {
    let route = 'SignIn';
    let params = { next: null };
    if (company) {
        params = { companyId: company.id };
        if (company.isStandaloneNcf) {
            if (reviewSubmittedOnboarding?.canView) {
                route = 'Applications';
            } else {
                route = 'Settings';
            }
        } else if (!company.hasCustomers && !company.hasVendors) {
            route = 'Settings';
        } else if (company.hasCustomers) {
            route = 'Customers';
        } else if (company.hasVendors) {
            route = 'Vendors';
        }
    }
    return { route, params };
};

const doFormatDate = (date, opts) => {
    return new Date(date).toLocaleDateString('en-US', opts);
};

const doFormatTime = (date, opts) => {
    return new Date(date).toLocaleTimeString('en-US', opts);
};

const formatDateLong = (date, opts) => {
    return doFormatDate(date, {
        ...opts,
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
};

const formatDateNumeric = (date, opts) => {
    return doFormatDate(date, {
        ...opts,
        month: 'numeric',
        day: 'numeric',
    });
};

const formatDateShort = (date, opts) => {
    return doFormatDate(date, {
        ...opts,
        month: 'short',
        day: 'numeric',
    });
};

const formatDateLongMonth = (date, opts) => {
    return doFormatDate(date, {
        ...opts,
        month: 'long',
        day: 'numeric',
    });
};

const formatDateLongWithTime = (date, opts) => {
    return formatTime(date, {
        ...opts,
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
};

const formatTime = (date, opts = {}) => {
    return doFormatTime(date, {
        ...opts,
        hour: 'numeric',
        minute: '2-digit',
    });
};

export const strToDate = date => {
    const fullDate = date.indexOf('T') > 1 ? date : `${date}T00:00:00Z`;
    return new Date(fullDate);
};

const formatDate = (formatter, date) => {
    return formatter(strToDate(date), {
        timeZone: 'UTC',
    });
};

const formatDateNumericWithoutTime = d => formatDate(formatDateNumeric, d);
const formatDateShortWithoutTime = d => formatDate(formatDateShort, d);
const formatDateLongWithoutTime = d => formatDate(formatDateLong, d);
const formatDateLongMonthWithoutTime = d => formatDate(formatDateLongMonth, d);

const minutesAgo = (d) => {
    return parseInt((Date.now() - new Date(d)) / (60 * 1000));
};

const potentialLargeInt = (n) => {
    /* Temporary solution because
    .toLocaleString() not working in Android */
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const absDollarsFromCents = (totalCents) => {
    /* Temporary solution because
    .toLocaleString() not working in Android */
    const dollars = parseInt(totalCents / 100);
    const remainingCents = dollars * 100 - totalCents;
    const absRemainingCents = Math.abs(remainingCents);
    const zeroPadding = absRemainingCents < 10 ? '0' : '';
    const absDollars = Math.abs(dollars);
    const displayDollars = potentialLargeInt(absDollars);
    return `${displayDollars}.${zeroPadding}${absRemainingCents}`;
};

const centsFromFormattedDollar = (dollars) => {
    return Number.parseInt(dollars.replace('$', '').replace('.', '').replace(',', ''), 10);
};

const formatDollars = (cents) => {
    const numString = !cents ? '0' : absDollarsFromCents(cents);
    const negationSymbol = cents < 0 ? '-' : '';
    return cents != null && `${negationSymbol}$${numString}`;
};

const formatDollarsShort = (cents) => {
    const dollars = formatDollars(cents);
    const ending = '.00';
    return dollars.endsWith(ending) ?
        dollars.slice(0, dollars.length - ending.length) :
        dollars;
};

const range = n => Array(n).fill().map((_, i) => i);

const negate = x => 0 - x;

const isTextObject = (i) => i && (
    i.text !== undefined || i.value !== undefined || i.valueType !== undefined || i.modify !== undefined
);

const pluralize = (word, count, endsInS = false) => word +
    (
        count && count === 1 ?
            '' :
            (endsInS ? 'es' : 's')
    );
const capitalize = (w, opts = { lowerCaseRemaining: true }) => {
    const remaining = r => opts.lowerCaseRemaining ? r.toLowerCase() : r;
    return w && w.length > 0 ?
        w[0].toUpperCase() + remaining(w.substr(1)) :
        w;
};
const plural = (count, single, multi) => count === 1 ? single : multi;
const withIndefiniteArticle = (w, capitalized = false) => {
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    const prefix = w && w[0] && vowels.includes(w[0].toLowerCase()) ?
        'An' :
        'A';
    const prefixCased = capitalized ? prefix : prefix.toLowerCase();
    return `${prefixCased} ${w}`;
};

const truncate = (input, max) => input.length > max ?
    `${input.substring(0, max)}...` :
    input;

const listify = (words) => {
    const lastIndex = words.length - 1;
    const lastWord = words.length > 0 ?
        words[lastIndex] :
        '';
    const wordsThroughPenultimate = words
        .slice(0, lastIndex).join(', ');
    return words.length > 1 ?
        `${wordsThroughPenultimate} or ${lastWord}` :
        lastWord;
};

const joinStyles = (...styles) => {
    const styleArrays = styles.map(i => asArray(i));
    return styleArrays.reduce((acc, cur) => acc.concat(cur), []);
};

// Takes two objects of the same structure and merges `next` on top of `previous`
// Makes the following assumptions:
// - previous and next are both objects
// - all their values are objects
export const mergeObjectChildren = (previous, next) => {
    const combo = {};
    Object.keys(previous).forEach((section) => {
        combo[section] = {
            ...previous[section],
            ...next[section],
        };
    });
    Object.keys(next).forEach((section) => {
        if (!combo[section]) {
            combo[section] = {
                ...previous[section],
                ...next[section],
            };
        }
    });
    return combo;
};

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export const mergeDeep = (target, source, ...restSources) => {
    if (!source) {
        return target;
    }

    const result = { ...target };
    if (isObject(result) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!result[key]) {
                    result[key] = { ...source[key] };
                } else {
                    result[key] = mergeDeep(result[key], source[key]);
                }
            } else {
                result[key] = source[key];
            }
        }
    }

    return mergeDeep(result, ...restSources);
};

export const prependKeysWith = (prefix, obj) => {
    const result = {};
    Object.keys(obj).forEach((key) => {
        result[prefix + key] = obj[key];
    });
    return result;
};

export const isUUID = (str) => {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(str);
};

export const replaceUUID = (path) => {
    const regex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
    return path.replace(regex, '$UUID');
};

export const NOOP = () => { };

export const hocDisplayName = (hoc, Component) => `${hoc}(${Component.displayName ||
    Component.name})`;

const scheduleHasDiscount = (schedule = {}) => {
    return Object.values(schedule).filter(t => t.discount).length > 0;
};

export const doesCompanyAllowDiscounts = (company) => {
    const { globalTerm, termSchedules = [] } = company.policy || {};
    const hasGlobalDiscount = scheduleHasDiscount(globalTerm);
    const hasTermDiscount = termSchedules.filter(t =>
        scheduleHasDiscount(t.schedule)
    ).length > 0;

    return hasGlobalDiscount || hasTermDiscount;
};

const invert = obj => Object.assign(
    {},
    ...Object.entries(obj).map(([a, b]) => ({ [b]: a }))
);

export const commaAndJoin = (array, options = {}) => {
    const conjunction = options.conjunction || 'and';
    if (array.length <= 1) {
        return array;
    } else if (array.length === 2) {
        return [array[0], ` ${conjunction} `, array[1]];
    }

    const result = [];
    array.forEach((i, idx) => {
        result.push(i);

        if (idx + 1 < array.length) {
            result.push(', ');
        }

        if (idx + 2 === array.length) {
            result.push(`${conjunction} `);
        }
    });
    return result;
};

export const commaAndJoinMore = (array, limit, options = {}) => {
    if (array.length > limit) {
        const more = array.length - limit;
        return commaAndJoin([...array.slice(0, limit), `${more} more`], options);
    } else {
        return commaAndJoin(array, options);
    }
};

export function randomIdOfLength(length) {
    let id = '';
    while (id.length < length) {
        id += Math.random().toString(16).substring(2);
    }
    return id.substr(0, length);
}

export function relationshipTitle(seller, buyer) {
    if (buyer && buyer.displayName && seller && seller.displayName) {
        return `${seller.displayName}  \u21C4  ${buyer.displayName}`;
    }
}

export function minWaitTimeout(startTimeMs, waitMs) {
    return new Promise(resolve => {
        const duration = Date.now() - startTimeMs;
        setTimeout(() => {
            resolve();
        }, Math.max(waitMs - duration, 0));
    });
}

export function dollarsAsCents(str) {
    const dollars = parseFloat(str);
    return Math.round(dollars * 100);
}

export function formatBasisPoints(basisPoints) {
    return Math.abs(basisPoints / 100) + '%';
}

export function asArray(potentialArray) {
    if (!potentialArray) {
        return [];
    }

    return Array.isArray(potentialArray) ? potentialArray : [potentialArray];
}

export function friendlyTimezoneNameLong(ianaName) {
    return new Intl.DateTimeFormat('default',
        { timeZone: ianaName, timeZoneName: 'long' }
    ).formatToParts().find(({ type }) => type === 'timeZoneName').value;
}

export function friendlyTimezoneNameShort(ianaName) {
    return new Intl.DateTimeFormat('default',
        { timeZone: ianaName, timeZoneName: 'short' }
    ).formatToParts().find(({ type }) => type === 'timeZoneName').value;
}

export function convertApiLocalTimeToHour(time) {
    return Number.parseInt(time.slice(0, time.indexOf(':')), 10);
}

export function convertHourToApiLocalTime(time) {
    return `${time < 10 ? '0' : ''}${time}:00`;
}

export function formatAddress(address) {
    return [
        ...address.lines,
        address.city,
        [address.subdivision, address.postCode].filter(e => !!e).join(' '),
    ].filter(e => !!e).join(', ');
}

export const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export default {
    DEFAULT_LIST_RENDER_QUANTITY,
    DEFAULT_LIST_WINDOW_SIZE,
    PLACEHOLDER,
    withPlaceholder,
    defaultScreen,
    formatAddress,
    formatDateNumeric,
    formatDateLong,
    formatDateShort,
    formatDateNumericWithoutTime,
    formatDateShortWithoutTime,
    formatDateLongWithoutTime,
    formatDateLongMonth,
    formatDateLongMonthWithoutTime,
    formatDateLongWithTime,
    strToDate,
    formatTime,
    friendlyTimezoneNameLong,
    friendlyTimezoneNameShort,
    minutesAgo,
    potentialLargeInt,
    formatDollars,
    formatDollarsShort,
    range,
    negate,
    isTextObject,
    pluralize,
    capitalize,
    plural,
    withIndefiniteArticle,
    truncate,
    listify,
    joinStyles,
    mergeObjectChildren,
    prependKeysWith,
    NOOP,
    hocDisplayName,
    invert,
    commaAndJoin,
    relationshipTitle,
    commaAndJoinMore,
    minWaitTimeout,
    dollarsAsCents,
    formatBasisPoints,
    convertApiLocalTimeToHour,
    convertHourToApiLocalTime,
    centsFromFormattedDollar,
    absDollarsFromCents,
};

