import { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useIntl, FormattedMessage, FormattedList, FormattedNumber } from 'react-intl';
import debounce from 'lodash.debounce';

import { centsToDollars } from '@wsui/core/utils/Utils.js';

import ActivityIndicator from 'app/components/common/ActivityIndicator';
import Testing from 'app/utils/Testing';
import useAnalytics from 'app/analytics/useAnalytics';
import * as PageView from 'app/analytics/constants/PageView';
import * as Interaction from 'app/analytics/constants/Interaction';
import GenericModal, { StickyHeader } from 'app/components/modals/common/GenericModal';
import SearchBar from 'app/components/common/SearchBar';
import Router from 'app/platform/Router';
import useActiveUser from 'app/hooks/useActiveUser';
import useNavigation from 'app/hooks/useNavigation';
import fetchAvailableCompanies from 'app/fetch/fetchAvailableCompanies';

import VendorSvg from 'app/assets/icons/vendor.svg';

import styles from './CompanySelection.module.scss';

const NO_FILTERED_COMPANIES = 'NO_FILTERED_COMPANIES';
const REQUIRES_UPGRADED_SESSION = 'REQUIRES_UPGRADED_SESSION';
const DEFAULT_SELLER_LOGO = 'https://static.paywholesail.com/images/email/defaultSellerLogo.png';

function CompanyItem ({ item, index, onClick }) {
    const names = [
        item.hasCustomers && <FormattedMessage
            defaultMessage='Vendor account'
            description='Name to include if a seller company - Company Selection modal item'
        />,
        ...(item.vendorNames || []),
    ].filter(Boolean);
    let descriptionStyle = styles.itemDescription;
    let description = <FormattedList type='conjunction' value={names} />;

    if (!item.hasCustomers && !item.hasVendors) {
        description = <FormattedMessage
            defaultMessage='No vendors or buyers'
            description='description if company has no buyers or sellers - Company Selection modal item'
        />;
        descriptionStyle = styles.itemDescriptionEmpty;
    }

    const icon = item.hasCustomers && (
        <div className={styles.icon}>
            <VendorSvg width={12} height={12} />
        </div>

    );

    const handleClick = (e) => {
        e.preventDefault();
        onClick(item);
    };

    const logoUri = item.logoUri || item.seller?.logoUri || DEFAULT_SELLER_LOGO;

    return (
        <a
            role='button'
            href={Router.generatePath('BaseCompany', { companyId: item.id })}
            {...Testing.testAttribute('updateCompanySelected' + index)}
            onClick={handleClick}
            className={styles.item}
        >
            <div>
                <div className={styles.sellerImage} style={{ backgroundImage: `url(${logoUri})`}} />
            </div>
            <div className={styles.content}>
                <div className={styles.title}>
                    <span className={styles.itemText}>{item.displayName}</span>
                    {icon}
                </div>
                <div className={descriptionStyle}>
                    {description}
                </div>
            </div>
            {!item.hasCustomers && <div className={cx(styles.stats, { [styles.openBalance]: item.openBalanceCents })}>
                <b>
                    <FormattedNumber value={centsToDollars(item.openBalanceCents)} style='currency' currency='USD' />
                </b>
                <div>open</div>
            </div>}
            {!item.hasCustomers && !!item.pastDueBalanceCents && <div className={cx(styles.stats, styles.pastDue)}>
                <b>
                    <FormattedNumber value={centsToDollars(item.pastDueBalanceCents)} style='currency' currency='USD' />
                </b>
                <div>past due</div>
            </div>}
        </a>
    );
}

function CompanySelection ({ onClose, visible, sellerId }) {
    const { navigate } = useNavigation();
    const [ name, setName ] = useState();
    const [ query, setQuery ] = useState();
    const [ hasLoadedOnce, setHasLoadedOnce ] = useState(false);
    const [ showSearchBar, setShowSearchBar ] = useState(false);
    const intl = useIntl();
    const { trackInteraction } = useAnalytics();
    const { activeUser, isRestricted } = useActiveUser();

    const selectCompany = useCallback((company) => {
        navigate('BaseCompany', { companyId: company.id });
        onClose && onClose();
    }, [ navigate, onClose ]);

    const { data: companies = [], isLoading, error } = useQuery(
        [ 'availableCompanies', query, sellerId ],
        fetchAvailableCompanies,
        {
            keepPreviousData: false,
            useErrorBoundary: false,
            enabled: !!visible,
        },
    );

    useEffect(() => {
        if (visible && !showSearchBar && !isLoading) {
            if (companies.length === 1) {
                selectCompany(companies[0]);
            } else if (companies.length === 0 && !error && activeUser) {
                if (isRestricted && activeUser.switchCompanyCount > 0) {
                    onClose({ reason: REQUIRES_UPGRADED_SESSION });
                } else {
                    onClose({ reason: NO_FILTERED_COMPANIES });
                }
            }
        }
    }, [
        visible,
        companies,
        showSearchBar,
        isLoading,
        error,
        sellerId,
        onClose,
        selectCompany,
        activeUser,
        isRestricted,
    ]);

    useEffect(() => {
        if (visible && !isLoading && !hasLoadedOnce) {
            setShowSearchBar(companies.length > 10);
            setHasLoadedOnce(true);
        }
    }, [ setShowSearchBar, companies, isLoading, hasLoadedOnce, setHasLoadedOnce, visible ]);

    const setSearchQuery = useCallback(
        debounce((query) => {
            setQuery(query);
        }, 250, { leading: false, trailing: true}),
        [ setQuery ]
    );

    const showSpinner = !hasLoadedOnce ||
        (hasLoadedOnce && !isLoading && !showSearchBar && companies.length <= 1 && sellerId);

    if (!visible) {
        return null;
    }

    if (showSpinner) {
        return <GenericModal analyticsEntity={PageView.Entity.Company} visible isLoading={true} />;
    }

    const trackAndSelectCompany = (company) => {
        selectCompany(company);
        trackInteraction({
            type: Interaction.Type.ListItem,
            action: Interaction.Action.Click,
            element: 'Company',
            item_id: company.id,
            item_name: company.displayName,
        });
    };

    return (
        <GenericModal
            analyticsModalType='Company selection'
            analyticsName='Company Switcher'
            analyticsEntity={PageView.Entity.Company}
            onClose={onClose}
            visible={visible}
            title={<FormattedMessage
                defaultMessage='Select company'
                description='Modal title for selecting a company'
            />}
        >
            {showSearchBar && <StickyHeader className={styles.searchBar}>
                <SearchBar
                    value={name}
                    onChange={e => setSearchQuery(e.target.value)}
                    onChangeText={setName}
                    analyticsElement='Company Switcher'
                    placeholder={intl.formatMessage({
                        defaultMessage: 'Search for a company',
                        description: 'Placeholder for search input on the company switcher modal',
                    })}
                    autoFocus
                />
            </StickyHeader>}
            <div className={styles.modalBody}>
                {showSearchBar && isLoading && <ActivityIndicator size="large" />}
                {companies.map((item, index) => (
                    <CompanyItem
                        key={item.id}
                        item={item}
                        index={index}
                        onClick={trackAndSelectCompany}
                    />
                ))}
            </div>
        </GenericModal>
    );
}

CompanySelection.propTypes = {
    onClose: PropTypes.func,
    visible: PropTypes.bool,
    sellerId: PropTypes.string,
};

export {
    NO_FILTERED_COMPANIES,
    REQUIRES_UPGRADED_SESSION,
};

export default CompanySelection;
