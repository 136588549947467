import { useEffect } from 'react';
import * as Interaction from 'app/analytics/constants/Interaction';
import useAnalytics from 'app/analytics/useAnalytics';

const PrintMonitor = () => {
    const { trackInteraction } = useAnalytics();
    useEffect(() => {
        const printListener = () => {
            trackInteraction({
                element: 'BeforePrint',
                type: Interaction.Type.Page,
                action: Interaction.Action.Print,
            });
        };

        window.addEventListener('beforeprint', printListener);

        return () => window.removeEventListener('beforeprint', printListener);
    }, []);

    return null;
};

export default PrintMonitor;
