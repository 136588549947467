
const ADMIN_ID = 'babe27b6-585a-4afc-b891-32f65e2b16f1';
const SALES_REP_ID = '1c9d4dd4-8919-4769-abc9-992228871b03';

export const ROLES = {
    admin: ADMIN_ID,
    salesRep: SALES_REP_ID,
};

export const USER_TYPE = {
    PROFILE: 'PROFILE',
    CONTACT: 'CONTACT',
    TEAM_MEMBER: 'TEAM_MEMBER',
};
