import AnalyticsMetadata from 'app/analytics/AnalyticsMetadata';
import ExternalLink from './ExternalLink';

export default function TrackedExternalLink (props) {
    return (
        <AnalyticsMetadata link_href={props.link}>
            <ExternalLink {...props} />
        </AnalyticsMetadata>
    );
}
