import { useEffect } from 'react';
import useAnalytics from 'app/analytics/useAnalytics';
import { log } from 'app/analytics/Analytics';
import * as PageView from 'app/analytics/constants/PageView';

export default function useTrackPageView (opts = {}, config = {}) {
    const { trackEvent } = useAnalytics();
    const { isVisible = true } = config;
    useEffect(() => {
        if (!opts.name || !opts.type) {
            log('red', 'missing required params: name or type');
        }

        if (isVisible) {
            trackEvent(PageView.EventName, opts);
        }
    // We only want to update if `isVisible` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);
}
