import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import useResizeObserver from 'use-resize-observer';

import CheckOnCircleSvg from 'app/assets/icons/circle-with-check-filled.svg';

import styles from './ValidationBadge.module.scss';

export const TYPES = {
    REQUIRED: 'required',
    OPTIONAL: 'optional',
};

export default function ValidationBadge({ className, type, isValid }) {

    const titleRef = useRef();
    const [ titleWidth, setTitleWidth ] = useState();
    const { width } = useResizeObserver({ ref: titleRef, box: 'border-box' });

    useEffect(() => {
        if (!titleWidth && width) {
            setTitleWidth(width);
        }
    }, [width, titleWidth]);

    let title;
    let level;
    if (type === TYPES.REQUIRED) {
        level = 'warning';
        title = <FormattedMessage
            defaultMessage='Required'
            description='Required badge on a form label'
        />;
    } else if (type === TYPES.OPTIONAL) {
        level = 'default';
        title = <FormattedMessage
            defaultMessage='Optional'
            description='optional badge on a form label'
        />;
    }

    return <div
        className={cx(className, styles.badge, styles[level], {
            [styles.success]: isValid,
        })}
        style={titleWidth && {
            maxWidth: isValid ? '16px' : `${titleWidth + 8}px`,
        }}
    >
        <div className={styles.validIconHolder}>
            <CheckOnCircleSvg width={21} height={21} className={styles.validIcon} />
        </div>
        <div className={styles.title} ref={titleRef}>{title}</div>
    </div>;
}

ValidationBadge.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(['required', 'optional']),
    isValid: PropTypes.bool,
};

ValidationBadge.types = TYPES;
