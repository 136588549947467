import Exceptions from 'app/utils/Exceptions';
import User from 'app/utils/User';

const fetchActiveUser = async () => {
    const { body, status, bodyText, traceId } = await User.authenticatedFetch(
        null,
        '/userManagement/loginConfig',
        'GET',
    );

    // check to see if the body has data
    if (status === 200 && body.users === undefined && body.result === undefined) {
        throw new Exceptions.EmptyResponseException('Empty response for ActiveUser call', { bodyText, traceId });
    }

    return body;
};

export default fetchActiveUser;
