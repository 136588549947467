let navigation;

export const navigator = () => navigation;

export const updateNavigation = (n) => {
    navigation = n;
};

export const getScreenState = (nav) => {
    if (nav) {
        return nav;
    } else if (navigation && navigation.state) {
        return navigation.state;
    }

    return {
        routeName: null,
        params: {},
    };
};

export const navigate = (...params) => navigation && navigation.navigate(...params);

export default {
    updateNavigation,
    navigator,
    getScreenState,
    navigate,
};

