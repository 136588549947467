import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CommonColors from '@wsui/core/Colors';
import ActivityIndicator from 'app/components/common/ActivityIndicator';
import BaseButton from 'app/components/common/Button/BaseButton';
import AnalyticsMetadata from 'app/analytics/AnalyticsMetadata';

import styles from './Button.module.scss';

function Button({
    title,
    subtitle: _subtitle,
    loadingText,
    isLoading,
    disabled,
    className,
    width,
    Icon,
    ...rest
}) {
    // Size `sm` can't have a subtitle
    const subtitle = rest.size !== 'sm' ? _subtitle : undefined;
    const double = !!title && !!subtitle;
    const hasIcon = !!Icon;
    const content = isLoading ? (
        <React.Fragment>
            <ActivityIndicator
                className={cx(styles.loader, { [styles.double]: double })}
                color={CommonColors.slate}
            />
            {!!loadingText && <div className={cx(styles.loadingText, styles.text)}>{loadingText}</div>}
        </React.Fragment>
    ) : (
        <React.Fragment>
            {!!title && <div className={styles.text}>
                {title}
                {hasIcon && <span className={styles.icon}><Icon width="16" height="16" /></span>}
            </div>}
            {!!subtitle && <div className={styles.text}>{subtitle}</div>}
        </React.Fragment>
    );

    return (
        <AnalyticsMetadata
            button_title={typeof title === 'string' ? title : undefined}
            button_subtitle={typeof subtitle === 'string' ? subtitle : undefined}
        >
            <BaseButton
                {...rest}
                className={cx(styles.button, styles[`width-${width}`], { [styles.loading]: isLoading }, className)}
                disabled={disabled || isLoading}
            >
                {content}
            </BaseButton>
        </AnalyticsMetadata>
    );
}

Button.defaultProps = {
    width: 'auto',
};

Button.propTypes = {
    title: PropTypes.node,
    Icon: PropTypes.any,
    subtitle: PropTypes.string,
    isLoading: PropTypes.bool,
    loadingText: PropTypes.node,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    width: PropTypes.oneOf([ 'content', 'auto', 'full' ]),
};

export default Button;
