import { FormattedMessage } from 'react-intl';

const TAB_IDS = {
    Autopay: 'Autopay',
    AutopayOut: 'AutopayOut',
    BuyerUpsell: 'BuyerUpsell',
    Customers: 'Customers',
    Invoices: 'Invoices',
    Messages: 'Messages',
    NewCustomerForm: 'NewCustomerForm',
    PaymentsIn: 'PaymentsIn',
    PaymentsOut: 'PaymentsOut',
    Settings: 'Settings',
    Vendors: 'Vendors',
};

const tabs = {
    [TAB_IDS.Invoices]: {
        label: <FormattedMessage
            defaultMessage='Invoices'
            description='Invoices tab - navigation'
        />,
        icon: 'payables',
        route: 'Invoices',
        screen: {
            route: 'Invoices',
            params: {},
        },
        others: [
            'CustomerSingle',
            'InvoiceListInvoiceSingle',
        ],
    },
    [TAB_IDS.Customers]: {
        label: <FormattedMessage
            defaultMessage='Customers'
            description='Customers tab - navigation'
        />,
        icon: 'customers',
        screen: {
            route: 'Customers',
            params: {},
        },
        others: [
            'CustomerInvoiceSingle',
        ],
    },
    [TAB_IDS.Vendors]: {
        label: <FormattedMessage
            defaultMessage='Invoices'
            description='Invoices tab - navigation'
        />,
        icon: 'payables',
        screen: {
            route: 'Vendors',
            params: {},
        },
        others: [
            'VendorSingle',
            'VendorInvoiceSingle',
        ],
    },
    [TAB_IDS.PaymentsIn]: {
        label: <FormattedMessage
            defaultMessage='Payments'
            description='Payments tab - navigation'
        />,
        icon: 'payments',
        screen: {
            route: 'PaymentsIn',
            params: {},
        },
        others: [
            'PaymentInSingle',
            'Payouts',
        ],
    },
    [TAB_IDS.PaymentsOut]: {
        label: <FormattedMessage
            defaultMessage='Payments'
            description='Payments tab - navigation'
        />,
        icon: 'payments',
        screen: {
            route: 'PaymentsOut',
            params: {},
        },
        others: [
            'PaymentOutSingle',
        ],
    },
    [TAB_IDS.Messages]: {
        label: <FormattedMessage
            defaultMessage='Messages'
            description='Messages tab - navigation'
        />,
        icon: 'mail',
        screen: {
            route: 'Messages',
            params: {},
        },
        others: [],
    },
    [TAB_IDS.Autopay]: {
        label: <FormattedMessage
            defaultMessage='Autopay'
            description='Autopay tab - navigation'
        />,
        icon: 'right-arrow-left-arrow',
        screen: {
            route: 'Autopay',
            params: {},
        },
        others: [
            'PaymentPlanListCustomerPaymentPlan',
        ],
    },
    [TAB_IDS.AutopayOut]: {
        label: <FormattedMessage
            defaultMessage='Autopay'
            description='Autopay tab - navigation'
        />,
        icon: 'right-arrow-left-arrow',
        screen: {
            route: 'VendorSingleVendorPaymentPlan',
            params: {},
            includeSellerIdAsTraderId: true,
        },
        others: [],
    },
    [TAB_IDS.Settings]: {
        label: <FormattedMessage
            defaultMessage='Settings'
            description='Settings tab - navigation'
        />,
        icon: 'settings',
        screen: {
            route: 'Settings',
            params: {},
        },
        others: [],
    },
    [TAB_IDS.BuyerUpsell]: {
        label: <FormattedMessage
            defaultMessage='Manage AR'
            description='Manage AR tab - navigation'
        />,
        appendBadge: <FormattedMessage
            defaultMessage='DISCOVER'
            description='Manage AR tab badge - navigation'
        />,
        icon: 'noun-save-money',
        screen: {
            route: 'BuyerUpsell',
            params: {},
        },
        shouldTrackTabRender: true,
    },
    [TAB_IDS.NewCustomerForm]: {
        label: <FormattedMessage
            defaultMessage='New customer forms'
            description='New customer form tab - navigation'
        />,
        icon :'customer-form',
        screen: {
            route: 'Applications',
            params: {},
        },
    },
};

// Add the key to the object for easier filtering
Object.keys(tabs).forEach(tabName => {
    tabs[tabName].tabName = tabName;
});

const topRoutes = {
    Customer: [
        TAB_IDS.Vendors,
        TAB_IDS.PaymentsOut,
        TAB_IDS.AutopayOut,
        TAB_IDS.Settings,
    ],
    Vendor: [
        TAB_IDS.Customers,
        TAB_IDS.Invoices,
        TAB_IDS.PaymentsIn,
        TAB_IDS.Autopay,
        TAB_IDS.Messages,
        TAB_IDS.Settings,
    ],
    Empty: [
        TAB_IDS.Settings,
    ],
    StandaloneNcf: [
        TAB_IDS.NewCustomerForm,
        TAB_IDS.Settings,
    ],
};

const mobileRoutes = {
    Customer: [
        TAB_IDS.Vendors,
        TAB_IDS.PaymentsOut,
        TAB_IDS.AutopayOut,
        TAB_IDS.Settings,
    ],
    Vendor: [
        TAB_IDS.Customers,
        TAB_IDS.Invoices,
        TAB_IDS.PaymentsIn,
        TAB_IDS.Autopay,
        TAB_IDS.Messages,
        TAB_IDS.Settings,
    ],
    Empty: [
        TAB_IDS.Settings,
    ],
    StandaloneNcf: [
        TAB_IDS.NewCustomerForm,
        TAB_IDS.Settings,
    ],
};

const profileName = (company) => {
    if (!company || Object.keys(company).length === 0) {
        return 'SignIn';
    }
    if (company.isStandaloneNcf) {
        return 'StandaloneNcf';
    }
    return company.hasCustomers ?
        'Vendor' :
        company.hasVendors ?
            'Customer' :
            'Empty';
};

export default {
    TAB_IDS,
    tabs,
    topRoutes,
    mobileRoutes,
    profileName,
};

