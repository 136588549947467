import BUILD_INFO from './appInfo.json';

export class ServerEnv {
    constructor(env) {
        this._env = env;
    }

    isProd = () => this._env === 'prod';
    isQA = () => this._env === 'qa';
    isDev = () => this._env === 'dev';
    isLocal = () => this._env === 'local';
    isTest = () => this._env === 'test';
    isTeststack = () => this._env.indexOf('ts') === 0;
    isDemo = () => this._env.indexOf('demo') === 0;
    isDynamic = () => this._env.indexOf('dyn') === 0;
    isNamespace = () => this._env.indexOf('ns-') === 0;

    isValid = () => this.isProd() ||
        this.isQA() ||
        this.isDev() ||
        this.isLocal() ||
        this.isTeststack() ||
        this.isDemo() ||
        this.isTest() ||
        this.isDynamic() ||
        this.isNamespace();

    name = () => {
        if (this.isTeststack()) {
            return 'ts';
        } else if (this.isDemo()) {
            return 'demo';
        } else if (this.isDynamic()) {
            return 'dyn';
        } else if (this.isNamespace()) {
            return 'namespace';
        }

        return this._env;
    };

    fullName = () => this._env;

    id = () => {
        if (this._env.indexOf('-') >= 0) {
            return this._env.split('-')[1];
        }
        return null;
    };

    get config () {
        const config = { ...BUILD_INFO.serverEnvConfig[this.name()] } || {};
        if ((this.isTeststack() || this.isDemo()) && this.id()) {
            config.HOST_PREFIX = config.HOST_PREFIX.replace(
                'TESTSTACKID',
                this.id()
            );
            config.CONTROL_PREFIX = config.CONTROL_PREFIX?.replace(
                'TESTSTACKID',
                this.id()
            );
        } else if (this.isDynamic()) {
            config.HOST_NAME = config.HOST_NAME?.replace(
                'DYNID',
                this.id()
            );
            config.CONTROL_NAME = config.CONTROL_NAME?.replace(
                'DYNID',
                this.id()
            );
        } else if (this.isNamespace()) {
            config.HOST_NAME = config.HOST_NAME?.replace(
                'NAMESPACE',
                this.id()
            );
            config.CONTROL_NAME = config.CONTROL_NAME?.replace(
                'NAMESPACE',
                this.id()
            );
        }

        return config;
    }

    get serverBase () {
        const config = this.config;

        const SERVER_BASE = `${config.HOST_PROTOCOL || 'https'}://${config.HOST_NAME}${config.HOST_PREFIX || ''}`;
        const CONTROL_BASE = `${config.CONTROL_PROTOCOL || 'https'}://${config.CONTROL_NAME}${config.CONTROL_PREFIX || ''}`;

        return {
            SERVER_BASE,
            CONTROL_BASE,
        };
    }
}

export class NodeEnv {
    constructor(env) {
        this._env = env;
    }

    isProd = () => this._env === 'prod';
    isLocal = () => this._env === 'local';
    isQA = () => this._env === 'main';

    name = () => {
        if (this.isProd()) {
            return 'prod';
        } else if (this.isLocal()) {
            return 'local';
        } else {
            return 'branch';
        }
    };

    get serverBase () {
        const name = this.name();
        const config = BUILD_INFO.nodeServerEnvConfig[name] || {};

        if (name === 'branch') {
            config.HOST_NAME = config.HOST_NAME.replace('BRANCH', this._env);
        }

        return `${config.HOST_PROTOCOL || 'https'}://${config.HOST_NAME}${config.HOST_PREFIX || ''}`;
    }
}

export class AppEnv {
    constructor(env) {
        this._env = env;
    }

    isProd = () => this._env === 'prod';
    isQA = () => this._env === 'qa';
    isDev = () => this._env === 'dev';
    isLocal = () => this._env === 'local';
    isTest = () => this._env === 'test';

    name = () => {
        return this._env;
    };

    get config () {
        return BUILD_INFO.appEnvConfig[this.name()] || {};
    }
}

export const APP_VERSION = BUILD_INFO.versionNumber;
