import React from 'react';

let _instance;

export const instance = () => _instance;

export default class ErrorInjector extends React.PureComponent {
    state = {
        error: null,
    };

    componentDidMount () {
        _instance = this;
    }

    componentWillUnmount () {
        if (instance === this) {
            _instance = undefined;
        }
    }

    /**
     * This should only be called by Sentry.
     *
     * Sentry has seen this error already and has logged it, but it has not yet
     * hit an ErrorBoundry to show a message to the user. Mark this error as
     * `rethrown` and set the state so it can be rendered in the render stack
     */
    rethrowError = (error) => {
        const ensureError = error || {};

        ensureError.additionalData = {
            ...(ensureError.additionalData || {}),
            rethrown: true,
        };

        this.setState({ error: ensureError });
    };

    render () {
        // throw error if there is one
        if (this.state.error) {
            throw this.state.error;
        }

        return null;
    }
}
