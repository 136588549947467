import Navigo from 'navigo';
import * as Url from 'app/platform/Url';
import { generateQueryString } from './Url';
import Exceptions from 'app/utils/Exceptions';
import { routes as appRoutes } from 'app/utils/Screens';
import { isUUID, NOOP } from 'app/utils/Utils';
import * as Auth from 'app/utils/Auth';
import { trackPageView } from 'app/analytics/Analytics';

export const PERSIST_PARAMS = [ 'env', 'branch', 'nodeenv' ];

export const validateParams = (Screen, params) => {
    const { validate = {} } = Screen;

    Object.keys(validate).forEach((validateParam) => {
        const validation = validate[validateParam];
        const value = params[validateParam];

        if (validation.type === 'uuid' && !isUUID(value)) {
            throw new Exceptions.NotFoundException(validateParam + ' is not a UUID');
        }
    });
};

const DEFAULT_HOOKS = {
    after: () => {
        // Clear out the scroll position after navigating so that the new
        // page is shown at the top.  This will not fire on browser or
        // programmatic back thereby preserving the scroll position
        window.scrollTo({ top: 0 });
    },
};

/**
 * Router is a class that helps resolves paths to navigation actions and vise versa.
 * This is done by using Navigo which also does all the navigation for the web. Outside
 * of the web, paths and actions are used to power aspects of the mobile app (think magic
 * links opening the app and navigating to the right page) and email templates (think
 * generating links).
 *
 * To support the web usecase and make navigation the same across platforms, this class
 * exposes an API similar to react-navigation. Those functions are at the bottom of the
 * class.
 */
export default class Router {
    constructor({
        origin = '',
        mockedLocation = false,
        onNavigation = NOOP,
        onError = NOOP,
    }) {
        this._onNavigation = onNavigation;
        this._onError = onError;
        this.isSuperAdmin = false;

        if (typeof window !== 'undefined' && mockedLocation) {
            window.__NAVIGO_WINDOW_LOCATION_MOCK__ = '';
        }

        this.router = new Navigo(origin, false);
        this._initializeRoutes();
        this.router.hooks(DEFAULT_HOOKS);

        // Fallback to the "Default" route when no route is found
        this.router.notFound(this.onRoute('Default', appRoutes.Default).uses);
        this.router.resolve();

        this.historyCount = 0;
    }

    _initializeRoutes = (superAdminRoutes) => {
        this.router._routes = [];
        const routes = superAdminRoutes ? { ...appRoutes, ...superAdminRoutes, SuperAdminNotFound: null } : appRoutes;

        Object.keys(routes).forEach(routeName => {
            const route = routes[routeName];
            const path = route?.pattern;
            if (path !== undefined) {
                this.router.on(path, this.onRoute(routeName, route));
            }
        });

        // Adding a route in `appRoutes` that has `pattern: '/'` makes it automatically _defaultHandler in Navigo.
        // This takes it out of our `_routes` and messes up our persistent queries. To get around it, remove the
        // _defaultHandler and add it directly to the _routes
        const DefaultRoute = this.onRoute('Default', appRoutes.Default);
        this.router._defaultHandler = null;
        this.router._add('/', DefaultRoute);
    };

    onRoute = (routeName, Screen) => {
        return {
            as: routeName,
            uses: async (pathParams, queryParams) => {
                const params = {
                    ...Url.parseQueryString(queryParams),
                    ...pathParams,
                };

                const navState = {
                    routeName,
                    params,
                    routeParams: Screen?.props || {},
                };

                this._onNavigation(navState);

                // Trigger validation errors _after_ navState has been updated
                try {
                    validateParams(Screen, params);
                } catch (err) {
                    this._onError(err);
                }

                if (!Screen.unauthenticated && !Auth.isSignedIn()) {
                    if (Screen.unauthenticatedRedirect) {
                        return this.navigate(Screen.unauthenticatedRedirect, { ...params });
                    }
                    return this.navigate('SignIn', { next: this.generatePath(routeName, params) });
                }

                if (!Screen?.props?.suppressPageView) {
                    trackPageView(navState);
                }
            },
        };
    };

    setIsSuperAdmin = async (isSuperAdmin) => {
        if (isSuperAdmin !== undefined && isSuperAdmin !== this.isSuperAdmin) {
            this.isSuperAdmin = isSuperAdmin;

            const prom = isSuperAdmin ?
                import(/* webpackChunkName:"internal" */'app/admin/AdminScreens.js') :
                Promise.resolve({ default: {} });

            const { default: superAdminRoutes } = await prom;
            this._initializeRoutes(superAdminRoutes);
            this.router._lastRouteResolved = undefined;
            this.router.resolve();
        }
    };

    // Gets the query params that should persist across navigation
    persistantQueryParams = () => {
        const queryObj = Url.parseQueryString();
        return PERSIST_PARAMS.reduce((result, key) => {
            if (queryObj[key]) {
                result[key] = queryObj[key];
            }
            return result;
        }, {});
    };

    // convert a navigation action into a url path
    generatePath = (name, data = {}) => {
        const decoded = decodeURIComponent(name);
        if (decoded.startsWith('/')) {
            return decoded;
        }

        const _data = { ...this.persistantQueryParams(), ...data };
        return this.router._routes.reduce((result, route) => {
            if (route.name === name) {
                result = route.route;
                const qs = {};

                for (let key in _data) {
                    if (result.indexOf(`:${key}`) >= 0) {
                        result = result.toString().replace(':' + key, _data[key]);
                    } else if (_data[key] !== null) {
                        qs[key] = _data[key];
                    }
                }

                return result + generateQueryString(qs);
            }
            return result;
        }, '');
    };

    /**
     * Start functions to emulate react-naviation's API
     */
    navigate = (routeName, data) => {
        this.incrementCount();
        return this.router.navigate(this.generatePath(routeName, data));
    };

    setParams = (params) => {
        const lastRoute = this.router.lastRouteResolved();
        return this.navigate(lastRoute.name, params);
    };

    // update but don't adjust scroll position
    updateParams = (params) => {

        //clear the default hooks
        this.router.hooks();

        // update the navigation state
        const lastRoute = this.router.lastRouteResolved();
        const result = this.navigate(lastRoute.name, params);

        // rest the default hooks
        this.router.hooks(DEFAULT_HOOKS);

        return result;
    };

    replace = (routeName, data) => {
        this.router.historyAPIUpdateMethod('replaceState');
        this.router.navigate(this.generatePath(routeName, data));
        this.router.historyAPIUpdateMethod('pushState');
    };

    push = (routeName, data) => {
        this.navigate(routeName, data);
    };

    goBack = (routeName, data) => {
        if (this.hasHistory()) {
            this.decrementCount();
            window.history.go(-1);
        } else {
            // Navigate to fallback but don't increment history counter
            return this.router.navigate(this.generatePath(routeName, data));
        }
    };
    /**
     * End functions to emulate react-naviation's API
     */

    hasHistory = () => this.historyCount > 0;
    incrementCount = () => this.historyCount += 1;
    decrementCount = () => this.historyCount -= 1;
}
