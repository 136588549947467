import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import Testing from 'app/utils/Testing';
import useNavigation from 'app/hooks/useNavigation';
import useBreakpoints from 'app/hooks/useBreakpoints';
import useActiveUser from 'app/hooks/useActiveUser';
import useAnalytics from 'app/analytics/useAnalytics';
import * as Interaction from 'app/analytics/constants/Interaction';

import Avatar from 'app/components/common/Avatar';
import ActionLink from 'app/components/common/ActionLink';
import ContactUsModal from 'app/components/modals/ContactUs';

import RestrictedSessionModal from 'app/components/modals/RestrictedSession';

import LockSvg from 'app/assets/icons/lock.svg';

import styles from './UserMenu.module.scss';

const ONBOARDING = 'onboarding';

const UserMenu = ({ positions, variant, menuOptions }) => {
    const navigation = useNavigation();
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isRestrictedModalOpen, setIsRestrictedModalOpen ] = useState(false);
    const [ isContactUsModalOpen, setIsContactusModalOpen ] = useState(false);
    const { isDesktopWeb } = useBreakpoints();
    const { activeUser, isRestricted } = useActiveUser();
    const { trackInteraction } = useAnalytics();

    const toggleOpen = useCallback(() => {
        trackInteraction({
            element: 'User Menu',
            type: Interaction.Type.Dropdown,
            action: isOpen ? Interaction.Action.Collapse : Interaction.Action.Expand,
        });
        setIsOpen(!isOpen);
    }, [ isOpen, trackInteraction ]);

    const navigateToPreferences = useCallback(() => {
        navigation.navigate('Preferences', { companyId: navigation.state.params.companyId });
    }, [ navigation ]);

    const username = activeUser?.email || activeUser?.phoneNumber;

    const options = useMemo(() => {
        return menuOptions || [
            isRestricted && <ActionLink
                key='restricted'
                title={(<span className={styles.signIn}>
                    <LockSvg width={20} height={20} />
                    <FormattedMessage
                        defaultMessage='Sign in for full access'
                        description='Menu option to sign in when in a restricted session'
                    />
                </span>)}
                onClick={() => {
                    setIsRestrictedModalOpen(true);
                }}
                analyticsElement='User menu sign in'
                inline={false}
            />,
            <ActionLink
                key='preferences'
                title={<FormattedMessage
                    defaultMessage='Preferences'
                    description='Preferences menu option'
                />}
                onClick={() => {
                    navigateToPreferences();
                }}
                analyticsElement='Preferences'
                inline={false}
                {...Testing.testAttribute('userMenu-option-preferences')}
            />,
            <ActionLink
                key='contactUs'
                title={<FormattedMessage
                    defaultMessage='Contact us'
                    description='Contact us menu option'
                />}
                onClick={() => {
                    setIsContactusModalOpen(true);
                }}
                analyticsElement='Contact us'
                inline={false}
            />,
            <ActionLink
                key='signOut'
                title={<FormattedMessage
                    defaultMessage='Sign out'
                    description='Sign out menu option'
                />}
                onClick={() => navigation.navigate('Logout')}
                analyticsElement='User menu sign out'
                inline={false}
            />,
        ].filter(Boolean);
    }, [ menuOptions, isRestricted, navigation, navigateToPreferences ]);

    const showUsernameInMenu = !isDesktopWeb && variant !== ONBOARDING;

    const getContentOffsetFunction = () => {
        if (isDesktopWeb || variant === ONBOARDING) {
            return undefined;
        } else {
            return ({ parentRect, popoverRect}) => ({ top: 37, left: parentRect.width - popoverRect.width });
        }
    };

    const getIcon = () => {
        if (variant !== ONBOARDING) {
            return isRestricted ? 'lock' : undefined;
        }
        return undefined;
    };

    return (<>
        <Popover
            containerClassName={styles.popoverContainer}
            isOpen={isOpen}
            padding={2}
            reposition={true}
            onClickOutside={toggleOpen}
            positions={positions || [ isDesktopWeb ? 'top' : 'bottom' ]}
            contentLocation={getContentOffsetFunction()}
            content={<div className={styles.popover}>
                {showUsernameInMenu && <small className={styles.username}>{username}</small>}
                {options.map((option, index) => (<div key={index} onClick={toggleOpen} className={styles.menuItem}>
                    {option}
                </div>))}
            </div>}
        >
            <button
                onClick={toggleOpen}
                className={cx(styles.userMenu, styles[`variant-${variant}`])}
                {...Testing.testAttribute('userMenu')}
            >
                <Avatar
                    title={username}
                    showTitle={variant === ONBOARDING || isDesktopWeb}
                    icon={getIcon()}
                    useDisplayNameIcon={!!activeUser?.phoneNumber}
                />
            </button>
        </Popover>
        {isRestricted && <RestrictedSessionModal
            visible={isRestrictedModalOpen}
            currentUser={{
                username,
                passwordSet: activeUser?.passwordSet,
            }}
            onClose={() => setIsRestrictedModalOpen(false)}
        />}
        <ContactUsModal visible={isContactUsModalOpen} onClose={() => setIsContactusModalOpen(false)} />
    </>);
};

UserMenu.propTypes = {
    positions: PropTypes.arrayOf(PropTypes.string),
    variant: PropTypes.oneOf([ ONBOARDING ]),
    menuOptions: PropTypes.any,
};

export default UserMenu;
