import PropTypes from 'prop-types';
import Circle from 'app/components/common/Circle';
import CommonColors from '@wsui/core/Colors';

export default function CompanyLogo ({
    uri,
    className,
    diameter = 48,
    borderWidth = 1,
    borderColor = CommonColors.gainsboro,
}) {

    return <Circle
        source={{ uri }}
        className={className}
        diameter={diameter}
        borderWidth={borderWidth}
        backgroundColor={CommonColors.snow}
        borderColor={borderColor} />;

}

CompanyLogo.propTypes = {
    uri: PropTypes.string,
    className: PropTypes.string,
    diameter: PropTypes.number,
    borderWidth: PropTypes.number,
    borderColor: PropTypes.string,
};
