import PropTypes from 'prop-types';
import cx from 'classnames';
import Testing from 'app/utils/Testing';
import * as Interaction from 'app/analytics/constants/Interaction';
import useAnalytics from 'app/analytics/useAnalytics';
import styles from './ActionLink.module.scss';

export function ActionLink ({
    Component,
    type,
    title,
    inline,
    className,
    disabled,
    onClick,
    analyticsElement,
    href,
    ...rest
}) {
    const { trackInteraction } = useAnalytics();
    const onAction = (e) => {
        trackInteraction({
            element: analyticsElement || (typeof title === 'string' ? title : undefined),
            type: Interaction.Type.Link,
            action: Interaction.Action.Click,
            disabled,
        });

        if (disabled) {
            return;
        }

        onClick && onClick(e);
    };

    const classes = cx(
        styles.link,
        styles[type],
        {
            [styles.inline]: inline,
            [styles.disabled]: disabled,
        },
        className
    );

    const LinkComponent = Component || (href ? 'a' : 'button');

    return (
        <LinkComponent
            {...rest}
            href={href || undefined}
            onClick={onAction}
            {...Testing.testAttributes(rest)}
            className={classes}
            type={!href ? 'button' : undefined}
            disabled={disabled}
        >
            { title }
        </LinkComponent>
    );

}

ActionLink.defaultProps = {
    type: 'creative',
    disabled: false,
    href: '',

    // react-native made these all blocks. A few set this to false but we should switch those over to inline soon
    inline: true,
};

ActionLink.propTypes = {
    Component: PropTypes.oneOf([ 'a', 'button' ]),
    className: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.oneOf([ 'creative', 'destructive' ]),
    disabled: PropTypes.bool,
    analyticsElement: PropTypes.string,
    inline: PropTypes.bool,
};

export default ActionLink;
