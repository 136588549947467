import React, { useContext, useMemo } from 'react';

export const AnalyticsMetadataContext = React.createContext({});

export default function AnalyticsMetadata ({ children, ...params }) {
    const parentContext = useContext(AnalyticsMetadataContext);
    const value = useMemo(() => ({
        ...parentContext,
        ...params,
    }), [ parentContext, params ]);

    return (
        <AnalyticsMetadataContext.Provider value={value}>
            {children}
        </AnalyticsMetadataContext.Provider>
    );
}
