import { FormattedMessage } from 'react-intl';

export const getPaymentBadges = (paymentObj) => {
    const isReversed = paymentObj.isReversed();
    const isVoided = paymentObj.isVoided();
    const isVendorCharge = paymentObj.isSellerLinked() || paymentObj.isSellerInitiated();

    const badges = [];
    if (isReversed) {
        badges.push({
            key: 'reversed',
            level: 'warning',
            label: <FormattedMessage
                defaultMessage='Reversed'
                description='reversed payment - payment status badge'
            />,
        });
    } else if (isVoided) {
        badges.push({
            key: 'voided',
            level: 'warning',
            label: <FormattedMessage
                defaultMessage='Void'
                description='voided payment - payment status badge'
            />,
        });
    }

    if (paymentObj.isAutopay()) {
        badges.push({
            key: 'autopay',
            level: 'default',
            label: <FormattedMessage
                defaultMessage='Autopay'
                description='Autopay - payment status badge'
            />,
        });
    }

    if (isVendorCharge) {
        badges.push({
            key: 'vendor-charge',
            level: 'default',
            label: <FormattedMessage
                defaultMessage='Vendor charge'
                description='vendor charge - payment status badge'
            />,
        });
    }

    return badges;
};
