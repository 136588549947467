import { useEffect } from 'react';
import Sentry, { hasSentry } from 'app/utils/Sentry';
import useActiveUser from 'app/hooks/useActiveUser';

export default function ActiveSentryUser () {
    const { activeUser, activeUserId } = useActiveUser();

    useEffect(() => {
        if (hasSentry()) {
            Sentry.configureScope((scope) => {
                scope.setUser({
                    email: activeUser?.email,
                    id: activeUserId,
                });
            });
        }
    }, [activeUserId, activeUser?.email]);

    return null;
}
