import * as BuildInfo from 'app/utils/BuildInfo';
import * as Analytics from 'app/platform/Analytics';
import Company from 'app/utils/Company';
import { getScreenState } from 'app/platform/Navigation';
import { routes } from 'app/utils/Screens';
import * as Auth from 'app/utils/Auth';
import { prependKeysWith } from 'app/utils/Utils';
import { getTrackingId } from 'app/analytics/TrackingId';
import * as PageView from './constants/PageView';

const PATH = '/info/qed';

export const log = async (color, ...rest) => {
    if (BuildInfo.appEnv().isDev()) {
        // eslint-disable-next-line no-console
        console.log('%cAnalytics', `font-weight: bold; color: ${color};`, ...rest);
    }
};

// `data` is only supported for objects (no longer supports arrays of events)
export const makeRequest = async (data, endpoint = 'TRACK') => {
    if (!data) {
        log('red', 'Malformed data', data);
        return false;
    }

    // determine if we have some minimal information before sending event
    const sendEvent = !!data.properties.token && !!data.properties.distinct_id;
    const color = sendEvent ? 'green' : 'orange';
    log(color, `(${endpoint})`, data);

    if (!sendEvent) {
        return false;
    }

    const body = {
        endpoint,
        data,
    };
    const { SERVER_BASE } = BuildInfo.getConfig();
    const path = SERVER_BASE + PATH;

    // Make sure to catch to not cause UI errors
    const fallbackSend = () => fetch(path, { body: JSON.stringify(body), method: 'POST' })
        .catch(err => console.warn('Unable to send event data', err));

    // Fire and forget - paradigm inspired by vercel/nextjs
    // eslint-disable-next-line max-len
    // https://github.com/vercel/next.js/blob/f16ee05f599de27e777ac2b736c3bf820a19bd7b/packages/next/client/performance-relayer.ts#L53-L70
    try {
        // Navigator has to be bound to ensure it does not error in some browsers
        // https://xgwang.me/posts/you-may-not-know-beacon/#it-may-throw-error%2C-be-sure-to-catch
        const send = window.navigator?.sendBeacon && navigator.sendBeacon.bind(navigator);

        // If send is undefined or returns false, try fallbackSend
        (send && send(path, JSON.stringify(body))) || fallbackSend();
    } catch (error) {
        fallbackSend();
    }
};

export const trackEvent = async (eventName, properties) => {
    const companyId = await Company.getCompanyId();
    const deviceProperties = await Analytics.deviceProperties();
    const release = BuildInfo.release();
    const trackingId = await getTrackingId();
    const { MIXPANEL_TOKEN } = BuildInfo.getConfig();

    let auth_level;
    try {
        auth_level = await Auth.sessionLevel();
    } catch (err) {
        // do nothing. ActiveUserProvider probably not fully bootstrapped yet
    }

    const addProperties = {
        release,
        distinct_id: trackingId,
        token: MIXPANEL_TOKEN,
        auth_level,
        app_version: BuildInfo.APP_VERSION,
    };

    if (companyId) {
        addProperties.company_id = companyId;
    }

    return makeRequest({
        event: eventName,
        properties: {
            ...properties,
            ...deviceProperties,
            ...addProperties,
        },
    });
};

export const trackEventWithScreen = (eventName, properties) => {
    const state = getScreenState();

    trackEvent(eventName, {
        ...paramsFromNavState(state),
        ...properties,
    });
};

const paramsFromRouterForScreen = (routeName) => {
    return routes[routeName] && routes[routeName].analytics || {};
};

const paramsFromNavState = (state) => {
    const screen = state.routeName || 'Unknown';
    const screenParams = paramsFromRouterForScreen(screen);

    const result = {
        ...prependKeysWith('screen_param_', state.params),
        ...prependKeysWith('screen_', screenParams),
        screen,
    };

    // Make sure to always clear this one from the analytics
    delete result.screen_param__wsrt;

    return result;
};

export const trackPageView = (state) => {
    const navParams = paramsFromNavState(state);
    const screenParams = paramsFromRouterForScreen(navParams.screen);

    return trackEvent(PageView.EventName, {
        ...navParams,
        ...screenParams,
        name: navParams.screen,
    });
};
