export const EventName = 'Interaction';

export const SuppressElement = 'SuppressElement';

export const Type = {
    Text: 'Text',
    Input: 'Input',
    Link: 'Link',
    Button: 'Button',
    Tab: 'Tab',
    ListItem: 'List Item',
    NavItem: 'Nav Item',
    Checkbox: 'Checkbox',
    ThirdParty: '3rd Party',
    Content: 'Content',
    Dropdown: 'Dropdown',
    RadioGroup: 'RadioGroup',
    Page: 'Page',
    Form: 'Form',
};

export const Action = {
    Hover: 'Hover',
    Click: 'Click',
    Focus: 'Focus',
    Blur: 'Blur',
    Select: 'Select',
    Deselect: 'Deselect',
    Type: 'Type',
    Scroll: 'Scroll',
    Other: 'Other',
    Expand: 'Expand',
    Collapse: 'Collapse',
    Copy: 'Copy',
    Print: 'Print',
    KeyEnter: 'KeyEnter',
    Submit: 'Submit',
};
