import { sellerFeeConfigFromTraderRelationship } from './FeeConfig';

// To be used with GET /company/config/{companyId} endpoint

export const FEATURES = {
    allowAutoStatement: 'allowAutoStatement',
    allowMultiplePayoutAccounts: 'allowMultiplePayoutAccounts',
    autoSendInvoices: 'autoSendInvoices',
    bulkSendInvoices: 'bulkSendInvoices',
    buyerNavLinks: 'buyerNavLinks',
    creditHold: 'creditHold',
    inviteBanner: 'inviteBanner',
    sellerNavLinks: 'sellerNavLinks',
    showBuyerReferral: 'showBuyerReferral',
    showCustomerId: 'showCustomerId',
    showInvoiceMemo: 'showInvoiceMemo',
    showPoNumber: 'showPoNumber',
    showProjectInApp: 'showProjectInApp',
    inviteFlow: 'inviteFlow',
    salesDemoBanner: 'salesDemoBanner',
    allowSmsUsers: 'allowSmsUsers',
    allowSmsUsersWithoutNames: 'allowSmsUsersWithoutNames',
    hideReconciliationPayments: 'hideReconciliationPayments',
    newCustomerOnboarding: 'newCustomerOnboarding',
    paymentMethodFilters: 'paymentMethodFilters',
    projectsUiLabel: 'projectsUiLabel',
    internationalizationOnboarding: 'internationalizationOnboarding',
    promoteSms: 'promoteSms',
    disableSellerLinkedCreditCVC: 'disableSellerLinkedCreditCVC',
    disableSellerLinkedCreditZip: 'disableSellerLinkedCreditZip',
    invoiceTemplate: 'invoiceTemplate',
    splitCmPayments: 'splitCmPayments',
    disableSellerInitiatedPartialPay: 'disableSellerInitiatedPartialPay',
    cobrandingLogo: 'cobrandingLogo',
    showInvoiceAttachments: 'showInvoiceAttachments',
};

export const CAPABILITIES = {
    customerAutoSendInvoice: 'customerAutoSendInvoice',
    globalAutoSendInvoice: 'globalAutoSendInvoice',
    customerAutoStatement: 'customerAutoStatement',
    globalAutoStatement: 'globalAutoStatement',
    payouts: 'payouts',
    relationshipCCFee: 'relationshipCCFee',
    repAssignments: 'repAssignments',
    reviewSubmittedOnboarding: 'reviewSubmittedOnboarding',
    settingsAccountIdentifiers: 'settingsAccountIdentifiers',
    settingsIntegrations: 'settingsIntegrations',
    settingsOnboardingConfig: 'settingsOnboardingConfig',
    settingsPaymentAccounts: 'settingsPaymentAccounts',
    settingsTeamMembers: 'settingsTeamMembers',
    settingsContacts: 'settingsContacts',
    smsPromotion: 'smsPromotion',
    sellerInitiatedPaymentCc: 'sellerInitiatedPaymentCc',
    onboardingSensitiveFields: 'onboardingSensitiveFields',
    settingsGlobalInvoiceAttachment: 'settingsGlobalInvoiceAttachment',
    settingsCustomerInvoiceAttachment: 'settingsCustomerInvoiceAttachment',

};

export default class ActiveCompany {
    constructor(data) {
        this.data = data;
    }

    get id() {
        return this.data.result;
    }

    get capabilities() {
        return this.data.capabilities[this.id];
    }

    get isSeller() {
        return this.hasBuyers;
    }

    get isBuyer() {
        return this.hasSellers;
    }

    get hasSellers() {
        return this.capability('hasSellers');
    }

    get hasBuyers() {
        return this.capability('hasBuyers');
    }

    get isStandaloneNcf() {
        return this.capability('isStandaloneNcf');
    }

    get customerId() {
        return this.data.companies[this.id].customerId;
    }

    get displayName() {
        return this.data.companies[this.id].displayName;
    }

    get autoSendConfiguration() {
        const { policy } = this.data.companies[this.id];
        return {
            autoSendInvoicesEnabled: policy.autoSendInvoicesEnabled,
            autoSendCreditsEnabled: policy.autoSendCreditsEnabled,
            autoSendInvoicesScheduleFrequency: policy.autoSendInvoicesScheduleFrequency,
            autoSendInvoicesSchedule: policy.autoSendInvoicesSchedule,
        };
    }

    get relationshipName() {
        const seller = this.seller;
        if (seller) {
            return `${seller.displayName}  \u21C4  ${this.displayName}`;
        }

        return this.data.companies[this.id].displayName;
    }

    get sellerTraderRelationship() {
        if (this.isSeller) {
            return null;
        }

        const sellers = this.data.traderRelationships[this.id]?.sellers || [];
        return sellers[0];
    }

    get seller() {
        const tr = this.sellerTraderRelationship;
        if (tr) {
            return this.data.companies[tr.sellerId];
        }

        return null;
    }

    get latLng() {
        const latlng = this.data.businessLatLongs && this.data.businessLatLongs[this.id];
        return latlng && [latlng.latitude, latlng.longitude];
    }

    get timeZone() {
        return this.data.companies[this.id].timezone;
    }

    // Used for compatability with our old company fetching
    get rawCompany() {
        return this.data.companies[this.id];
    }

    /**
     * @deprecated - legacy function
     */
    get hasCustomers() {
        return this.isSeller;
    }

    /**
     * @deprecated - legacy function
     */
    get hasVendors() {
        return this.isBuyer;
    }

    /**
     * @deprecated - legacy function
     */
    get policy() {
        return this.rawCompany.policy;
    }

    get slug() {
        return this.rawCompany.slug;
    }

    get newBuyerExportEnabled() {
        return this.rawCompany.policy.newBuyerExportEnabled;
    }

    canUseCC = () => {
        if (this.seller) {
            return this.data.traderConfig[this.id].sellerAcceptsCC;
        }

        return this.rawCompany.policy.stripeConfiguration?.linkEnabled;
    };

    /**
     * Note:
     * - sellerCCLinkEnabled - seller can link buyer’s CC on behalf of buyer
     * - stripeConfiguration.linkEnabled  - seller sees the “Stripe Connect” settings (or at a higher level
     * “The seller accepts CC payments in general”)
     *
     * For sellers, the permission to add a CC can be different from being able to use a CC. This function
     * was added to help understand in the future the difference between the two, but components don't use
     * it yet. We should work on making the app more robust by depending on these :)
    */
    canAddCC = () => {
        if (this.seller) {
            return this.canUseCC();
        }

        return this.canUseCC() && this.rawCompany.policy.sellerCCLinkEnabled;
    };

    feature = (featureFlag) => {
        return (this.data.featureFlags || {})[featureFlag];
    };

    capability = (capability) => {
        return this.data.capabilities?.[this.id]?.[capability];
    };

    canView = (capability) => {
        return this.capabilities[capability]?.indexOf('VIEW') >= 0;
    };

    canUpdate = (capability) => {
        return this.capabilities[capability]?.indexOf('UPDATE') >= 0;
    };

    canDelete = (capability) => {
        return this.capabilities[capability]?.indexOf('DELETE') >= 0;
    };

    canCreate = (capability) => {
        return this.capabilities[capability]?.indexOf('CREATE') >= 0;
    };

    sellerFeeConfig = () => {
        if (this.isBuyer) {
            return sellerFeeConfigFromTraderRelationship(this.data, this.seller.id, this.id);
        }
    };
}
