import cx from 'classnames';
import PropTypes from 'prop-types';
import CommonColors from '@wsui/core/Colors';
import styles from './Circle.module.scss';

function Circle ({
    borderWidth,
    borderColor,
    borderStyle,
    backgroundColor,
    diameter,
    source,
    ...passthroughProps
}) {

    const additionalStyles = {
        borderWidth: borderWidth,
        borderColor: borderColor,
        borderStyle: borderStyle,
        backgroundColor: backgroundColor,
        width: diameter,
        height: diameter,
        borderRadius: diameter / 2,
    };

    const props = {
        ...passthroughProps,
        style: additionalStyles,
    };

    if (source) {
        const imageStyles = {
            ...additionalStyles,
            backgroundImage: `url(${source.uri})`,
        };
        props.style = imageStyles;

        return <div {...props} className={cx(styles.backgroundImage, props.className)} />;
    } else {
        return <div {...props} />;
    }

}

Circle.defaultProps = {
    borderWidth: 0,
    borderColor: CommonColors.slate,
    backgroundColor: CommonColors.slate,
    borderStyle: 'solid',
};

Circle.propTypes = {
    // string - web imported; number - native imported; object - when using uri from api
    source: PropTypes.oneOfType([ PropTypes.string, PropTypes.object, PropTypes.number ]),
    backgroundColor: PropTypes.string.isRequired,
    borderColor: PropTypes.string,
    borderStyle: PropTypes.string,
    borderWidth: PropTypes.number,
    diameter: PropTypes.number.isRequired,
    className: PropTypes.string,
};

export default Circle;
