import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import CheckOnCircle from 'app/assets/icons/check-on-circle.svg';

import styles from './Toast.module.scss';

export default function Toast({
    visible,
    children,
    onExited,
    offset,
    level,
}) {
    return <CSSTransition
        in={visible}
        timeout={310}
        onExited={onExited}
        style={{
            marginBottom: `${offset}px`,
        }}
        classNames={{
            enter: styles.enter,
            enterActive: styles.enterActive,
            exit: styles.exit,
            exitActive: styles.exitActive,
            exitDone: styles.exitDone,
        }}
    >
        <div
            className={cx(styles.toast, {
                [styles.warning]: level === 'warning',
            })}
        >
            {level === 'success' && <CheckOnCircle width={22} height={22} className={styles.icon} />}
            {children}
        </div>
    </CSSTransition>;
}

Toast.propTypes = {
    visible: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onExited: PropTypes.func,
    offset: PropTypes.number,
    level: PropTypes.oneOf([ '', 'warning', 'success' ]),
};

Toast.defaulProps = {
    offset: 0,
};
