import { useCallback, useMemo, useContext } from 'react';
import { trackEventWithScreen, log } from './Analytics';
import { AnalyticsMetadataContext } from './AnalyticsMetadata';
import * as Interaction from './constants/Interaction';

export default function useAnalytics () {
    const analyticsContext = useContext(AnalyticsMetadataContext);
    const trackEvent = useCallback((eventName, params) => {
        trackEventWithScreen(eventName, {
            ...analyticsContext,
            ...params,
        });
    }, [ analyticsContext ]);

    const trackInteraction = useCallback((params) => {
        if (!params.type || !params.element || !params.action) {
            log('red', 'missing required params: type, element, or action', params);
            return;
        }

        if (params.element === Interaction.SuppressElement) {
            return;
        }

        trackEvent(Interaction.EventName, params);
    }, [ trackEvent ]);

    return useMemo(() => ({
        trackEvent,
        trackInteraction,
        Interaction,
    }), [ trackInteraction, trackEvent ]);
}
