import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import MailSvg from 'app/assets/icons/mail.svg';
import Circle from 'app/components/common/Circle';
import TrackedExternalLink from 'app/components/common/ExternalLink/TrackedExternalLink';
import CommonColors from '@wsui/core/Colors';
import * as PageView from 'app/analytics/constants/PageView';
import styles from './ContactUs.module.scss';
import GenericModal from 'app/components/modals/common/GenericModal/GenericModal';

function ContactUsModal ({ ...rest }) {

    return (
        <GenericModal
            title={<FormattedMessage
                defaultMessage='Contact Us'
                description='Title for contact us modal'
            />}
            {...rest}
            analyticsEntity={PageView.Entity.Help}
            analyticsName='Contact Us'
            analyticsModalType='Contact us'
        >
            <div className={styles.text}>
                <FormattedMessage
                    defaultMessage='Any feedback or problems? Let us know anytime by emailing us.'
                    description='First line of helper text in contact us modal'
                />
            </div>
            <div className={styles.text}>
                <FormattedMessage
                    defaultMessage='We also have answers to common questions in <helpLink>our help center</helpLink>.'
                    description='Second line of helper text in contact us modal'
                    values={{
                        helpLink: (chunks) => <TrackedExternalLink
                            title={chunks}
                            link='https://wholesail.zendesk.com/hc/en-us'
                            analyticsElement='Help Center'
                        />,
                    }}
                />
            </div>
            <div className={styles.contactItem}>
                <Circle
                    diameter={32}
                    backgroundColor={CommonColors.surfie}
                    className={styles.circle}
                >
                    <MailSvg className={styles.icon} />
                </Circle>
                <TrackedExternalLink
                    title='support@paywholesail.com'
                    link='mailto:support@paywholesail.com'
                    analyticsElement='Contact Us Email'
                />
            </div>
        </GenericModal>
    );
}

ContactUsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ContactUsModal;
