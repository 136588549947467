import { Suspense, useContext } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactModal from 'react-modal';
import * as BuildInfo from 'app/utils/BuildInfo';
import ErrorBoundary from 'app/components/utils/ErrorBoundary';
import ServerIndicator from 'app/components/utils/ServerIndicator';
import CartContextProvider from 'app/components/utils/CartContextProvider';
import { trackEvent } from 'app/analytics/Analytics';
import { routes } from 'app/utils/Screens';
import { screenClasses } from 'app/utils/ScreenClasses';
import SuperAdminRoutes from 'app/admin/AdminScreens';
import { screenClasses as superAdminScreenClasses } from 'app/admin/AdminScreenClasses';
import ErrorInjector from 'app/components/utils/ErrorInjector';
import ConnectedRestrictedBoundary from 'app/components/utils/RestrictedBoundary/RestrictedBoundary';
import ToastProvider from 'app/components/utils/ToastProvider';
import useActiveUser from 'app/hooks/useActiveUser';
import useTrackRender from 'app/analytics/useTrackRender';
import * as Rendered from 'app/analytics/constants/Rendered';
import ActiveCompanyProvider from 'app/components/utils/ActiveCompanyProvider';
import { decodeAuthToken, getAuthToken, getSessionLevel, setAuthToken } from 'app/utils/Auth';
import ActiveUserProvider from 'app/components/utils/ActiveUserProvider';
import ActiveSentryUser from 'app/components/utils/ActiveSentryUser';
import BreakpointProvider from 'app/components/utils/BreakpointProvider';
import IntlProvider from 'app/components/utils/IntlProvider';
import SSLCheck from 'app/components/utils/SSLCheck';
import AppScreenFrame from 'app/components/common/Screen/ScreenFrame';
import Testing from 'app/utils/Testing';
import NavigationProvider, { NavigationContext } from 'app/components/utils/NavigationProvider';
import ClipboardMonitor from 'app/components/utils/ClipboardMonitor';
import ActivityIndicator from 'app/components/common/ActivityIndicator';
import ControlHelpPortal from 'app/admin/ControlHelp/ControlHelpPortal';
import PrintMonitor from 'app/components/utils/PrintMonitor';
import { ERROR_MAINTENANCE_MODE } from 'app/utils/Fetch';
import { SESSION_LEVEL } from 'app/constants/Auth';

const { hash, pathname, search } = window.location;

ReactModal.setAppElement('#react-app');
ReactModal.defaultProps.closeTimeoutMS = 300; // Set here so we can set this to 0 in unit test

let abortInitializingApp = false;

// TODO: remove this at some point
// redirects the old hash style paths to the new paths
if (hash.indexOf('#/') === 0 && pathname === '/') {
    abortInitializingApp = true;
    trackEvent('Usage', { name: 'urlHash' });
    window.location.replace(hash.substr(1) + search);
}

// store _wsrt query param and replace the url if one exists before starting up the app
const searchParams = new URLSearchParams(window.location.search);
if (searchParams.has('_wsrt')) {
    const authToken = getAuthToken();
    const decodedAuthToken = decodeAuthToken(authToken);
    const sessionLevel = getSessionLevel(decodedAuthToken);

    if (sessionLevel !== SESSION_LEVEL.UNRESTRICTED) {
        setAuthToken(searchParams.get('_wsrt'));
    }

    searchParams.delete('_wsrt');
    const qs = searchParams.toString();
    abortInitializingApp = true;
    window.location.replace(window.location.pathname + (qs ? '?' + qs : ''));
}

function colorScheme () {
    if (window.matchMedia('(prefers-color-scheme)').matches) {
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }
    return 'not-supported';
}

function App () {
    const NavContext = useContext(NavigationContext);

    useTrackRender({
        type: Rendered.Type.App,
        element: 'App',
        languages: window.navigator.languages,
        standalone: window.navigator.standalone,
        colorScheme: colorScheme(),
    });

    const { isRestricted } = useActiveUser();

    if (NavContext.error) {
        throw NavContext.error;
    }

    const { routeName, routeParams } = NavContext.navigation?.state || {};
    const Screen = routes[routeName] || SuperAdminRoutes[routeName];
    const ScreenClass = screenClasses[routeName] || superAdminScreenClasses[routeName];
    const ScreenFrame = Screen.ScreenFrame || AppScreenFrame;

    if (!Screen || !ScreenClass) {
        return null;
    }

    const unauthenticated = !!Screen.unauthenticated;

    return (
        <>
            <div {...Testing.testAttribute(`Screen-${routeName}`)} />
            <Suspense fallback={<ActivityIndicator />}>
                <ScreenFrame unauthenticated={unauthenticated} activeNavTab={Screen.activeNavTab}>
                    {unauthenticated ? <ScreenClass {...routeParams} /> : (
                        <ConnectedRestrictedBoundary isRestricted={isRestricted}>
                            <ScreenClass {...routeParams} />
                        </ConnectedRestrictedBoundary>)
                    }
                </ScreenFrame>
            </Suspense>
        </>
    );

}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60,
            cacheTime: 1000 * 60 * 5,
            retry: (failureCount, error) => {
                if (error?.additionalData?.status >= 500) {
                    const data = error.additionalData;
                    const isMaintenanceMode = data.status === 503 && data.json?.error === ERROR_MAINTENANCE_MODE;
                    return !isMaintenanceMode && failureCount < 3;
                }

                return false;
            },
            useErrorBoundary: true,
        },
    },
});

export async function runRouter () {
    // We already triggered a redirect -- don't continue initializing the app
    if (abortInitializingApp) {
        return;
    }

    const container = document.getElementById('react-app');
    const root = createRoot(container);

    root.render(
        <QueryClientProvider client={queryClient}>
            <ActiveUserProvider>
                <IntlProvider>
                    <NavigationProvider>
                        <ActiveCompanyProvider>
                            <BreakpointProvider>
                                <CartContextProvider>
                                    <ToastProvider>
                                        <ErrorBoundary showBackButton={true}>
                                            <SSLCheck />
                                            <ActiveSentryUser />
                                            <ServerIndicator hostColor={BuildInfo.getConfig().HOST_COLOR} />
                                            <ClipboardMonitor />
                                            <PrintMonitor />
                                            <App/>
                                            <ControlHelpPortal />
                                            <ErrorInjector />
                                        </ErrorBoundary>
                                    </ToastProvider>
                                </CartContextProvider>
                            </BreakpointProvider>
                        </ActiveCompanyProvider>
                    </NavigationProvider>
                </IntlProvider>
            </ActiveUserProvider>
        </QueryClientProvider>
    );

}

