// left async for transistion purposes. Should be removed and migrate uses away
const getCompanyId = async () => {
    return window._wsCompanyIdHack;
};

const compareCompanies = (a, b) => {
    if (a.hasCustomers && !b.hasCustomers) {
        return -1;
    } else if (!a.hasCustomers && b.hasCustomers) {
        return 1;
    }
    return a.displayName.localeCompare(b.displayName);
};

export default {
    getCompanyId,
    compareCompanies,
};
