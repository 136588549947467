import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Testing from 'app/utils/Testing';
import { NOOP } from 'app/utils/Utils';
import useAnalytics from 'app/analytics/useAnalytics';
import * as Interaction from 'app/analytics/constants/Interaction';
import styles from './TextInput.module.scss';

export const TextInput = React.forwardRef((props, ref) => {
    const {
        enableValueTracking,
        analyticsElement,
        onChangeValue,
        onChangeText,
        onChange,
        invalid,
        disabled,
        className,
        inputClassName,
        value,
        name,
        type,
        IconSvg,
        onBlur,
        onFocus,

        /* eslint-disable no-unused-vars */
        testAttribute,
        /* eslint-enable no-unused-vars */

        ...rest
    } = props;

    const { trackInteraction } = useAnalytics();

    const handleChange = (e) => {
        const newValue = e.target.value || '';
        onChangeValue(newValue);
        onChangeText(newValue);
        onChange(e);
    };

    const track = (action) => {
        const params = {
            element: analyticsElement,
            type: Interaction.Type.Input,
            action,
            input_length: value?.length || 0,
        };

        if (enableValueTracking) {
            if (type === 'password') {
                console.warn('`enableValueTracking` prop cannot be `true` on passwords');
            } else {
                params['input_value'] = value;
            }
        }

        trackInteraction(params);
    };

    const inputProps = {
        value,
        name,
        type,
        disabled,
        onChange: handleChange,
        onBlur: (e) => {
            track(Interaction.Action.Blur);
            onBlur(e);
        },
        onFocus: (e) => {
            track(Interaction.Action.Focus);
            onFocus(e);
        },
        ...rest,
    };

    return (
        <div className={cx(styles.wrapper, className)}>
            <input
                ref={ref}
                className={cx(styles.input, {
                    [styles.hasIcon]: IconSvg,
                    [styles.invalid]: invalid,
                }, inputClassName)}
                {...Testing.testAttributes(props)}
                {...inputProps}
            />
            {IconSvg && (
                <span className={styles.iconWrapper}>
                    <IconSvg className={styles.icon} />
                </span>
            )}
        </div>
    );
});

TextInput.displayName = 'TextInput';

TextInput.defaultProps = {
    enableValueTracking: false,
    type: 'text',
    onBlur: NOOP,
    onFocus: NOOP,
    onChange: NOOP,

    // onChangeText and onChangeValue are legacy functions
    onChangeText: NOOP,
    onChangeValue: NOOP,
};

TextInput.propTypes = {
    analyticsElement: PropTypes.string.isRequired,
    /**
     * Track the input value in analytics when `true`.  By default only the
     * value's length is tracked to avoid logging sensitive information.
     */
    enableValueTracking: PropTypes.bool,
    value: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    testAttribute: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChangeValue: PropTypes.func,
    onChangeText: PropTypes.func,
    onChange: PropTypes.func,
    IconSvg: PropTypes.elementType,
};

export default TextInput;
