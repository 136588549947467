import User from 'app/utils/User';

const fetchInProgressForm = async ({ queryKey }) => {
    const [ , applicationId, fetchBankReport = false ] = queryKey;
    const { body } = await User.authenticatedFetch(
        null,
        `/company/onboarding/${applicationId}?fetchBankReport=${fetchBankReport}`,
        'GET'
    );

    return body;
};

export default fetchInProgressForm;
