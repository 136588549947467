import Header from './Header';
import Body from './Body';
import Container from './Container';
import Footer from './Footer';
import FixedFooter, { FixedFooterContext } from './FixedFooter';

export default {
    Container,
    Header,
    Body,
    Footer,
    FixedFooter,
    FixedFooterContext,
};
