// Date and Time formats that can be used in all intl functions.
// These function will be available in user's local time. To use it in seller
// time, append "-seller". Or for UTC, append "-utc".

// Note: when the API returns "YYYY-MM-DD" format, always use "-utc"

// Note: when using a custom date/time format in FormattedMessage, it will use the
// user's local time unless you specify otherwise in that request. Ideally we
// should reduce the different number of ways we format dates/times and include
// them all here instead.
export const FORMATS = {
    date: {
        short: {
            month: 'numeric',
            day: 'numeric',
            year: '2-digit',
        },

        shortLongYear: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
        },

        medium: {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        },

        mediumNoYear: {
            month: 'short',
            day: 'numeric',
        },

        mediumNoDay: {
            month: 'short',
            year: 'numeric',
        },

        long: {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        },

        full: {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        },
        medDateAndShortTime: {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        },
        medDateAndTime: {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short',
        },
    },

    time: {
        short: {
            hour: 'numeric',
            minute: 'numeric',
        },

        medium: {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        },

        long: {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        },

        full: {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        },
    },
};

export function addTzToFormats (formats, name, timeZone) {
    const result = {};
    Object.keys(formats).forEach(format => {
        result[name ? `${format}-${name}` : format] = { ...formats[format], timeZone };
    });
    return result;
}

export function getFormats (sellerTz) {
    return {
        date: {
            ...addTzToFormats(FORMATS.date),
            ...addTzToFormats(FORMATS.date, 'seller', sellerTz),
            ...addTzToFormats(FORMATS.date, 'utc', 'UTC'),
        },
        time: {
            ...addTzToFormats(FORMATS.time),
            ...addTzToFormats(FORMATS.time, 'seller', sellerTz),
            ...addTzToFormats(FORMATS.time, 'utc', 'UTC'),
        },
    };
}

export function getFormatsUnit () {
    return {
        date: {
            ...addTzToFormats(FORMATS.date, undefined, 'UTC'),
            ...addTzToFormats(FORMATS.date, 'seller', 'UTC'),
            ...addTzToFormats(FORMATS.date, 'utc', 'UTC'),
        },
        time: {
            ...addTzToFormats(FORMATS.time, undefined, 'UTC'),
            ...addTzToFormats(FORMATS.time, 'seller', 'UTC'),
            ...addTzToFormats(FORMATS.time, 'utc', 'UTC'),
        },
    };
}
