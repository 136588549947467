export const EventName = 'PageView';

export const Type = {
    List: 'List',
    Single: 'Single',
    Modal: 'Modal',
    Wizard: 'Flow/Wizard',
    Section: 'Section',
    Setting: 'Setting',
    Detail: 'Detail',
};

export const Entity = {
    Invoice: 'Invoice',
    Payment: 'Payment',
    Company: 'Company',
    User: 'User',
    Cart: 'Cart',
    PaymentAccount: 'Payment Account',
    PaymentPlan: 'Payment Plan',
    BankAccount: 'Bank Account',
    Help: 'Help',
    Confirmation: 'Confirmation',
    ContentSubmission: 'Content Submission',
    Error: 'Error',
    AccountingIntegration: 'Accounting Integration',
    ProspectSeller: 'Prospect Seller',
    Message: 'Message',
    CustomerForm: 'CustomerForm',
};
