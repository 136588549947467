const getParentType = (node, role) => {
    if (node === document || !node) {
        return undefined;
    }

    if (node.getAttribute && node.getAttribute('role') === role) {
        return node;
    }

    return getParentType(node.parentNode, role);
};

const NEWLINE = '\n';
const processNode = (node, startContainer, endContainer) => {
    let value = '';
    let startFound = !startContainer;
    let endFound = !endContainer;

    if (!node) {
        return { value: '' };
    }

    if (node === startContainer) {
        startFound = true;
    }

    if (node === endContainer) {
        endFound = true;
    }

    if (node.childNodes && node.childNodes.length) {
        /**
         * `childNodes` is not an Array but a NodeList. NodeList does have a `forEach`
         * but it is not supported on IE. Followed the recommendation on
         * https://developer.mozilla.org/en-US/docs/Web/API/NodeList
         */
        Array.prototype.forEach.call(node.childNodes, (i) => {
            if (!endFound && (i.contains(startContainer) || startFound)) {
                const nextStart = startFound ? undefined : startContainer;
                const nextEnd = endFound ? undefined : endContainer;
                const processed = processNode(i, nextStart, nextEnd);

                if (processed.startFound) {
                    value += processed.value;
                }

                startFound = startFound || processed.startFound;
                endFound = endFound || processed.endFound;
            }
        });
    } else {
        value += node.innerText || node.nodeValue || '';
    }

    if (node.getAttribute && node.getAttribute('role') === 'row') {
        value += NEWLINE;
    } else if (node.getAttribute && node.getAttribute('role') === 'gridcell') {
        value += '\t';
    } else if (value &&
        node.tagName === 'DIV' &&
        getParentType(node, 'row') === undefined
    ) {
        // add new line if one doesn't already exist
        if (value.substr(value.length - NEWLINE.length) !== NEWLINE) {
            value += NEWLINE;
        }
    }

    return {
        value,
        startFound,
        endFound,
    };
};

const inAdminContainer = (node) => {
    if (node === document || !node) {
        return undefined;
    }

    return node.classList?.contains('admin-portal') || inAdminContainer(node.parentNode);
};

export const processSelection = (selection) => {
    if (selection.rangeCount === 0) {
        return false;
    }

    if (inAdminContainer(selection.anchorNode) || inAdminContainer(selection.focusNode)) {
        // Abort our copy logic if in the admin container
        return false;
    }

    let result = '';
    let rowCount = 0;
    for (let i = 0; i < selection.rangeCount; i++) {
        const range = selection.getRangeAt(i);
        const clone = range.cloneContents();

        rowCount += clone.querySelectorAll('[role="row"]').length;
        const {
            commonAncestorContainer,
            startContainer,
            endContainer,
        } = range;

        if (range.toString()) {
            let node;
            if (startContainer === endContainer) {
                node = processNode(startContainer);
            } else {
                node = processNode(commonAncestorContainer, startContainer, endContainer);
            }

            if (node.value.trim()) {
                result += node.value;
            }
        }
    }

    return { result, rowCount };
};
