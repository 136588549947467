import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useQueryClient } from 'react-query';

import { envAppend } from 'app/platform/Storage';
import * as Navigation from 'app/platform/Navigation';
import { setTrackingId } from 'app/analytics/TrackingId';
import { SESSION_LEVEL } from 'app/constants/Auth';
import { getAuthToken, decodeAuthToken, setAuthToken, USER_SESSION_TOKEN, getSessionLevel } from 'app/utils/Auth';
import useOnMount from 'app/hooks/useOnMount';

const SIGN_IN = 'SignIn';

export const ActiveUserIdContext = React.createContext({});

function ActiveUserProvider ({ children }) {
    const [ userToken, _setUserToken ] = useState(getAuthToken());
    const [ clearQueries, setClearQueries ] = useState(true);
    const [ userDecodedJwt, _setUserDecodedJwt ] = useState(decodeAuthToken(userToken));
    const queryClient = useQueryClient();

    const setUserToken = useCallback((token) => {
        const decoded = setAuthToken(token);

        if (decoded) {
            _setUserToken(token);
            _setUserDecodedJwt(decoded);

            return true;
        }

        return false;
    }, []);

    const signOut = useCallback(({ navigateToSignin = true, next } = {}) => {
        Cookies.remove(envAppend(USER_SESSION_TOKEN));

        _setUserToken(undefined);
        _setUserDecodedJwt(undefined);

        if (navigateToSignin) {
            setClearQueries(true);
            Navigation.navigate(SIGN_IN);
        } else if (next) {
            setClearQueries(true);
            Navigation.navigate(next.routeName, next.params);
        } else {
            setClearQueries(false);
        }
    }, []);

    const value = useMemo(() => {
        const sessionLevel = getSessionLevel(userDecodedJwt);

        const value = {
            sessionToken: userToken,
            isSignedIn: !!userToken,
            setUserToken,
            signOut,
            activeUserId: userDecodedJwt?.uid ?? userDecodedJwt?.u,
            sessionLevel,
            isRestricted: sessionLevel === SESSION_LEVEL.RESTRICTED,
        };

        // TODO: get rid of this!! OMG this is so bad
        window._wsAuthHack = value;

        return value;
    }, [ userToken, userDecodedJwt, setUserToken, signOut ]);

    // On app load, set the tracking id with the active user (if one exists)
    useOnMount(() => {
        setTrackingId(value.activeUserId);
    });

    const [ isInitialized, setInitialized ] = useState(false);
    useEffect(() => {

        if (isInitialized) {
            if (userToken) {
                queryClient.resetQueries({
                    predicate: (query) => {
                        return query.queryKey[0] !== 'companySlug';
                    },
                });
            } else if (clearQueries) {
                queryClient.clear();
            }
        }
        setInitialized(true);
    // Only rerun if userToken changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userToken, clearQueries ]);

    return (
        <ActiveUserIdContext.Provider value={value}>
            {children}
        </ActiveUserIdContext.Provider>
    );
}

ActiveUserProvider.propTypes = {
    children: PropTypes.node,
};

export default ActiveUserProvider;
