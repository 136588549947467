import { FormattedMessage } from 'react-intl';

export const OMIT = 'OMITTED';
export const REQUIRED = 'REQUIRED';
export const OPTIONAL = 'OPTIONAL';
export const ANY_REQUIRED = 'ANY_REQUIRED';
export const DELEGATE = 'DELEGATE';

export const MAXIMUM_ATTACHMENTS = 50;
// 100 MB maximum size
export const MAXIMUM_FILE_SIZE = 1024 * 1000 * 100;

export const SECTION_IDS = {
    primaryUser: 'primaryUser',
    company: 'company',
    tradeRefs: 'tradeRefs',
    bankReport: 'bankReport',
    paymentAccounts: 'paymentAccounts',
    terms: 'terms',
    submitted: 'submitted',
};

export const AVAILABLE_SECTIONS = [
    SECTION_IDS.primaryUser,
    SECTION_IDS.company,
    SECTION_IDS.tradeRefs,
    SECTION_IDS.bankReport,
    SECTION_IDS.paymentAccounts,
    SECTION_IDS.terms,
    SECTION_IDS.submitted,
];

export const APPLICATION_STATUSES = {
    CREATED: 'CREATED',
    EXPORT_ENQUEUED: 'EXPORT_ENQUEUED',
    IN_PROGRESS: 'IN_PROGRESS',
    PENDING_LINK: 'PENDING_LINK',
    LINKED: 'LINKED',
    SUBMITTED: 'SUBMITTED',
    STANDALONE_ACCEPTED: 'STANDALONE_ACCEPTED',
};

export const DISPLAY_STATUSES = {
    INCOMPLETE: 'INCOMPLETE',
    NEEDS_REVIEW: 'NEEDS_REVIEW',
    PENDING_LINK: 'PENDING_LINK',
    PENDING_EXPORT: 'PENDING_EXPORT',
    ACCEPTED_AND_LINKED: 'ACCEPTED_AND_LINKED',
    ACCEPTED: 'ACCEPTED',
};

export const API_TO_DISPLAY_STATUS_MAP = {
    [APPLICATION_STATUSES.CREATED]: DISPLAY_STATUSES.INCOMPLETE,
    [APPLICATION_STATUSES.IN_PROGRESS]: DISPLAY_STATUSES.INCOMPLETE,
    [APPLICATION_STATUSES.SUBMITTED]: DISPLAY_STATUSES.NEEDS_REVIEW,
    [APPLICATION_STATUSES.EXPORT_ENQUEUED]: DISPLAY_STATUSES.PENDING_EXPORT,
    [APPLICATION_STATUSES.PENDING_LINK]: DISPLAY_STATUSES.PENDING_LINK,
    [APPLICATION_STATUSES.LINKED]: DISPLAY_STATUSES.ACCEPTED_AND_LINKED,
    [APPLICATION_STATUSES.STANDALONE_ACCEPTED]: DISPLAY_STATUSES.ACCEPTED,
};

export const DISPLAY_TO_API_STATUS_MAP = {
    [DISPLAY_STATUSES.INCOMPLETE]: [ APPLICATION_STATUSES.IN_PROGRESS ],
    [DISPLAY_STATUSES.NEEDS_REVIEW]: [ APPLICATION_STATUSES.SUBMITTED ],
    [DISPLAY_STATUSES.PENDING_LINK]: [ APPLICATION_STATUSES.PENDING_LINK ],
    [DISPLAY_STATUSES.PENDING_EXPORT]: [ APPLICATION_STATUSES.EXPORT_ENQUEUED ],
    [DISPLAY_STATUSES.ACCEPTED_AND_LINKED]: [ APPLICATION_STATUSES.LINKED ],
    [DISPLAY_STATUSES.ACCEPTED]: [ APPLICATION_STATUSES.STANDALONE_ACCEPTED ],
};

// Intentionally not translated
export const COMPANY_TYPE_OPTIONS = [
    {
        label: <FormattedMessage
            defaultMessage='Sole proprietorship'
            description='Company type option - sole proprietorship'
        />,
        value: 'Sole proprietorship',
    },
    {
        label: <FormattedMessage
            defaultMessage='Single-member LLC'
            description='Company type option - Single-member LLC'
        />,
        value: 'Single-member LLC',
    },
    {
        label: <FormattedMessage
            defaultMessage='Multi-member LLC'
            description='Company type option - Multi-member LLC'
        />,
        value: 'Multi-member LLC',
    },
    {
        label: <FormattedMessage
            defaultMessage='Private partnership'
            description='Company type option - Private partnership'
        />,
        value: 'Private partnership',
    },
    {
        label: <FormattedMessage
            defaultMessage='Private corporation'
            description='Company type option - Private corporation'
        />,
        value: 'Private corporation',
    },
    {
        label: <FormattedMessage
            defaultMessage='Other/I’m not sure'
            description='Company type option - Other/I’m not sure'
        />,
        value: 'Other/I’m not sure',
    },
];
