import { FormattedMessage } from 'react-intl';

import ScreenFrame from 'app/components/common/Screen/ScreenFrame';
import useActiveUser from 'app/hooks/useActiveUser';
import ActivityIndicator from 'app/components/common/ActivityIndicator';

import styles from './NotFoundScreen.module.scss';
import ErrorSvg from 'app/assets/illustrations/error.svg';

export default function NotFound () {
    const { activeUser, isLoading } = useActiveUser();
    const isSuperUser = activeUser && activeUser?.isSuperUser;

    if (isLoading || isSuperUser) {
        return <ActivityIndicator />;
    }

    return (
        <ScreenFrame unauthenticated={!activeUser}>
            <div className={styles.body}>
                <ErrorSvg />
                <h2>
                    <FormattedMessage
                        defaultMessage='Page not found'
                        description='Not found exception - error title'
                    />
                </h2>
                <p>
                    <FormattedMessage
                        defaultMessage='The page you were looking for could not be found.'
                        description='Not found exception - error message'
                    />
                </p>
            </div>
        </ScreenFrame>
    );
}
