import { useQuery } from 'react-query';

import User from 'app/utils/User';
import useActiveUser from 'app/hooks/useActiveUser';

const fetchAccountsQuery = async ({ queryKey }) => {
    const [ , activeUserId, buyerId, action = 'PAY' ] = queryKey;

    let path = '/accounts/query';
    if (buyerId) {
        path += `?buyerId=${buyerId}`;
    }

    const response = await User.authenticatedFetch(
        null,
        path,
        'POST',
        {
            hasPermission: { action, actor: activeUserId },
        },
    );

    return response?.body;
};

export default function useAccountsQuery ({ buyerId, action }) {
    const { activeUserId } = useActiveUser();
    const queryKey = [
        'userAccountsQuery',
        activeUserId,
        buyerId,
        action,
    ];

    const query = useQuery(
        queryKey,
        fetchAccountsQuery,
        { refetchOnMount : true, useErrorBoundary: true, enabled: !!activeUserId }
    );

    return query;
}
