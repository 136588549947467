import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as BuildInfo from 'app/utils/BuildInfo';
import * as ErrorInjector from 'app/components/utils/ErrorInjector/ErrorInjector';

let sentryInstalled = false;

const config = BuildInfo.getConfig();

if (config.SENTRY_DSN) {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        release: BuildInfo.release(),
        environment: config.analyticsEnv,
        integrations: [ new BrowserTracing() ],
        tracesSampleRate: 0.1,
        beforeSend: (event, hint) => {
            const error = hint.originalException || event.exception?.values?.[0] || {};
            const additionalData = error?.additionalData || {};

            /**
             * `rethrown` is set in ErrorInjector and if true, means we have already
             * seen this error and it was rethrown through ErrorInjector to hit an
             * ErrorBoundry
             */
            if (additionalData.rethrown) {
                return null;
            }
            event.extra = {
                ...event.extra,
                ...additionalData,
            };
            if (!event.extra.hitBoundry && !event.extra.suppress) {
                /**
                 * The error has not hit an ErrorBoundry yet and still needs to be
                 * shown. Rethrow the error through ErrorInjector
                 */
                const errorInjector = ErrorInjector.instance();
                errorInjector && errorInjector.rethrowError(error);
            }

            /**
             * `suppressSentry` is set in Exceptions and means we should throw this
             * event away
             *
             * `!REPORT_SENTRY` - This environment does not want to report to sentry
             */
            if (error.suppressSentry || !config.REPORT_SENTRY) {
                return null;
            }

            return event;
        },
        beforeBreadcrumb: (breadcrumb) => {
            const {
                type,
                category,
                data,
            } = breadcrumb;

            // Omit Sentry default breadcrumbs that are related to our API
            if (type === 'http' && category === 'fetch' &&
                data.url.indexOf(config.SERVER_BASE) === 0) {
                return null;
            }

            return breadcrumb;
        },
        ignoreErrors: [
            // seems safe to ignore https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
            'ResizeObserver loop limit exceeded',
            // https://github.com/juggle/resize-observer/issues/103
            'ResizeObserver loop completed with undelivered notifications',
            // https://wholesail.atlassian.net/browse/ENG-1562
            /Object Not Found Matching Id/,
            // Hide chunk errors,
            'ChunkLoadError',
            // eslint-disable-next-line max-len
            // https://stackoverflow.com/questions/74197049/exception-caught-in-sentry-error-hidden-in-ywebkit-masked-url
            'webkit-masked-url',
            // eslint-disable-next-line max-len
            // https://techcommunity.microsoft.com/t5/discussions/error-can-t-find-variable-logmutedmessage-on-edge-mobile/m-p/3912307
            // related to Edge on mobile
            'logMutedMessage',
        ],
    });
    Sentry.configureScope(scope => {
        scope.setTag('platform', 'web');
    });
    sentryInstalled = true;
}

global.SENTRY_UTILITY = Sentry;
export default Sentry;
export const hasSentry = () => sentryInstalled;

export const silentCaptureException = (error, metadata) => {
    hasSentry() && Sentry.withScope(scope => {
        // Tag the error with `suppress` so we don't forward the event along to ErrorInjector
        scope.setExtra('suppress', true);
        metadata && scope.setExtra('metadata', metadata);

        // Send the error to Sentry
        Sentry.captureException(error);
    });
};
