import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useResizeObserver from 'use-resize-observer';
import {
    DESKTOP_SCREEN_WIDTH,
    DESKTOP_BODY_WIDTH_SMALL,
    DESKTOP_BODY_WIDTH_LARGE,
    DESKTOP_BODY_WIDTH_EXTRA_LARGE,
} from 'app/utils/Options';

export const BreakpointContext = React.createContext({});

const hasHoverQuery = window.matchMedia(' (hover: hover) and (pointer: fine)');
const calcBreakpoints = function () {
    const result =  {
        isLTSmall: window.matchMedia(`(max-width:${DESKTOP_BODY_WIDTH_SMALL - 1}px)`).matches,
        isSmall: window.matchMedia(`(min-width:${DESKTOP_BODY_WIDTH_SMALL}px)`).matches,
        isLTMedium: window.matchMedia(`(max-width:${DESKTOP_SCREEN_WIDTH - 1}px)`).matches,
        isMedium: window.matchMedia(`(min-width:${DESKTOP_SCREEN_WIDTH}px)`).matches,
        isLTLarge: window.matchMedia(`(max-width:${DESKTOP_BODY_WIDTH_LARGE - 1}px)`).matches,
        isLarge: window.matchMedia(`(min-width:${DESKTOP_BODY_WIDTH_LARGE}px)`).matches,
        isLTExtraLarge:window.matchMedia(`(max-width:${DESKTOP_BODY_WIDTH_EXTRA_LARGE - 1}px)`).matches,
        isExtraLarge: window.matchMedia(`(min-width:${DESKTOP_BODY_WIDTH_EXTRA_LARGE}px)`).matches,
        isDesktopWeb: window.matchMedia(`(min-width:${DESKTOP_SCREEN_WIDTH}px)`).matches,
        hasHover: hasHoverQuery.matches,
    };
    return result;
};

function BreakpointProvider ({ children }) {
    const [ breakpoints, setBreakpoints ] = useState(calcBreakpoints());
    const { width } = useResizeObserver({ ref: document.getElementById('react-app')});

    useEffect(() => {
        setBreakpoints(calcBreakpoints());
    }, [ width, setBreakpoints ]);

    return (
        <BreakpointContext.Provider value={{ breakpoints }}>
            {children}
        </BreakpointContext.Provider>
    );
}

BreakpointProvider.propTypes = {
    children: PropTypes.node,
};

export default BreakpointProvider;
