import Storage from 'app/platform/Storage';

const FEATUREFLAG_KEY = 'ff_items';

export const FEATURE = [
    'FANCY_LANG',
    'CONTROL_HELP',
].reduce((result, key) => {
    result[key] = key;
    return result;
}, {});

export const FEATURE_DESCRIPTIONS = {
    [FEATURE.FANCY_LANG]: 'Use this to check for unlocalized strings',
    [FEATURE.CONTROL_HELP]: 'control+shift+H to show and hide.  control+shift+ARROW to control position',
};

const DEFAULT_VALUE = {
    [FEATURE.FANCY_LANG]: false,
    [FEATURE.CONTROL_HELP]: false,
};

let _activeValues = {};

function updateActiveValues (stored) {
    _activeValues = {
        ...DEFAULT_VALUE,
        ...stored,
    };
}

function loadStored () {
    const raw = Storage.storage.getItem(FEATUREFLAG_KEY);
    const stored = raw ? JSON.parse(raw) : {};
    updateActiveValues(stored);
    return stored;
}

export async function store (feature, value) {
    const stored = loadStored();
    stored[feature] = value;
    await Storage.storage.setItem(FEATUREFLAG_KEY, JSON.stringify(stored));
    updateActiveValues(stored);

    // Normally these are only dispatched from other windows/tabs
    window.dispatchEvent(new Event('storage'));
    return stored;
}

export function get (feature) {
    if (!feature) {
        return false;
    }

    return _activeValues[feature];
}

export function getAll () {
    return _activeValues;
}

// initial load
try {
    loadStored();
} catch (err) {
    console.info('Unable to load feature flags -- using defaults');
}
