import { FormattedDate } from 'react-intl';
import { withPlaceholder, strToDate } from '../utils/Utils.js';

import { type PaymentAccountType } from '../models/PaymentAccount.js';

export const PAYMENT_STATUS_REVERSED = 'REVERSED';
export const PAYMENT_STATUS_VOID = 'VOID';

export const PAYMENT_ORIGIN_AUTOPAY = 'AUTO';

interface PaymentType {
    paidAt: string;
    paymentAccount?: PaymentAccountType;
    paymentAccounts: { [key: string]: PaymentAccountType };
    toAccount: string;
    status: string;
    documentId: string;
    paymentType: string;
    sellerLinked: boolean;
    sellerInitiated: boolean;
    origin: string;
    fundsAvailability: string;
    fromAccountSellerUseEnabled?: boolean;
}

export default class Payment {
    data: PaymentType;

    constructor(payment: PaymentType) {
        this.data = payment;
    }

    getPaymentAccount() {
        return this.data.paymentAccount;
    }

    getMask() {
        return this.data.paymentAccount?.mask;
    }

    getToPaymentAccount() {
        return this.data.paymentAccounts?.[this.data.toAccount];
    }

    /**
     * @returns Date in UTC format (use `-utc` in a formattedmessage)
     */
    get estimatedPayoutDate (): Date | undefined {
        return strToDate(this.data.fundsAvailability);
    }

    getEstimatedPayoutDate() {
        return this.data.fundsAvailability;
    }

    /**
     * @returns Date in UTC format (use `-utc` in a formattedmessage)
     */
    get paidAt (): Date | undefined {
        return strToDate(this.data.paidAt);
    }

    paidAtStr() {
        return withPlaceholder(
            this.paidAt,
            (paidAt) => (<FormattedDate value={paidAt} format='medium-utc' />)
        );
    }

    paymentMethod() {
        return withPlaceholder(
            this.data.paymentType,
            (t: any) => t
        );
    }

    reference() {
        return withPlaceholder(
            this.data.documentId,
            (t: any) => t
        );
    }

    isReversed() {
        return this.data && this.data.status === PAYMENT_STATUS_REVERSED;
    }

    isVoided() {
        return this.data && this.data.status === PAYMENT_STATUS_VOID;
    }

    isSellerLinked() {
        return this.data && this.data.sellerLinked;
    }

    isSellerInitiated() : boolean {
        return this.data && this.data.sellerInitiated;
    }

    isAutopay() {
        return this.data && this.data.origin === PAYMENT_ORIGIN_AUTOPAY;
    }

    isAuthAccountCharged() : boolean {
        return this.isSellerInitiated() && !!this.data.fromAccountSellerUseEnabled;
    }
}

